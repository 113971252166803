import React from "react";
import styled from "styled-components";
import { BsGrid, BsList } from "react-icons/bs";
import { useFilterContext } from "../context/filter_context";


const Sort = () => {
  const { filter_products, grid_view, setGridView, setListView, sorting } =
    useFilterContext();

  return (
    <Wrapper className="row">
      {/* 1st column  */}
      <div className="col-6">
      <h5 className="common-heading">{`${filter_products.length} Product Available`}</h5>

      </div>
      {/* 2nd column  */}
      {/* 3rd column  */}
      <div className="col-6">
        <div className="row top_tab">
          <div className="col-3 top_tab_option">
            <form action="#">
              <label htmlFor="sort"></label>
              <select
                name="sort"
                id="sort"
                className="sort_selection"
                onClick={sorting}
              >
                <option value="lowest">Price(lowest)</option>
                <option value="highest">Price(highest)</option>
                <option value="a-z">Price(a-z)</option>
                <option value="z-a">Price(z-a)</option>
              </select>
            </form>
          </div>
          <div className="sorting-list--grid col-3">
            <button
              className={grid_view ? "active sort-btn" : "sort-btn"}
              onClick={setGridView}
            >
              <BsGrid className="icon" />
            </button>

            <button
              className={!grid_view ? "active sort-btn" : " sort-btn"}
              onClick={setListView}
            >
              <BsList className="icon" />
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
padding: 20px 10px;
  .top_tab {
    text-align: right;
    justify-content: end;
  }
  .top_tab_option {
    display: flex;
    justify-content: center;
    align-items: center;

    .sort_selection {
      font-size: 16px;
      width: 200px;
      line-height: 2px;
      padding: 10px 20px;
      border-radius: 8px;
      border: none;
      background:F5F5F5;
      margin-right: 50px;
    }
    .sort_selection option {
      padding: 8px !important;
      line-height: 2px;
    }
  }
  .sorting-list--grid {
    display: flex;
    justify-content: end;
    gap: 1rem;

    .sort-btn {
      padding: 0.8rem 1rem;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #fff;
    }

    .icon {
      font-size: 2.6rem;

    }
    .active {
      color: #538B3E;
    }
  }
`;

export default Sort;
