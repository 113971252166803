import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styled from "styled-components";
import ProductCategories from "../productcategories";
import AddProductNew from './addproductnew';
import ProductList from './productlist';
import MainPage from './mainpage';
import AddBlogForm from './blog';
import CategoryManagement from './categorymanagement';
import SettingPage from './settingpage';
import Customers from './customers';
import Orders from './orders';
import ReviewsComponent from './reviewscomponent';
import UserManagement from '../usermanagement';
import MainContentEditor from './maincontenteditor';
import ImageSliderEditor from './imageslidereditor';
import BlogManagement from './blogmanagement';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: '85%', height: '80vh', overflow: 'auto' }}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Wrapper>
      <Box sx={{ display: 'flex', height: "100vh", width: "100vw", overflow: "hidden", position: "relative" }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            borderRight: 1,
            borderColor: 'divider',
            backgroundColor: '#222222',
            '&::-webkit-scrollbar': {
              width: '0.4em',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'red',
            },
          }}
        >
          <Tab label="Dashboard" {...a11yProps(0)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
          <Tab label="Product" {...a11yProps(1)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
          <Tab label="Product Categories" {...a11yProps(2)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
          <Tab label="Add New Product" {...a11yProps(3)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
          <Tab label="Orders" {...a11yProps(4)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
          <Tab label="Statistics" {...a11yProps(5)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
          <Tab label="Reviews" {...a11yProps(6)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
          <Tab label="Settings" {...a11yProps(7)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
          <Tab label="Blog" {...a11yProps(8)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
          <Tab label="Blog Category" {...a11yProps(9)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
          <Tab label="Customers" {...a11yProps(10)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
          <Tab label="Orders" {...a11yProps(11)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
          <Tab label="new menu" {...a11yProps(12)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
          <Tab label="Content Editor" {...a11yProps(13)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
          <Tab label="SliderEditor" {...a11yProps(14)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
          <Tab label="BlogManagement" {...a11yProps(15)} sx={{ '&.Mui-selected': { color: '#000000' } }} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <MainPage />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className='row product_outer'>
            <div className='col'>
              <ProductList />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ProductCategories />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AddProductNew />
        </TabPanel>
        <TabPanel value={value} index={4}>
          Item Five
        </TabPanel>
        <TabPanel value={value} index={5}>
          Item Six
        </TabPanel>
        <TabPanel value={value} index={6}>
          <ReviewsComponent />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <SettingPage />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <AddBlogForm />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <CategoryManagement />
        </TabPanel>
        <TabPanel value={value} index={10}>
          <Customers />
        </TabPanel>
        <TabPanel value={value} index={11}>
          <Orders />
        </TabPanel>
        <TabPanel value={value} index={12} >
        <UserManagement/>
        </TabPanel>
        <TabPanel value={value} index={13} >
        <MainContentEditor/>
        </TabPanel>
        <TabPanel value={value} index={14} >
        <ImageSliderEditor/>
        </TabPanel>
        <TabPanel value={value} index={15} >
        <BlogManagement/>
        </TabPanel>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  button {
    color: #ffffff;
    position: relative;
    z-index: 2;
    align-items: start;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    overflow: hidden;
  }
  .MuiTabs-indicator {
    width: 100%;
    position: absolute;
    background: #ffffff;
    border-radius: 6px;
    z-index: 1;
  }
  .MuiTabs-vertical {
    width: 15%;
    padding: 12px;
  }
  .product_outer {
    .col-2 {
      margin: 0px 10px;
    }
  }
`;
