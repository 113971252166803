import React from 'react';
import VerticalTabs from './components/verticaltabs';
import styled from "styled-components";
import AccountMenu from "./components/accountmenu";

const dashboard = () => {
  return (
    <Wrapper>
      <div className='container-fluid'>

        <div className='row top'>
          <div className='col-2 logo'>
          <img src='../images/logo.png'/>
          </div>
          <div className='col-8'></div>
          <div className='col-2 accounticon'>
          <AccountMenu/>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
          <VerticalTabs/>
          </div>
        </div>
      </div>


    </Wrapper>
  )
};
const Wrapper = styled.section`
width: 100%;
height: 100vh;
overflow: hidden;
.logo {
    width: 15.94%;
  img{
 width: 100%;
 height: 100px;
 object-fit: contain;
 background: #222222;
 padding: 12px;

}}
.accounticon{
  display: flex;
  justify-content: end;
  align-items: center;
}
.topsection{
  padding:  10px 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px #00000020;
  justify-content: center;
  align-items: center;
}
`;
export default dashboard;