import * as React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';

function createData(email, number, name, address, city, country, orders) {
  return {
    email,
    number,
    name,
    address,
    city,
    country,
    orders: orders || [], // Ensure orders is always an array
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton className='downbtn'
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.email}
        </TableCell>
        <TableCell align="right">{row.number}</TableCell>
        <TableCell align="right">{row.name}</TableCell>
        <TableCell align="right">{row.address}</TableCell>
        <TableCell align="right">{row.city}</TableCell>
        <TableCell align="right">{row.country}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Order Details
              </Typography>
              <Table size="small" aria-label="orders">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Grand Total (₹)</TableCell>
                    <TableCell>Order Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.orders.length > 0 ? (
                    row.orders.map(order => (
                      <TableRow key={order._id}>
                        <TableCell>
                          <a
                            href={`https://api.rajhospitalitygroup.com/invoice/${order._id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {order._id}
                          </a>
                        </TableCell>
                        <TableCell>{order.createdAt}</TableCell>
                        <TableCell>{order.Grand_Total}</TableCell>
                        <TableCell>{order.status || 'N/A'}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>No Order Details Available</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    email: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    orders: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        createdAt: PropTypes.string,
        Grand_Total: PropTypes.number,
        status: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};

export default function Customers() {
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user and order data
        const userResponse = await axios.get('https://api.rajhospitalitygroup.com/users/');
        const checkoutResponse = await axios.get('https://api.rajhospitalitygroup.com/checkouts/');

        // Log responses for debugging
        console.log('User Data:', userResponse.data);
        console.log('Order Data:', checkoutResponse.data);

        // Map user data and attach order details
        const combinedData = userResponse.data.map(user => {
          const userOrders = checkoutResponse.data.filter(checkout => checkout.user_id === user._id);
          return createData(
            user.email,
            user.phoneNumber,
            user.user_name,
            user.addresses[0]?.street || 'N/A',
            user.addresses[0]?.city || 'N/A',
            user.addresses[0]?.country || 'N/A',
            userOrders.map(order => ({
              _id: order._id,
              createdAt: new Date(order.createdAt).toLocaleDateString(), // Format date as needed
              Grand_Total: order.Grand_Total,
              status: order.Orders_Status || 'N/A', // Ensure `Orders_Status` is part of the API response
            }))
          );
        });

        setRows(combinedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Wrapper>
      <div className='container-fluid'>
        <div className='row' style={{ paddingBottom: '20px' }}>
          <div className='col-12'>
            <h3 className='common-heading'>Customers Details</h3>
            <h4>Here is your general customers list data</h4>
          </div>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Email ID</TableCell>
              <TableCell align="right">Number</TableCell>
              <TableCell align="right">Customer Name</TableCell>
              <TableCell align="right">Address</TableCell>
              <TableCell align="right">City</TableCell>
              <TableCell align="right">Country</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.email} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  th, td {
    font-size: 2rem;
  }

  .MuiTypography-root {
    font-size: 20px;
    font-weight: 600;
  }

  .downbtn {
    color: #000000;
    svg {
      width: 30px;
      height: 30px;
    }
  }
`;
