import React from "react";
import { NavLink } from "react-router-dom";
import { FaEye, FaRegStar, FaRegHeart } from "react-icons/fa";


const Product = ({ curElem }) => {
  const { id, name, images, category } = curElem;

  return (

    <div className="col-lg-3 col-md-6 col-sm-6 col-6">
      <NavLink to={`/SingleProduct/${id}`} className="product-link">
        <div className="single-products-box">
          <div className="products-image">
            <a href="#">
            <img src={`images/product/${images[0]}`} className="main-image" alt={name} />

            <img src={`images/product/${images[1]}`} className="hover-image" alt={name} />
            </a>
            <div className="products-button">
              <ul>
                <li>
                  <div className="wishlist-btn">

                    <button>
                      <FaRegHeart />
                      <span className="tooltip-label">Add to Wishlist</span>
                    </button>

                  </div>
                </li>
                <li>
                  <div className="compare-btn">
                    <button>
                      <FaRegStar />
                      <span className="tooltip-label">Rating</span>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="quick-view-btn">
                    <button data-bs-toggle="modal" data-bs-target="#productsQuickView">
                      <FaEye />
                      <span className="tooltip-label">Quick View</span>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="products-content">
            <h3>
              <span>{name}</span>
            </h3>

            <NavLink to={`/SingleProduct/${id}`} className="add-to-cart">
              Know More
            </NavLink>
          </div>
        </div>
      </NavLink>
    </div>

  );
};


export default Product;
