import React from 'react';

import './styles/contentpages.css';

const TermsAndConditions = () => {
  return (
    <div className="container terms-and-conditions">
      <div className="row">
        <div className="col">
          <h1 className='marg-top'>Terms and Conditions</h1>
          <p>
            These Terms and Conditions ("Terms") govern your use of the website and services. By accessing or using our website or services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our website or services.
          </p>
          <h2>Definitions</h2>
          <ul>
            <li><strong>"We," "us," "our,"</strong> refers to Rabbit and Turtle</li>
            <li><strong>"You," "your,"</strong> refers to the user or customer accessing our website and using our services.</li>
          </ul>
          <h2>Use of Our Website and Services</h2>
          <ol>
            <li><strong>Acceptance of Terms:</strong> By using our website or services, you agree to these Terms in their entirety and agree to comply with all applicable laws and regulations.</li>
            <li><strong>User Accounts:</strong> You may be required to create an account to access certain features of our website or services. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
            <li><strong>User Conduct:</strong> You agree to use our website and services only for lawful purposes and in a manner that does not infringe upon the rights of others or violate any laws or regulations.</li>
            <li><strong>Intellectual Property:</strong> All content on our website, including text, images, graphics, logos, and designs, is the property of Rabbit and Turtle or its licensors and is protected by copyright, trademark, and other intellectual property laws.</li>
          </ol>
          <h2>Order Acceptance</h2>
          <ol>
            <li><strong>Order Placement:</strong> When you place an order for customized garments through our website, you agree to provide accurate and complete information about your order, including the design, size, and quantity of the product you order.</li>
            <li><strong>Design Submissions:</strong> You are solely responsible for the content of any designs or images you upload for customization and agree not to upload any content that infringes on the rights of others or violates any laws or regulations. By submitting a custom design to us, you represent and warrant that you own or have the necessary rights and permissions to use the design. You grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, and modify your design for the purpose of fulfilling your order.</li>
            <li><strong>Order Approval:</strong> We reserve the right to reject any order or design that we deem inappropriate, offensive, or in violation of our policies.</li>
            <li><strong>Order Acceptance:</strong> All orders are subject to acceptance by us. We reserve the right to refuse or cancel any order for any reason, including limitations on quantities available for purchase, inaccuracies, or errors in product or pricing information.</li>
          </ol>
          <h2>Payment and Pricing</h2>
          <ol>
            <li><strong>Prices:</strong> Prices are listed on our website and are subject to change without notice.</li>
            <li><strong>Payment:</strong> Payment must be made at the time of placing an order. Payment for orders is processed through our secure payment gateway partners. By providing your payment information, you authorize us to charge the applicable amount to your chosen payment method.</li>
            <li><strong>Refunds and Exchanges:</strong> If you receive a defective or incorrect item, please contact us within [number of days] days of receiving your order. We will provide a replacement or refund at our discretion. Due to the customized nature of our products, we do not accept returns or exchanges for any other reasons.</li>
          </ol>
          <h2>Shipping and Delivery</h2>
          <p>
            We aim to process and deliver your order within the estimated timeframes. However, delivery times are not guaranteed and may be affected by factors beyond our control. Shipping costs and delivery times will be calculated at checkout.
          </p>
          <h2>Intellectual Property</h2>
          <p>
            All content on our website, including text, graphics, logos, images, and software, is the property of Rabbit and turtle or its content suppliers and is protected by intellectual property laws. You may not use any content from our website without our prior written permission.
          </p>
          <h2>Limitation of Liability</h2>
          <ol>
            <li><strong>Disclaimer:</strong> Rabbit and Turtle provides our website and services on an "as is" and "as available" basis without any warranties of any kind, either express or implied.</li>
            <li><strong>Limitation of Liability:</strong> In no event shall Rabbit and Turtle be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of our website or services.</li>
          </ol>
          <h2>Governing Law</h2>
          <p>
            These Terms shall be governed by and construed in accordance with the laws of Noida Jurisdiction, without regard to its conflict of law principles.
          </p>
          <h2>Changes to These Terms</h2>
          <p>
            We reserve the right to update or change these Terms at any time, and any changes will be effective immediately upon posting on this page. Your continued use of our website or services after any such changes constitutes your acceptance of the new Terms.
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns about these Terms, please contact us at:
            <ul>
              <li><strong>Email:</strong> rabbitandturtle22@gmail.com</li>
              <li><strong>Address:</strong> B 28, SECTOR 5, NOIDA, UP-201301</li>
              <li><strong>Phone:</strong> 9810071536</li>
            </ul>
          </p>
          <p>
            By using our website and services, you agree to these Terms and Conditions. Thank you for choosing Rabbit and Turtle.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
