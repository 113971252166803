import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';

const Sidebar = ({ onSelect }) => {
    const [activeItem, setActiveItem] = useState('myInfo');

    useEffect(() => {
        // Set the default active menu item
        onSelect(activeItem);
    }, [activeItem, onSelect]);

    const handleSelect = (item) => {
        setActiveItem(item);
        onSelect(item);

    };


    return (
        <SidebarWrapper className='col-2'>
            <StyledButton
                onClick={() => handleSelect('myInfo')}
                className={activeItem === 'myInfo' ? 'active' : ''}
            >
                My Info
            </StyledButton>
            <StyledButton
                onClick={() => handleSelect('myOrders')}
                className={activeItem === 'myOrders' ? 'active' : ''}
            >
                My Orders
            </StyledButton>
            <StyledButton
                onClick={() => handleSelect('signOut')}
                className={activeItem === 'signOut' ? 'active' : ''}
            >
                Sign Out
            </StyledButton>
        </SidebarWrapper>
    );
};

const SidebarWrapper = styled.div`
    display: flex;
    flex-direction: column;

    height: 100vh;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: inset -4px 0px 0px -2px #65A04F;

    button {
        margin-bottom: 15px;
    }
`;

const StyledButton = styled(Button)`
    && {
        width: 100%;
        padding: 10px 20px;
        color: #000000;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        transition: background-color 0.3s, transform 0.3s;

        &.active {
            border-left: 4px solid #538B3E;
            transform: translateX(5px);
        }

        &:hover {
            border-left: 4px solid #538B3E;
            transform: translateX(5px);
        }

        &:active {
            background-color: #16a085;
        }

        &:focus {
            outline: none;

        }
    }
`;

export default Sidebar;
