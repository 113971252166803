import React from 'react';
import styled from "styled-components";
const ReviewsComponent = () => {
  return (
    <Wrapper>
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'><h3 className='common-heading'>Reviews</h3></div>
      </div>
      <div className='row'>
        <div className='col boxsha'> <h4>4.5</h4>
        <h5>Review score</h5>
          </div>
        <div className='col boxsha'> </div>
        <div className='col boxsha'> </div>
        <div className='col boxsha'> </div>
        <div className='col-4 boxsha'> </div>
      </div>

    </div>
    </Wrapper>
  )
}
const Wrapper = styled.section`
.boxsha{
  box-shadow: 0px 0px 10px #00000040;
}
`;

export default ReviewsComponent;