import React, { useState, useEffect } from 'react';
import UserForm from './userform';
import { updateUser, getUser } from '../services/userservice';

const UserEdit = ({ userId, onClose }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        fetchUser();
    }, [userId]);

    const fetchUser = async () => {
        const data = await getUser(userId);
        setUser(data);
    };

    const handleSubmit = async (formData) => {
        await updateUser(userId, formData);
        onClose();
    };

    return (
        <div>
            {user && <UserForm user={user} onSubmit={handleSubmit} />}
        </div>
    );
};

export default UserEdit;
