import { NavLink } from "react-router-dom";
import styled from "styled-components";
import FormatPrice from "../Helpers/formatprice";
import { Button } from "../styles/button";

const ListView = ({ products }) => {
  return (
    <Wrapper className="section">
      <div className="container ">
        {products.map((curElem) => {
          const { id, name, images, price, description } = curElem;
          return (
            <div className="row product_list" key={id}>
              <div className="col-6">
                <img src={`images/product/${images[0]}`} alt={name} />
              </div>

              <div className="col-6">
                <h3>{name}</h3>
                <p>
                  <FormatPrice price={price} />
                </p>
                <p>{description ? description.slice(0, 90) : "No description available"}...</p>

                <NavLink to={`/singleproduct/${id}`} className="btn-main">
                  <Button className="btn">Read More</Button>
                </NavLink>
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 9rem 0;

  .grid {
    gap: 3.2rem;
  }

  .product_list {
    padding: 20px 0px;

    img {
      width: 100%;
      max-width: 100%;
      height: 300px;
      object-fit: contain;
    }
  }

  .card {
    border: 0.1rem solid rgb(170 170 170 / 40%);

    .card-data {
      padding: 0 2rem;
    }

    h3 {
      margin: 2rem 0;
      font-weight: 300;
      font-size: 2.4rem;
      text-transform: capitalize;
    }

    .btn {
      margin: 2rem 0;
      background-color: rgb(0 0 0 / 0%);
      border: 0.1rem solid rgb(98 84 243);
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgb(98 84 243);

      &:hover {
        background-color: rgb(98 84 243);
      }

      &:hover a {
        color: #fff;
      }
      a {
        color: rgb(98 84 243);
        font-size: 1.4rem;
      }
    }

    .btn-main .btn:hover {
      color: #fff;
    }
  }
`;

export default ListView;
