import React from 'react';
import './styles/contentpages.css';

const TermsOfService = () => {
  return (
    <div className="container my-5 ">
      <div className="row">
        <div className="col-md-12">
          <h1 className="mb-4  marg-top">Terms of Service</h1>
          <p>
            These Terms of Service ("Terms") govern your use of the website and services. By accessing or using our website or services, you agree to be bound by these Terms.
          </p>
          <h2 className="mt-4">Use of Our Website and Services</h2>
          <ul className="list-unstyled">
            <li>You agree to provide accurate, current, and complete information when using our website or services and to update your information as necessary to keep it accurate, current, and complete.</li>
            <li>You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
            <li>You agree not to use our website or services for any illegal or unauthorized purpose or to violate any laws in your jurisdiction.</li>
          </ul>
          <h2 className="mt-4">Customized Orders</h2>
          <ul className="list-unstyled">
            <li>When you place an order for a customized garment through our website, you agree to provide accurate and complete information about your order, including the design, size, and quantity of the garment.</li>
            <li>You are solely responsible for the content of any designs or images you upload for customization and agree not to upload any content that infringes on the rights of others or violates any laws or regulations.</li>
            <li>By submitting a custom design, you represent and warrant that you have the right to use the design and that it does not infringe on the rights of any third party.</li>
            <li>We reserve the right to reject or cancel any order that violates these Terms or that we believe to be inappropriate or offensive.</li>
          </ul>
          <h2 className="mt-4">Payment and Shipping</h2>
          <ul className="list-unstyled">
            <li>You agree to pay all fees and charges associated with your order, including any applicable taxes and shipping fees.</li>
            <li>We will process and ship your order in accordance with our shipping policies, which may vary based on your location and the availability of the products you ordered.</li>
          </ul>
          <h2 className="mt-4">Intellectual Property</h2>
          <ul className="list-unstyled">
            <li>All content on our website, including text, graphics, logos, and images, is the property of our company and is protected by copyright, trademark, and other intellectual property laws.</li>
            <li>You agree not to use, reproduce, or distribute any content from our website without our prior written permission.</li>
          </ul>
          <h2 className="mt-4">Disclaimers and Limitation of Liability</h2>
          <ul className="list-unstyled">
            <li>Our website and services are provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied.</li>
            <li>We do not warrant that our website or services will be uninterrupted or error-free, or that any defects will be corrected.</li>
            <li>We are not liable for any damages arising from your use of our website or services, including any direct, indirect, incidental, or consequential damages.</li>
          </ul>
          <h2 className="mt-4">Governing Law</h2>
          <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which our company is located, without regard to its conflict of law principles.</p>
          <h2 className="mt-4">Changes to These Terms</h2>
          <p>We reserve the right to update or change these Terms at any time, and any changes will be posted on this page with an updated effective date. We encourage you to review these Terms periodically for any updates or changes.</p>
          <h2 className="mt-4">Contact Us</h2>
          <p>If you have any questions or concerns about these Terms, please contact us at rabbitandturtle22@gmail.com.</p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
