import React from 'react';
import styled from "styled-components";
import { NavLink } from 'react-router-dom';
import PageNavigation from "./components/pagenavigation";
const Thankyou = () => {
  return (
    <Wrapper>
 <PageNavigation title="Order Completed" />
 <div className='container'>
    <div className='row thankyouimg'>
        <div className='col-12 thankyouimginner'>
                <h3 className='common-heading'> Your Order Is Completed! </h3>
                <p>Thank you for your order! Your order is being processed and will be completed within 3-6
                    hours. You will receive an email confirmation when your order is completed.
                </p>
            <NavLink to="/shop" className="navbar-link">
            Continue Shopping
            </NavLink>
        </div>
    </div>
 </div>

    </Wrapper>
  )
}
const Wrapper = styled.section`
padding: 9rem 0;
.thankyouimg{
    justify-content: center;
    align-items: center;
    background: url("images/Group 1000002733.png") no-repeat center center;
    background-size: contain;
    margin: 50p   x 0px;

p{
    width: 800px;
}
}
.thankyouimginner{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8rem 0rem;
    p{
        padding-bottom: 50px;
    }
    h3{
        margin-bottom: 20px;
    }
}
.navbar-link{
    display: block;
    text-align: center;

    border-radius: 8px;
    background: #538B3E;
    padding: 12px 30px;
    color: #ffffff;
    border: none;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

}
`;
export default Thankyou;