import React from 'react';
import styled from "styled-components";
import { NavLink } from 'react-router-dom';
const Collection = () => {
  return (
    <Wrapper>
        <div className='container'>

    <div className='row collection'>
        <div className='col-md-4 outerbox'>
            <img src='images/collection1.jpeg' alt='collection1'/>
            <NavLink to="#">Women's Collection</NavLink>
        </div>
        <div className='col-md-4 outerbox'>
            <img src='images/collection2.jpeg' alt='collection1'/>
            <NavLink to="#">Kid's Collection</NavLink>
        </div>
        <div className='col-md-4 outerbox'>
            <img src='images/collection3.jpeg' alt='collection1'/>
            <NavLink to="#">Men's Collection</NavLink>
        </div>
    </div>
    </div>
    </Wrapper>
  )
};
const Wrapper = styled.section`
padding-top : 100px;
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter:brightness(0.6);
    border-radius: 12           px;
}
.collection{

    .outerbox{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: bottom;
        margin-bottom: 20px;

a{
    position: absolute;
    bottom: 20px;
    font-size: 16px;
    font-weight: 600;
 color: #000000;
 background: #ffffff;
 padding: 10px 20px;
 border-radius: 12px;
}
    }

}
`;
export default Collection;