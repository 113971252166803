import React from "react";
import { useProductContext } from "../context/productcontex";
import styled from "styled-components";
import Product from "./product";
import "../styles/product.css";

const FeatureProduct = () => {
  const { isLoading, featureProducts } = useProductContext();

  if (isLoading) {
    return <Loading>Loading...</Loading>;
  }

  return (
    <Wrapper>
      <div className="product-tab">
        <h3 className="common-heading" style={{padding: '50px 0px',}}>Explore our collection</h3>
        {/* <div className="tab-links">
          <a href="#" className="active">New Arrivals</a>
          <a href="#">Best Seller</a>
          <a href="#">Sale</a>
        </div>
        <div className="view-all">
          <a href="#">View All</a>
        </div> */}
      </div>
      <div className="row">
        {featureProducts.map((curElem) => (
          <Product key={curElem.id} curElem={curElem} />
        ))}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.bg};

  .product-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    .common-heading {
      text-align: center;
      flex: 1 100%;
    }

    .tab-links {
      flex: 1 100%;
      display: flex;
      justify-content: center;
      margin-top: 1rem;

      a {
        margin-right: 1rem;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.text};
        font-size: 1.4rem;
      }

      .active {
        font-weight: bold;
      }
    }

    .view-all {
      flex: 1 100%;
      text-align: center;
      margin-top: 1rem;

      a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.primary};
        font-size: 1.4rem;
      }
    }
  }

  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2rem;
    padding: 0 2rem;
  }
  img{width: 100%;
  height: 100%; }
`;

const Loading = styled.div`
  font-size: 1.6rem;
  text-align: center;
  margin-top: 2rem;
`;

export default FeatureProduct;
