// Home.js
import React from 'react';

import HeroSection from "./components/herosection.js";
import Usp from './components/usp.js';
import SimpleSlider from './components/categoriesslider.js';
import ContactForm from './components/contactform.js';
import FAQSection from './components/faqsection.js';
import FeaturedProduct from './components/featuredproduct.js';
import './styles/product.css';
import Collection from './components/collection.js';
import Testimimonialslider from './components/testimimonialslider.js';

import { Link } from 'react-router-dom';



const Home = () => {

  return (
    <>
      <HeroSection />
      <Usp />

      <SimpleSlider />

      <Collection />
      <div className='container-fluid' style={{background: '#F5FFF2', padding: '20px 0px', margin: '20px',}}>
      <div className='row custom_goodies'></div>
      </div>
      <div className='container'>

        <div className=" product_outer">


        <FeaturedProduct />


        </div>
        <div className="row bannerc">
          <div className="col-md-6 bannerc1">
            <div className="row">
              <div className="col-md-6">
                <h3>Thousands of
                  free templates</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod, delectus?</p>
                <Link to="../Create.js" className="btnstyle1">Explore More</Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 bannerc2">
            <div className="row">
              <div className="col-md-6">
                <h3>Create your
                  unique style</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod, delectus?</p>
                <Link to="../Create.js" class="btnstyle1">Explore More</Link>
              </div>
            </div>
          </div>
        </div>
</div>
    <Testimimonialslider/>
    <div className='container'>
        <div className="row intro1">
          <div className="col-12">
            <h6>_ Recent News &  Blog</h6>
            <h2>Rabbit & Turtle: Here for small<br />
              <span>Business Si</span>nce 1995.
            </h2>
          </div>
        </div>
        <div className="row intro">
          <div className="col-md-4 introinner">
            <img src="images/man-working-printing-house-with-paper-paints.jpg" alt="" />
            <h6>Posted by R&T - <span>.</span>Feb 18, 2024</h6>
            <p>Richard McClintock, a Latin<br />
              professor at Hampden-Sydney</p>
            <img className="introtag" src="images/Rectangle 10074.png" alt="" />
            <h5 className="introtag2">25 Aug</h5>
          </div>
          <div className="col-md-4 introinner">
            <img src="images/side-view-hands-painting-t-shirt.jpg" alt="" />
            <h6>Posted by R&T - <span>.</span>Feb 18, 2024</h6>
            <p> Richard McClintock, a Latin<br />
              professor at Hampden-Sydney</p>
            <img className="introtag" src="images/Rectangle 10074.png" alt="" />
            <h5 className="introtag2">25 Aug</h5>
          </div>
          <div className="col-md-4 introinner">
            <img src="images/close-up-person-working-engraving.jpg" alt="" />
            <h6>Posted by R&T - <span>.</span>Feb 18, 2024</h6>
            <p>Richard McClintock, a Latin<br />
              professor at Hampden-Sydney</p>
            <img className="introtag" src="images/Rectangle 10074.png" alt="" />
            <h5 className="introtag2">25 Aug</h5>
          </div>


        </div>
        <div className='row contactsectionmain' style={{ margin: '70px 0px', textAlign: 'center', color: '#000000' }}>
          <h3 className='common-heading'>Let’s Connect</h3>
          <div className='col-md-6'>
            <FAQSection />

          </div>
          <div className='col-md-6' >
            <ContactForm />
          </div>
        </div>

      </div>

    </>
  )

};



export default Home;
