import React from "react";
import styled from "styled-components";
import { FaRegHeart, FaRegStar, FaEye } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const GridView = ({ products }) => {
  return (
    <Wrapper className="section">
      <div className="container">
        <div className="row">
          {products.map((curElem) => {
            const { id, name, images, category } = curElem;
            return (
              <div className="col-lg-4 col-md-6 col-sm-6" key={id}>
                <NavLink to={`/singleproduct/${id}`}>
                  <div className="single-products-box">
                    <div className="products-image">
                      <a href="editor">
                        <img src={`images/product/${images[0]}`} className="main-image" alt={name} />
                        <img src={`images/product/${images[1]}`} className="hover-image" alt={name} />
                      </a>
                      <div className="products-button">
                        <ul>
                          <li>
                            <div className="wishlist-btn">
                            <button>
                                <FaRegHeart />
                                <span className="tooltip-label">Add to Wishlist</span>
                                </button>
                            </div>
                          </li>
                          <li>
                            <div className="compare-btn">
                            <button>
                                <FaRegStar />
                                <span className="tooltip-label">Rating</span>
                                </button>
                            </div>
                          </li>
                          <li>
                            <div className="quick-view-btn">
                              <button href="#" data-bs-toggle="modal" data-bs-target="#productsQuickView">
                                <FaEye />
                                <span className="tooltip-label">Quick View</span>
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="products-content">
                      <h3 style={{ paddingBottom: '20px' }}>
                       {name}
                      </h3>

                    </div>
                  </div>
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  /* your styles here */
`;

export default GridView;
