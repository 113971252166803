import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import styled from "styled-components";
import PageNavigation from "./components/pagenavigation";
import { GoogleLogin } from '@react-oauth/google';

function Profile() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('user-info')) {
      navigate("/cart");
    }
  }, [navigate]);

  const login = async () => {
    console.warn(email, password);
    let item = { email, password };
    try {
      let result = await fetch("https://api.rajhospitalitygroup.com/login", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json'
        },
        body: JSON.stringify(item)
      });
      result = await result.json();
      localStorage.setItem('user-info', JSON.stringify(result));
      navigate("/UserProfile");
    } catch (error) {
      console.error('Error occurred while logging in:', error);
    }
  };

  const handleGoogleLoginSuccess = (credentialResponse) => {
    console.log(credentialResponse);
    // Process the Google credentialResponse here
    // Save the user info in local storage or handle the login logic
    localStorage.setItem('user-info', JSON.stringify(credentialResponse));
    navigate('/UserProfile');
  };

  const handleGoogleLoginFailure = () => {
    console.log('Login Failed');
  };

  return (
    <Wrapper>
      <PageNavigation title="Login" />
      <div className='container'>
        <div className='row Loginpage'>
          <div className='col-md-6 Loginsection1'>
            <h3 className='heading-common'>Free access to all <span>Rabbit and turtle</span> tools</h3>
            <p>Get back to your designs in a few clicks</p>
            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={handleGoogleLoginFailure}
              useOneTap
            />
            <label>Email</label>
            <input type='text' placeholder='email' onChange={(e) => setEmail(e.target.value)} className='form-control' />
            <br />
            <label>Password</label>
            <input type='password' placeholder='password' onChange={(e) => setPassword(e.target.value)} className='form-control' />
            <br />
            <button onClick={login} className='btn btn-primary'>Login</button>
            <p className='common-heading'>Don't have an account?<span><NavLink to="/SignUp"><span> &nbsp;Sign up here</span></NavLink></span></p>
            <p className='common-heading'><NavLink to="/ForgotPassword"><span>Forgot Password?</span></NavLink></p>
          </div>
          <div className='col-md-6'>
            <img src='images/Make-Your-Own-Shirt-Printify.gif' alt='loginimg' />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  padding-top: 6rem;
  input {
    text-transform: inherit;
  }
  h3 {
    font-weight: 800;
    text-align: center;
    font-size: 3rem;
  }
  p {
    text-align: center;
  }
  span {
    color: #538B3E;
  }
  input {
    max-width: 100%;
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    &:focus {
      outline: none;
      border-color: dodgerblue;
    }
  }
  label {
    display: block;
    font-weight: 600;
    font-size: 1.6rem;
    padding-bottom: 10px;
  }
  button {
    width: 100%;
    padding: 10px;
    background-color: #538B3E;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 30px;
    &:hover {
      background-color: #34A853;
    }
    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  img {
    width: 100%;
  }
  .Loginsection1 {
    padding: 0px 30px;
  }
`;

export default Profile;
