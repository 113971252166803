import React from "react";
import styled from "styled-components";
import { useFilterContext } from "../context/filter_context";
import { FaCheck} from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import { Button } from "../styles/button";


const FilterSection = () => {
  const {
    filters: { text, category, color, event },
    updateFilterValue,
    all_products,
    clearFilters,
  } = useFilterContext();

  // get the unique values of each property
  const getUniqueData = (data, attr) => {
    let newVal = data.map((curElem) => {
      return curElem[attr];
    });

    if (attr === "colors") {
      newVal = newVal.flat();
    }

    return (newVal = ["all", ...new Set(newVal)]);
  };

  // we need to have the individual data of each in an array format
  const categoryData = getUniqueData(all_products, "category");
  const eventData = getUniqueData(all_products, "event");
  const colorsData = getUniqueData(all_products, "colors");

  return (
    <Wrapper>
      <div className="filter-search">
        <label><h4 className="common-heading">Search</h4></label>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="searchicon_outer">
            <input
              type="text"
              name="text"
              placeholder="Search products..."
              value={text}
              onChange={updateFilterValue}
            />
            <IoSearchOutline className="serchicon" />
          </div>
        </form>
      </div>

      <div className="filter-category">
        <h4 className="common-heading">Product categories</h4>
        <div>
          {categoryData.map((curElem, index) => {
            return (
              <button
                key={index}
                type="button"
                name="category"
                value={curElem}
                className={curElem === category ? "active" : ""}
                onClick={updateFilterValue}>
                {curElem}
              </button>
            );
          })}
        </div>
      </div>

      <div className="filter-company">
        <h4 className="common-heading">Event</h4>
        <form action="#">
          <select
            name="event"
            id="event"
            className="filter-company--select"
            onClick={updateFilterValue} onfocus='this.size=4;' onblur='this.size=0;'
            onchange='this.size=1; this.blur();'>
            {eventData.map((curElem, index) => {
              return (
                <option key={index} value={curElem} name="event">
                  {curElem}
                </option>
              );
            })}
          </select>
        </form>
      </div>
      <div className="filter-clear">
        <Button className="btn" onClick={clearFilters}>
          Clear Filters
        </Button>
      </div>
      <div className="filter-colors colors" style={{display: 'none',}}>
        <h3>Colors</h3>
        <div className="filter-color-style" >
          {colorsData.map((curColor, index) => {
            return (
              <button
                key={index}
                type="button"
                value={curColor}
                name="color"
                style={{ backgroundColor: curColor }}
                className="btnStyle"
                onClick={updateFilterValue}>
                {color === curColor ? "" : null}
              </button>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .filter-search {
    input {
      padding: 1.2rem 1rem;
      width: 100%;
      margin: 15px 0px;
      border-radius: 10px;
      border:none;
      text-transform:none;
      background: #F5F5F5;
      &:active{
        border: 1px solid #538B3E;
      }
      &:focus-visible {
        outline: -webkit-focus-ring-color auto 0px;
        border: 1px solid #538B3E;
      }
    }
  }
  .searchicon_outer{
    position: relative;
    padding: 0;
  }
  .serchicon{
    position: absolute;
    top: 45%;
    right: 1rem;
  }

  .filter-category {
    div {
      padding-top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.4rem;

      button {
        border: none;
        background-color: #ffffff;
        text-transform: capitalize;
        cursor: pointer;

        &:hover {
          color: #538B3E;
        }
      }

      .active {
        border-bottom: 2px solid #538B3E;
        color: #538B3E;
      }
    }
  }

  .filter-company--select {
    width: 100%;
    border: none;
    padding: 0.3rem 1.2rem;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.text};
    text-transform: capitalize;
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0px;
      border: 1px solid #538B3E;
    }
  }

  .filter-color-style {
    display: flex;
    justify-content: center;
  }

  .btnStyle {
    width: 2rem;
    height: 2rem;
    background-color: #000;
    border-radius: 50%;
    margin-left: 1rem;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .active {
    opacity: 1;
  }

  .checkStyle {
    font-size: 1rem;
    color: #fff;
  }

  .filter-clear .btn {
    background-color: #538B3E;
    color: #fff;
  }
`;

export default FilterSection;
