import React, { useEffect } from 'react';
import styled from "styled-components";
import BlazeSlider from 'blaze-slider';
import 'blaze-slider/dist/blaze.css';
const Testimimonialslider = () => {
    useEffect(() => {
        const el = document.querySelector('.blaze-slider2');
        new BlazeSlider(el, {
            all: {
                enableAutoplay: true,
                draggable: true,
                stopAutoplayOnInteraction: true,
                autoplayInterval: 3000,
                transitionDuration: 1000,
                slidesToShow: 3,
                slideGap: '20px',
                loop: true,
            },
            '(max-width: 720px)': {
                slidesToShow: 1,
            },

        });
    }, []);
    return (
        <Wrapper>
            <div className='container'>

                <div className='row'>
                    <div className='col-12 testimonial_heading'>
                    <h3 className='common-heading '>Happy Customers</h3>
            <p>Lorem Ipsum is simply dummy text of the printing.</p>
                    </div>
                    <div className='col-12'>
                        <div className="blaze-slider2">
                            <div className="blaze-container">
                                <div className="blaze-track-container">
                                    <div className="blaze-track">
                                        <div>
                                            <div className='testimonial_card'>
                                                <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled </h6>
                                                <div className='testimonial_card_inner'>
                                                    <img src='images/categories/01.png' alt='slide1' />
                                                    <h5 className='common-heading'>Happy Customers</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='testimonial_card'>
                                                <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled </h6>
                                                <div className='testimonial_card_inner'>
                                                    <img src='images/categories/02.png' alt='slide2' />
                                                    <h5 className='common-heading'>Happy Customers</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='testimonial_card'>
                                                <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled </h6>
                                                <div className='testimonial_card_inner'>
                                                    <img src='images/categories/03.png' alt='slide3' />
                                                    <h5 className='common-heading'>Happy Customers</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='testimonial_card'>
                                                <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled </h6>
                                                <div className='testimonial_card_inner'>
                                                    <img src='images/categories/04.png' alt='slide4' />
                                                    <h5 className='common-heading'>Happy Customers</h5>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="my-structure">
      <button class="blaze-prev" aria-label="Go to previous slide"></button>
      <div class="blaze-pagination"></div>
      <button class="blaze-next" aria-label="Go to next slide"></button>
    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
};
const Wrapper = styled.section`
background: url('images/testimonialback.png') no-repeat center center;
background-size: cover;
padding: 100px 0px;
.container{
.testimonial_heading{
    padding-top: 50px;
    text-align: center;
}
.blaze-track{
    padding: 0px 10px;
}
.testimonial_card{
    margin: 30px 0px;
    padding: 5%;
    background: #ffffff;
    box-shadow : 0px 0px 10px #00000080;
    border-radius: 5px;
    h6{
        font-size: 14px;
        line-height: 2;
    }
    .testimonial_card_inner{
        padding-top: 10px;
        padding-left: 14px;
    display: flex;
    justify-content:start;
    align-items:center;
    img{

        width: 50px;
        height: 50px;
        border-radius: 100%;
        object-fit: contain;
    }
    h5{
        padding-left: 10px;
        margin: 0;
    }
    }
}

.blaze-slider1 {
    position: relative;
    padding-top: 4.2%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
.row{
    position: relative;
}
  .blaze-prev,
  .blaze-next {
    background-color: #00000000;
    color: #000000;
    font-size: 50px;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .blaze-prev {
    left: 10px;
  }

  .blaze-next {
    right: 10px;
  }

  .blaze-prev:hover,
  .blaze-next:hover {
    background-color: #5c5c5c;

    color: #ffffff;
  }
.blaze-pagination{
    display: flex;
    justify-content: center;
    align-items:center;
    button{
        display: block;
        width: 14px;
        height: 14px;
        background: #C2C2C2;
        color: #00000000;
        border: none;
        margin: 10px;
        border-radius: 100%;
        transition: 1.6s;
        padding: 0;
    }
    .active{
        background:#538B3E;
        width: 30px;
        border-radius: 20px;
    }
}
}
 @media (max-width: ${({ theme }) => theme.media.mobile}) {
 padding: 20px 0px;
 }
`;
export default Testimimonialslider;