import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './addtocart.css';

const AddToCart = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get('userId');
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [selectedFolders, setSelectedFolders] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      if (userId) {
        try {
          const response = await axios.get(`http://localhost/PHOTO_EDITING_APPLICATION_IN_PHP_WITH_SOURCE_CODE/list_files.php?folder=${userId}`);
          const data = response.data;

          if (data && data.folders && typeof data.folders === 'object') {
            const productData = {};
            Object.keys(data.folders).forEach(folderId => {
              productData[folderId] = {
                name: `${folderId}`, // Replace this with actual folder name if available
                images: data.folders[folderId]
              };
            });
            setProduct(productData);

            const initialSelectedFolders = Object.keys(productData).reduce((acc, folderId) => {
              acc[folderId] = false;
              return acc;
            }, {});
            setSelectedFolders(initialSelectedFolders);
          } else {
            console.error('Unexpected data format from the server:', data);
            setError('Unexpected data format from the server');
          }
        } catch (error) {
          setError('Error fetching images data');
          console.error('Error fetching images data:', error);
        }
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await fetchImages();
      setLoading(false);
    };

    fetchData();
  }, [userId]);

  const handleCheckboxChange = (folderId) => {
    setSelectedFolders(prev => ({
      ...prev,
      [folderId]: !prev[folderId]
    }));
  };

  const handleSave = async () => {
    const selectedProducts = Object.keys(selectedFolders)
      .filter(folderId => selectedFolders[folderId])
      .reduce((acc, folderId) => {
        acc[folderId] = {
          name: product[folderId].name,
          images: product[folderId].images,
        };
        return acc;
      }, {});

    const postData = {
      userId,
      product: selectedProducts,  // Send products as an object
    };

    console.log('Posting data:', JSON.stringify(postData, null, 2));

    try {
      const response = await axios.post('https://api.rajhospitalitygroup.com/add-to-cart', postData);

      alert('Selected items added to cart successfully');
      console.log('Response:', response.data);
      navigate('/cart');
    } catch (error) {
      setError('Error saving data');
      console.error('Error saving data:', error);
      if (error.response) {
        console.error('Server response:', error.response.data);
      }
    }
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Add to Cart</h1>

      {Object.keys(product).length > 0 ? (
        <div>
          <h2 style={styles.subHeader}>Folders and Images</h2>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>Select</th>
                <th style={styles.tableHeader}>Folder Name</th>
                <th style={styles.tableHeader}>Images</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(product).map(([folderId, folderData]) => (
                <tr key={folderId}>
                  <td style={styles.tableCell}>
                    <input
                      type="checkbox"
                      checked={selectedFolders[folderId]}
                      onChange={() => handleCheckboxChange(folderId)}
                    />
                  </td>
                  <td style={styles.tableCell}>{folderData.name}</td>
                  <td style={styles.tableCell}>
                    <ul style={styles.imageList}>
                      {folderData.images.map((filePath, index) => (
                        <li key={index} style={styles.li}>
                          <img
                            src={`http://localhost/PHOTO_EDITING_APPLICATION_IN_PHP_WITH_SOURCE_CODE/${filePath}`}
                            alt={`Image ${index}`}
                            style={styles.image}
                          />
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No products found.</p>
      )}

      <button
        onClick={handleSave}
        style={styles.saveButton}
        onMouseOver={e => e.currentTarget.style.backgroundColor = '#45a049'}
        onMouseOut={e => e.currentTarget.style.backgroundColor = '#4CAF50'}
      >
        Add Selected to Cart
      </button>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    marginTop: '100px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  header: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '28px',
    fontWeight: '700',
    color: '#333',
  },
  subHeader: {
    marginTop: '20px',
    fontSize: '22px',
    fontWeight: '600',
    color: '#555',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  },
  tableHeader: {
    border: '1px solid #ddd',
    padding: '10px',
    textAlign: 'left',
    backgroundColor: '#eaeaea',
    color: '#333',
    fontSize: '14px',
    fontWeight: '600',
  },
  tableCell: {
    border: '1px solid #ddd',
    padding: '10px',
    fontSize: '14px',
  },
  imageList: {
    listStyleType: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0',
  },
  image: {
    width: '100px',
    height: 'auto',
    margin: '5px',
    borderRadius: '6px',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
  },
  li: {
    width: 'fit-content',
  },
  saveButton: {
    display: 'block',
    margin: '20px auto',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: '600',
    cursor: 'pointer',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    transition: 'background-color 0.3s',
    textTransform: 'uppercase',
  },
};

export default AddToCart;
