import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';

const EditProduct = ({ open, onClose, product, onSave }) => {
  const [formValues, setFormValues] = useState({
    name: '',
    price: '',
    colors: [],
    sizes: [],
    images: [],
    description: '',
    category: '',
    featured: false,
    rating: 0,
    faqs: [],
  });
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    if (product) {
      setFormValues({
        name: product.name || '',
        price: product.price || '',
        colors: product.colors || [],
        sizes: product.sizes || [],
        images: product.images || [],
        description: product.description || '',
        category: product.category || '',
        featured: product.featured || false,
        rating: product.rating || 0,
        faqs: product.faqs || [],
      });
    }
  }, [product]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileSelection = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleUploadImages = () => {
    if (selectedFiles.length > 0) {
      const formData = new FormData();
      Array.from(selectedFiles).forEach((file) => {
        formData.append('images', file);
      });

      axios.post('https://api.rajhospitalitygroup.com/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        setFormValues({
          ...formValues,
          images: [...formValues.images, ...response.data.imagePaths], // Assuming response contains the paths of uploaded images
        });
        setSelectedFiles([]); // Clear the selected files
      })
      .catch(error => {
        console.error('Error uploading images:', error);
        // Handle error, show error message, etc.
      });
    }
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...formValues.images];
    updatedImages.splice(index, 1);

    setFormValues({
      ...formValues,
      images: updatedImages,
    });
  };

  const handleAddFAQ = () => {
    setFormValues({
      ...formValues,
      faqs: [...formValues.faqs, { question: '', answer: '' }],
    });
  };

  const handleFAQChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFAQs = [...formValues.faqs];
    updatedFAQs[index][name] = value;

    setFormValues({
      ...formValues,
      faqs: updatedFAQs,
    });
  };

  const handleRemoveFAQ = (index) => {
    const updatedFAQs = [...formValues.faqs];
    updatedFAQs.splice(index, 1);

    setFormValues({
      ...formValues,
      faqs: updatedFAQs,
    });
  };

  const handleSave = async () => {
    try {
      const updatedProduct = {
        ...formValues,
        id: product.id,
      };

      const response = await axios.put(`https://api.rajhospitalitygroup.com/products/${product.id}`, updatedProduct);
      onSave(response.data); // Assuming the backend returns the updated product
    } catch (error) {
      console.error('Error updating product:', error);
      // Handle error, show error message, etc.
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="edit-product-dialog">
      <DialogTitle id="edit-product-dialog">Edit Product</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name="name"
          label="Name"
          type="text"
          fullWidth
          value={formValues.name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="price"
          label="Price"
          type="text"
          fullWidth
          value={formValues.price}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="colors"
          label="Colors"
          type="text"
          fullWidth
          value={formValues.colors.join(', ')}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="sizes"
          label="Sizes"
          type="text"
          fullWidth
          value={formValues.sizes.join(', ')}
          onChange={handleChange}
        />
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            value={formValues.description}
            onChange={handleChange}
            rows={4}
            style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
          />
        </div>
        <TextField
          margin="dense"
          name="category"
          label="Category"
          type="text"
          fullWidth
          value={formValues.category}
          onChange={handleChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formValues.featured}
              onChange={(e) => setFormValues({ ...formValues, featured: e.target.checked })}
              name="featured"
              color="primary"
            />
          }
          label="Featured"
        />
        <TextField
          margin="dense"
          name="rating"
          label="Rating"
          type="number"
          fullWidth
          value={formValues.rating}
          onChange={handleChange}
        />
        <div style={{ marginTop: '20px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Upload button */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleUploadImages}
              style={{ marginRight: '10px' }}
            >
              Upload
            </Button>
            {/* File input */}
            <input
              type="file"
              multiple
              onChange={handleFileSelection}
              accept="image/*"
              style={{ display: 'none' }}
              id="file-input"
            />
            <label htmlFor="file-input">
              <Button
                variant="outlined"
                component="span"
                color="primary"
              >
                Choose File
              </Button>
            </label>
          </div>
          {/* Display existing images */}
          {formValues.images.map((image, index) => (
            <div key={index} style={{ marginTop: '10px' }}>
              <img src={image} alt={`Product Image ${index}`} style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }} />
              <Button variant="outlined" color="secondary" onClick={() => handleRemoveImage(index)}>Remove Image</Button>
            </div>
          ))}
          {/* Add FAQ section */}
          <Button variant="outlined" onClick={handleAddFAQ} style={{ marginLeft: '10px', marginTop: '10px' }}>Add FAQ</Button>
          {formValues.faqs.map((faq, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <TextField
                margin="dense"
                name="question"
                label="Question"
                type="text"
                fullWidth
                value={faq.question}
                onChange={(e) => handleFAQChange(e, index)}
              />
              <TextField
                margin="dense"
                name="answer"
                label="Answer"
                type="text"
                fullWidth
                value={faq.answer}
                onChange={(e) => handleFAQChange(e, index)}
              />
              <Button variant="outlined" color="secondary" onClick={() => handleRemoveFAQ(index)}>Remove FAQ</Button>
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProduct;
