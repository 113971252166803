import React, { useState,useEffect  } from 'react';
import { NavLink,useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FiShoppingCart } from 'react-icons/fi';
import { CgMenu,CgClose,CgHeart  } from 'react-icons/cg';
import { BsPerson } from "react-icons/bs";
import {NavDropdown} from 'react-bootstrap';
import { FaHome,FaTshirt,FaShoppingCart,FaMicroblog } from "react-icons/fa";
import { FaPhone,FaShop } from "react-icons/fa6";
import { IoCreate } from "react-icons/io5";
import { IoIosChatboxes } from "react-icons/io";

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [carts, setCarts] = useState([]);
  const [menuAnimationState, setMenuAnimationState] = useState('closed');
  useEffect(() => {
    const userInfo = localStorage.getItem('user-info');
    if (userInfo) {
      const { _id } = JSON.parse(userInfo);
      fetchData(_id);
    } else {
      console.error('User info not found in local storage');
    }
  }, []);

  const fetchData = (userId) => {
    fetch(`https://api.rajhospitalitygroup.com/add-to-cart/?userId=${userId}`)
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data) && data.length > 0) {
          const updatedCarts = data.flatMap(cartData => {
            const productEntries = Object.entries(cartData.product || {});
            return productEntries.map(([id, product]) => ({
              _id: id,
              productname: product.name,
              images: product.images,
              size: {
                S: 0,
                M: 0,
                L: 0,
                XL: 0,
                XXL: 0
              },
              price: product.price // Assuming product.price is already calculated
            }));
          });
          setCarts(updatedCarts);
        } else {
          console.error('No data or incorrect data format');
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  const toggleMenu = () => {
    if (menuOpen) {
      setMenuAnimationState('closing');
      setTimeout(() => {
        setMenuOpen(false);
        setMenuAnimationState('closed');
      }, 300); // Match this with your transition duration
    } else {
      setMenuOpen(true);
      setMenuAnimationState('opening');
    }
  };
  const Navbar = styled.nav`


    width: 100%;


    .desktop-menumain{
      justify-content: space-around;
      width: 100%;
      display: flex;

      align-items: center;
        width: 30%%;

        list-style-type: none;
    }

    .navbar-link {
      text-decoration: none;
      font-size: 1.8rem;
      font-weight: 600;
      text-transform: capitalize;
      color: #000000;
      transition: color 0.3s linear;

      &:hover,
      &:active {
        color: #123306e0;
      }
    }

    .cart-trolley--link {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 2.5rem;
      position: relative;
    }

    .cart-total--item {
      width: 20px;
      height: 20px;
      position: absolute;
      background-color: #000;
      color: #ffffff;
      border-radius: 50%;
      display: grid;
      place-items: center;
      top: -50%;
      left: 80%;
      background-color: ${({ theme }) => theme.colors.helper};
      font-size: 10px;
    }

    .mobile-menu , .mobile-menu-toggle {
      display: none;
      font-size: 20px;
    }
    .icon_menu{
      display: flex;
      justify-content: center;
      gap: 8px;
      align-items: center;
    }

    @media (max-width: ${({ theme }) => theme.media.mobile}) {



.mobile-menu ul {
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
      }



      .desktop-menu {
        display: none;
      }

      .mobile-menu-toggle {
        display: block;

      }


      .mobile-menu {
  display: ${menuOpen ? 'block' : 'none'};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffffff;
  padding: 20px;
  border: 1px solid;
  z-index: 999;
  transition: transform 0.5s ease-in-out;
  transform: ${menuOpen ? 'translateX(0)' : 'translateX(100%)'};
   animation: slide 0.5s ease-in-out ${menuAnimationState === 'closing' ? 'alternate-reverse' : ''} forwards;
    }

    @keyframes slide {
      0% {
        width: 0%;
      }
      100% {
        width: 75%;
      }
    }


      .mobile-menu li{
        padding: 12px 0px;
      }

      .mobile-menu-link {
        text-decoration: none;
        color: #000000;
        font-size: 1.8rem;
        text-transform: uppercase;
        transition: color 0.3s linear;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover,
        &:active {
          color: #123306e0;
        }
          svg{
              margin-right: 10px;
          }
      }
    }
    #crossbutton{
      position: absolute;
      top: 1rem;
      right: 1rem;
      font-size: 20px;
    }
    .hearticon{
      font-size: 2.4rem;
      color: #000000;
    }
    .chatlink ul{
      padding: 0px 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000000;
      gap: 10px;
    }

    .chatlink img{
      width: 40px;
      height: 40px;
      border-radius: 100%;
    }
    #chatheading{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      h4{
        margin: 0;
        font-weight: 500;
      }
    }
    .chatlink  a{
      color: #000000;
      font-size: 16px;
      display: block;

    }
    svg{
        color: #538B3E;
    }

  `;

  let user = JSON.parse(localStorage.getItem('user-info'));
  console.log(user);
  const navigate = useNavigate();
  function logOut() {
    localStorage.clear();
    navigate("/SignUp");
  }
  return (
    <Navbar>
      <ul className="desktop-menu desktop-menumain chatlink desktop-menu">
        <li>
          <NavLink to="/" className="navbar-link">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" className="navbar-link">
          About&nbsp;Us
          </NavLink>
        </li>
        <li>
          <NavLink to="/shop" className="navbar-link">
          Shop
          </NavLink>
        </li>
        {/* <li>

          <NavLink to="/create" className="navbar-link">
            Create&nbsp;Now
          </NavLink>
        </li> */}
        <li>
          <NavLink to="/blog" className="navbar-link">
            Blog
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact" className="navbar-link">
            Contact&nbsp;Us
          </NavLink>
        </li>
        <div className='icon_menu'>
        <li>
                {!localStorage.getItem('user-info') && (
            <NavLink to="/Profile" className="Customize">
              <BsPerson  className="hearticon"/>
          </NavLink>
          )}
          <NavLink to="/Profile">

          </NavLink>
          {localStorage.getItem('user-info')?
          <NavDropdown  className='common-heading' title={user && user.user_name} style={{textTransform: 'uppercase',}}>
           <NavDropdown.Item as={NavLink} to="/UserProfile">Profile</NavDropdown.Item>
            <NavDropdown.Item onClick={logOut}>Logout</NavDropdown.Item>

          </NavDropdown>
          :null
  }
        </li>
        <li>
          <NavLink to="/like">
            <CgHeart className="hearticon"/>
          </NavLink>
        </li>
        <li>
          <NavLink to="/cart" className="navbar-link cart-trolley--link">
            <FiShoppingCart className="cart-trolley" />
            <span className="cart-total--item">{carts.length}</span>
          </NavLink>
        </li>
        </div>
      <li>
              <NavLink to="/contact" id='chatheading' ><h4 className='common-heading'>Let's&nbsp;Talk</h4><img
            src="../images/chat.png"
            alt=""
          /> </NavLink>


        </li>


      </ul>

      <MenuIcon className="mobile-menu-toggle" onClick={toggleMenu} />
      <div className="mobile-menu">

        {menuOpen && (
          <>
          <CgClose className="mobile-menu-toggle" id="crossbutton" onClick={() => setMenuOpen(false)} />
          <ul>
            <li>
              <NavLink to="/" className="mobile-menu-link" onClick={() => setMenuOpen(false)}>
              <FaHome /> Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" className="mobile-menu-link" onClick={() => setMenuOpen(false)}>
              <FaTshirt /> About Us
              </NavLink>
            </li>
            <li>
              <NavLink to="/shop" className="mobile-menu-link" onClick={() => setMenuOpen(false)}>
              <FaShop/>Shop
              </NavLink>
            </li>
            <li>
              <NavLink to="/create" className="mobile-menu-link" onClick={() => setMenuOpen(false)}>
              <IoCreate /> Create Now
              </NavLink>
            </li>
            <li>
              <NavLink to="/blog" className="mobile-menu-link" onClick={() => setMenuOpen(false)}>
               <FaMicroblog /> Blog
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" className="mobile-menu-link" onClick={() => setMenuOpen(false)}>
              <FaPhone /> Contact Us
              </NavLink>
            </li>
            <li>
              <NavLink to="/cart" className="mobile-menu-link" onClick={() => setMenuOpen(false)}>
              <FaShoppingCart /> Cart
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" className="mobile-menu-link"><IoIosChatboxes /> let's talk </NavLink>
            </li>
          </ul>

          </>
        )}
      </div>
    </Navbar>
  );
};
const MenuIcon = styled(CgMenu)`
 margin-left: 20px;
 width: 30px;
 height: 30px;
`;
export default Nav;
