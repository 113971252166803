import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import axios from 'axios';

const columns = [
  { id: 'orderId', label: 'ORDER ID', minWidth: 150 },
  { id: 'orderStatus', label: 'ORDER STATUS', minWidth: 150, align: 'right' },
  { id: 'grandTotal', label: 'GRAND TOTAL', minWidth: 150, align: 'right' },
  { id: 'action', label: 'ACTION', minWidth: 150, align: 'right' },
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function CustomPaginationActionsTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.rajhospitalitygroup.com/checkouts/');
        console.log('API Response:', response.data); // Log the response to check structure

        // Create a map to aggregate orders and their status
        const orderMap = response.data.reduce((acc, item) => {
          if (!acc[item._id]) {
            acc[item._id] = {
              orderStatus: item.Orders_Status,
              grandTotal: item.Grand_Total || 'N/A',
            };
          }
          return acc;
        }, {});

        const fetchedData = Object.keys(orderMap).map(orderId => ({
          orderId,
          orderStatus: orderMap[orderId].orderStatus || 'N/A',
          grandTotal: orderMap[orderId].grandTotal || 'N/A',
          action: orderMap[orderId].orderStatus || 'N/A',
        }));

        setRows(fetchedData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusChange = (event, orderId) => {
    const newStatus = event.target.value;
    setRows((prevRows) =>
      prevRows.map((row) => (row.orderId === orderId ? { ...row, action: newStatus } : row))
    );
  };

  const handleSubmit = async () => {
    console.log('Rows before submitting:', rows); // Add this line for debugging

    try {
      const response = await axios.post('https://api.rajhospitalitygroup.com/update-orders', rows);
      console.log('Data submitted successfully:', response.data);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table" stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, fontSize: 16 }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => (
              <TableRow key={row.orderId}>
                <TableCell component="th" scope="row" style={{ width: 160, fontSize: 16 }}>
  <a href={`https://api.rajhospitalitygroup.com/invoice/${row.orderId}`} target="_blank" rel="noopener noreferrer">
    {row.orderId}
  </a>
</TableCell>
                <TableCell style={{ width: 160, fontSize: 16 }} align="right">
                  {row.orderStatus}
                </TableCell>
                <TableCell style={{ width: 160, fontSize: 16 }} align="right">
                  {row.grandTotal}
                </TableCell>
                <TableCell style={{ width: 160, fontSize: 16 }} align="right">
                  <Select
                    value={row.action}
                    onChange={(event) => handleStatusChange(event, row.orderId)}
                    style={{ fontSize: 16 }}
                  >
                    <MenuItem value="Orders Confirmed">Orders Confirmed</MenuItem>
                    <MenuItem value="Orders in Progress">Orders in Progress</MenuItem>
                    <MenuItem value="Orders Delivered">Orders Delivered</MenuItem>
                    <MenuItem value="Orders Cancelled">Orders Cancelled</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={columns.length} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={columns.length}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginTop: 16 }}>
        Submit
      </Button>
    </Box>
  );
}
