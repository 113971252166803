import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './contenteditor.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import styled from "styled-components";

const Wrapper = styled.section`
  .App {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
  }

  .ck.ck-editor__editable {
    min-height: 200px;
    font-size: 16px;
  }
`;

const Editor = ({ field, form }) => {
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    form.setFieldValue(field.name, data);
  };

  const handleEditorBlur = (event, editor) => {
    form.setFieldTouched(field.name, true);
  };

  return (
    <Wrapper>
      <div className='App'>
        <CKEditor
          editor={ClassicEditor}
          data={field.value}
          onReady={(editor) => {
           // console.log("Editor is ready to use!", editor);
          }}
          onChange={handleEditorChange}
          onBlur={handleEditorBlur}
        />
      </div>
    </Wrapper>
  );
};

const ContentEditor = () => {
  const [content, setContent] = useState({
    about: {
      companyIntro: '',
      mission: '',
      vision: '',
      bannerQuote: '',
      faq: []
    },
    contact: {
      address: '',
      phone: '',
      email: '',
      facebook: '',
      whatsapp: '',
      linkedin: '',
      mapEmbedded: '',
      servicesInfo: ''
    }
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get('https://api.rajhospitalitygroup.com/content');
        console.log('Fetched content:', response.data);

        setContent(prevContent => ({
          about: {
            ...prevContent.about,
            companyIntro: response.data.about?.companyIntro || '',
            mission: response.data.about?.mission || '',
            vision: response.data.about?.vision || '',
            bannerQuote: response.data.about?.bannerQuote || '',
            faq: response.data.about?.faq || []
          },
          contact: {
            ...prevContent.contact,
            address: response.data.contact?.address || '',
            phone: response.data.contact?.phone || '',
            email: response.data.contact?.email || '',
            facebook: response.data.contact?.facebook || '',
            whatsapp: response.data.contact?.whatsapp || '',
            linkedin: response.data.contact?.linkedin || '',
            mapEmbedded: response.data.contact?.mapEmbedded || '',
            servicesInfo: response.data.contact?.servicesInfo || ''
          }
        }));
      } catch (error) {
        console.error('Error fetching content:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchContent();
  }, []);

  useEffect(() => {
    console.log('Updated content state:', content);
  }, [content]);

  const handleChange = (e) => {
    const { name, value, dataset } = e.target;
    const section = dataset.section;
    const index = dataset.index;
    if (section === 'about' && typeof index !== 'undefined') {
      const updatedFaq = [...content.about.faq];
      updatedFaq[index][name] = value;
      setContent({
        ...content,
        about: {
          ...content.about,
          faq: updatedFaq
        }
      });
    } else {
      setContent({
        ...content,
        [section]: {
          ...content[section],
          [name]: value
        }
      });
    }
  };

  const handleEditorChange = (data, name, section, index = null) => {
    if (index !== null) {
      const updatedFaq = [...content.about.faq];
      updatedFaq[index][name] = data;
      setContent({
        ...content,
        [section]: {
          ...content[section],
          faq: updatedFaq
        }
      });
    } else {
      setContent({
        ...content,
        [section]: {
          ...content[section],
          [name]: data
        }
      });
    }
  };

  const handleAddFaq = () => {
    setContent({
      ...content,
      about: {
        ...content.about,
        faq: [...content.about.faq, { question: '', answer: '' }]
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form data before submission:', content);
    try {
      await axios.post('https://api.rajhospitalitygroup.com/update-content', content);
      alert('Content updated successfully!');
    } catch (error) {
      console.error('Error updating content:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content-editor container">
      <h1 className="text-center mb-4">Content Editor</h1>
      <form onSubmit={handleSubmit}>
        <div className="section mb-4">
          <h2>About Us Page</h2>
          <div className="form-group">
            <label htmlFor="companyIntro">Company Introduction:</label>
            <Editor
              field={{ name: "companyIntro", value: content.about.companyIntro }}
              form={{ setFieldValue: (field, value) => handleEditorChange(value, field, 'about'), setFieldTouched: () => { } }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="mission">Mission:</label>
            <Editor
              field={{ name: "mission", value: content.about.mission }}
              form={{ setFieldValue: (field, value) => handleEditorChange(value, field, 'about'), setFieldTouched: () => { } }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="vision">Vision:</label>
            <Editor
              field={{ name: "vision", value: content.about.vision }}
              form={{ setFieldValue: (field, value) => handleEditorChange(value, field, 'about'), setFieldTouched: () => { } }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="bannerQuote">Banner Quote:</label>
            <Editor
              field={{ name: "bannerQuote", value: content.about.bannerQuote }}
              form={{ setFieldValue: (field, value) => handleEditorChange(value, field, 'about'), setFieldTouched: () => { } }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="faq">FAQ Section:</label>
            {content.about.faq.map((item, index) => (
              <div key={index} className="faq-item mb-3">
                <div className="form-group">
                  <label htmlFor={`question-${index}`}>Question:</label>
                  <input
                    id={`question-${index}`}
                    name="question"
                    data-section="about"
                    data-index={index}
                    value={item.question}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={`answer-${index}`}>Answer:</label>
                  <Editor
                    field={{ name: "answer", value: item.answer }}
                    form={{ setFieldValue: (field, value) => handleEditorChange(value, field, 'about', index), setFieldTouched: () => { } }}
                  />
                </div>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleAddFaq}
            >
              Add FAQ
            </button>
          </div>
        </div>

        <div className="section mb-4">
          <h2>Contact Us Page</h2>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="address">Address:</label>
              <input
                id="address"
                name="address"
                data-section="contact"
                value={content.contact.address}
                onChange={handleChange}
                type="text"
                className="form-control"
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="phone">Phone:</label>
              <input
                id="phone"
                name="phone"
                data-section="contact"
                value={content.contact.phone}
                onChange={handleChange}
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="email">Email:</label>
              <input
                id="email"
                name="email"
                data-section="contact"
                value={content.contact.email}
                onChange={handleChange}
                type="text"
                className="form-control"
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="facebook">Facebook Link:</label>
              <input
                id="facebook"
                name="facebook"
                data-section="contact"
                value={content.contact.facebook}
                onChange={handleChange}
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="whatsapp">WhatsApp Link:</label>
              <input
                id="whatsapp"
                name="whatsapp"
                data-section="contact"
                value={content.contact.whatsapp}
                onChange={handleChange}
                type="text"
                className="form-control"
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="linkedin">LinkedIn Link:</label>
              <input
                id="linkedin"
                name="linkedin"
                data-section="contact"
                value={content.contact.linkedin}
                onChange={handleChange}
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="mapEmbedded">Embedded Map:</label>
            <input
              id="mapEmbedded"
              name="mapEmbedded"
              data-section="contact"
              value={content.contact.mapEmbedded}
              onChange={handleChange}
              type="text"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="servicesInfo">Services Information:</label>
            <Editor
              field={{ name: "servicesInfo", value: content.contact.servicesInfo }}
              form={{ setFieldValue: (field, value) => handleEditorChange(value, field, 'contact'), setFieldTouched: () => { } }}
            />
          </div>
        </div>

        <button type="submit" className="btn btn-primary">Save Changes</button>
      </form>
    </div>
  );
};

export default ContentEditor;