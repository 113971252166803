import React from 'react';
import styled from "styled-components";
import CustomPaginationActionsTable from './custompaginationactionstable';
const Orders = () => {
  return (
    <Wrapper>
        <div className='container-fluid'>
            <div className='row'>
        <div className='col-12 main-heading' ><h3 className='common-heading'>Orders</h3></div>
       <div className='row' style={{justifyContent:'space-between'}}>
       <div className='col-4'><h4 className='common-heading'> Sales Period:</h4></div>
       <div className='col-4'><h4 className='common-heading' style={{textAlign: 'right'}}> View Orders: 4/18</h4></div>
       </div>
       <div className='row section1'>
        <div className='col-6'>
        <h5 className='common-heading'> 08/12/2024-08/12/2024</h5>
        </div>
        <div className='col-3' style={{textAlign: '-webkit-right'}}>
        <h5 className='common-heading '> Product Category</h5>
        </div>
        <div className='col-3' style={{textAlign: '-webkit-right'}}>
        <h5 className='common-heading '> Sort by:  Best Seller</h5>
        </div>
        </div>

        </div>
        <div className='row section2'>
            <div className='col-3'>
                <div className='innerbox'>
                <div className='colorBox' style={{background:"#035ECF" ,}}></div>
                <h4  className='common-heading'>Orders Confirmed</h4>
                <h3 className='common-heading'>25</h3>
                </div>
            </div>
            <div className='col-3'>
                <div className='innerbox'>
                <div className='colorBox' style={{background:"#538B3E" ,}}></div>
                <h4  className='common-heading'>Orders in Progress</h4>
                <h3 className='common-heading'>8</h3>
                </div>
            </div>
            <div className='col-3'>
                <div className='innerbox'>
                <div className='colorBox' style={{background:"#FF5470" ,}}></div>
                <h4  className='common-heading'>Order Complete</h4>
                <h3 className='common-heading'>15</h3>
                </div>
            </div>
            <div className='col-3'>
                <div className='innerbox'>
                <div className='colorBox' style={{background:"#515C6B" ,}}></div>
                <h4  className='common-heading'>Orders on Hold</h4>
                <h3 className='common-heading'>6</h3>
                </div>
            </div>

        </div>
        <div className='row section3'>
            <CustomPaginationActionsTable/>
        </div>
        </div>
    </Wrapper>
  )
}
const Wrapper = styled.section`
.main-heading{
    padding: 30px;
    box-shadow: 0px 0px 10px #00000040;
    border-radius: 12px;

    margin-bottom: 20px;
}
.section1{
    h5{
        padding: 14px 16px;
        box-shadow: 0px 0px 6px #00000040;
        width: fit-content;
        border-radius: 6px;
    }
}
.section2{
    .colorBox{

        width: 50px;
        height: 40px;
        border-radius: 8px;
        margin-bottom: 30px;
    }
    .col-3{
        padding: 20px;

        .innerbox{
            display: flex;
        justify-content: start;
        align-items: baseline;
        flex-direction: column;
        border-radius: 16px;
            padding: 20px;
            box-shadow: 0px 0px 6px #00000040;
        }
    }
}
button{
    color : #000000;

}
.MuiToolbar-gutters{
    p{
        font-size: 16px;
        margin-bottom: 0;
    }
    button{
        font-size: 16px;
    }
    select{
        font-size: 16px;
    }
    svg{
        fill: #000000;
        width: 20px;

        height: 20px;
    }

}
th{
    color: #035ECF;
    font-weight: 600;
}
.actionbtn{
    border-radius: 9px;

    text-align: center;
    padding: 12px;

}
.delivery_time{
    background: #538B3E;
    color: #ffffff;
    font-weight: 600;
}
.actionAll{
    background: #ff0000;
    color: #ffffff;
    font-weight: 600;
}
`;
export default Orders;