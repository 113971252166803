import React, { useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import styled from "styled-components";

function ResetPassword() {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState({ text: "", type: "" });
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: ""
  });
  const [resetSuccess, setResetSuccess] = useState(false);

  const validatePassword = (password) => {
    let error = "";
    if (password.length > 12) {
      error = "Password max limit 12 characters";
    } else if (!/(?=.*[A-Z])/.test(password)) {
      error = "Password must contain at least one uppercase letter";
    } else if (!/(?=.*[a-z])/.test(password)) {
      error = "Password must contain at least one lowercase letter";
    } else if (!/(?=.*\d)/.test(password)) {
      error = "Password must contain at least one numeric digit";
    } else if (!/(?=.*[!@#$%^&*(),.?":{}|<>])/.test(password)) {
      error = "Password must contain at least one special character";
    }
    return error;
  }

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setErrors(prevErrors => ({
      ...prevErrors,
      password: validatePassword(value)
    }));
  }

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setErrors(prevErrors => ({
      ...prevErrors,
      confirmPassword: value !== password ? "Passwords do not match" : ""
    }));
  }

  const handleResetPassword = async () => {
    if (errors.password || errors.confirmPassword) {
      setMessage({ text: "Please fix the errors before submitting.", type: "error" });
      return;
    }

    try {
      const response = await fetch("https://api.rajhospitalitygroup.com/reset-password", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json'
        },
        body: JSON.stringify({ token, password })
      });

      const result = await response.json();
      if (response.ok) {
        setMessage({ text: "Password has been reset successfully.", type: "success" });
        setResetSuccess(true);
      } else {
        setMessage({ text: result.message || "An error occurred.", type: "error" });
      }
    } catch (error) {
      console.error('Error occurred while resetting password:', error);
      setMessage({ text: "An error occurred. Please try again.", type: "error" });
    }
  }

  return (
    <Wrapper>
      <div className='container'>
        <div className='row ResetPasswordPage'>
          <div className='col-md-6 ResetPasswordSection'>
            <h3 className='heading-common'>Reset Your Password</h3>
            <label>New Password</label>
            <input
              type='password'
              placeholder='New password'
              value={password}
              onChange={handlePasswordChange}
              className='form-control'
            />
            {errors.password && <ErrorText>{errors.password}</ErrorText>}
            <br />
            <label>Confirm New Password</label>
            <input
              type='password'
              placeholder='Confirm new password'
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              className='form-control'
            />
            {errors.confirmPassword && <ErrorText>{errors.confirmPassword}</ErrorText>}
            <br />
            {!resetSuccess && (
              <button onClick={handleResetPassword} className='btn btn-primary'>Submit</button>
            )}
            {message.text && <Message type={message.type}>{message.text}</Message>}
            {resetSuccess && (
              <NavLink to="/Profile" className="btn btn-success">Go to Login</NavLink>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-top: 100px;
  padding-top: 6rem;
  input {
    text-transform: inherit;
  }
  h3 {
    font-weight: 800;
    text-align: center;
    font-size: 3rem;
  }
  input {
    max-width: 100%;
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    &:focus {
      outline: none;
      border-color: dodgerblue;
    }
  }
  label {
    display: block;
    font-weight: 600;
    font-size: 1.6rem;
    padding-bottom: 10px;
  }
  button, .btn {
    width: 100%;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 30px;
    text-align: center;
    text-decoration: none; /* Remove underline from NavLink */
  }
  button {
    background-color: #538B3E;
    &:hover {
      background-color: #34A853;
    }
    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  .btn-success {
    background-color: #4CAF50;
    &:hover {
      background-color: #45a049;
    }
  }
  .ResetPasswordPage {
    justify-content: center;
  }
`;

const ErrorText = styled.p`
  color: red;
  margin-top: -10px;
  margin-bottom: 20px;
`;

const Message = styled.p`
  text-align: center;
  color: ${props => (props.type === "error" ? "red" : "green")};
`;

export default ResetPassword;
