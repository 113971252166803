import React, { useEffect, useState } from 'react';
import { TextField, Checkbox, Button, MenuItem, CircularProgress, IconButton } from '@mui/material';
import { useFormik } from 'formik';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styled from 'styled-components';
import * as Yup from 'yup';

const Wrapper = styled.section`
  padding: 0 30px;
.error {
  color: red;
  font-size: 12px;
}
  h3 {
    padding: 20px 0;
    text-align: center;
  }

  .imagebox1 {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    label {
      border: 2px dashed #00000040;
      box-shadow: none;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #00000000;
      color: #538B3E;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: #f0f0f0;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .MuiTextField-root {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);

      input {
        color: #ffffff;
        font-weight: 600;
        padding: 8px 16px;
        max-width: 100%;
      }
    }
  }

  .imagebox2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .MuiButton-root {
      margin-top: 10px;
    }
  }

  .margin_T {
    margin: 20px 0;
  }

  .second_input_box {
    .MuiTextField-root {
      margin-bottom: 20px;
      font-size: 16px;
    }

    label {
      font-size: 14px;
      background: #ffffff;
      padding: 8px;
      border-radius: 4px;
    }
  }

  .upload_button {
    width: 100%;
    margin-top: 20px;
    background: #538B3E;
    &:hover{
        background: #ffffff;
        color: #538B3E;

        box-shadow: 0px 0px 10px #538B3E;
    }
  }
    .cancel_button{
    background: #FF0000;
    &:hover{
        background: #ffffff;
         color: #538B3E;

        box-shadow: 0px 0px 10px #538B3E;
    }
    }
    .faq {
        margin-bottom: 1rem;
         background: #ffffff;
    }
         .faq_button{
         background: #538B3E;
          &:hover{
          border: 1px solid #538B3E;
          color: #000000;
           background: #ffffff;
          }

         }
`;

const ProductForm = () => {
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [imageNames, setImageNames] = useState(['', '', '', '']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categories, setCategories] = useState([]);
  const [events, setEvent] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [faqs, setFaqs] = useState([{ question: '', answer: '' }]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Product Name is required'),
    category: Yup.string().required('Category is required'),
    events: Yup.string().required('event is required'),
    price: Yup.number().required('Price is required').positive('Must be positive'),
    sku: Yup.string().required('SKU is required'),
    productDescription: Yup.string().required('Product Description is required'),
  });

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setLoadingCategories(true);
    try {
      const response = await fetch('https://api.rajhospitalitygroup.com/product-categories');
      const data = await response.json();
      setCategories(data);
    } catch (err) {
      console.error('Error fetching categories:', err);
    } finally {
      setLoadingCategories(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      productDescription: '',
      color: '',
      category: '',
      events: '',
      price: '',
      sku: '',
      images: [],
      faqs: [{ question: '', answer: '' }],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (files.length === 0) {
        alert('Please upload at least one image.');
        return;
      }

      setIsSubmitting(true);
      const confirmSubmission = window.confirm('Are you sure you want to submit this product?');
      if (!confirmSubmission) {
        setIsSubmitting(false);
        return;
      }

      const fileNames = await uploadImages(files);
      if (!fileNames) {
        alert('Image upload failed. Please try again.');
        setIsSubmitting(false);
        return;
      }

      const productData = {
        ...values,
        color: values.color.split(',').map((color) => color.trim()),
        sizes: selectedSizes,
        images: fileNames,
      };

      try {
        const response = await fetch('https://api.rajhospitalitygroup.com/products', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(productData),
        });

        if (response.ok) {
          alert('Product added successfully.');
        } else {
          const errorText = await response.text();
          console.error('Product addition failed:', errorText);
          alert(`Product addition failed: ${errorText}`);
        }
      } catch (error) {
        console.error('Error adding product:', error);
        alert('Error adding product.');
      } finally {
        previews.forEach((url) => URL.revokeObjectURL(url));
        resetFormState();
        setIsSubmitting(false);
      }
    },
  });

  const uploadImages = async (files) => {
    const formData = new FormData();
    files.forEach((file, index) => {
      if (file) {
        formData.append('profileImages', file, imageNames[index] || file.name);
      }
    });

    try {
      const response = await fetch('https://api.rajhospitalitygroup.com/upload', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Image upload failed:', errorText);
        return null;
      }

      const fileNames = await response.json();
      console.log('Uploaded file names:', fileNames);
      return fileNames;
    } catch (error) {
      console.error('Error uploading images:', error);
      return null;
    }
  };


  useEffect(() => {
    console.log('Formik Errors:', formik.errors);
  }, [formik.errors]);

  const handleFileChange = (event, index) => {
    const selectedFile = event.target.files[0];
    const allowedFormats = ['image/jpeg', 'image/png', 'image/gif']; // Add more formats if needed

    if (!selectedFile) return;

    if (!allowedFormats.includes(selectedFile.type)) {
      alert('Invalid file format. Please upload an image in JPEG, PNG, or GIF format.');
      return;
    }

    const newFiles = [...files];
    const newPreviews = [...previews];

    newFiles[index] = selectedFile;
    newPreviews[index] = URL.createObjectURL(selectedFile);

    setFiles(newFiles);
    setPreviews(newPreviews);

    const newImageNames = [...imageNames];
    newImageNames[index] = selectedFile.name;
    setImageNames(newImageNames);
  };


  const handleImageNameChange = (event, index) => {
    const newImageNames = [...imageNames];
    newImageNames[index] = event.target.value;
    setImageNames(newImageNames);
  };

  const handleSizeChange = (size) => {
    setSelectedSizes((prevSelectedSizes) =>
      prevSelectedSizes.includes(size)
        ? prevSelectedSizes.filter((s) => s !== size)
        : [...prevSelectedSizes, size]
    );
  };
  const handleFaqChange = (index, field, value) => {
    const updatedFaqs = [...faqs];
    updatedFaqs[index][field] = value;
    setFaqs(updatedFaqs);
    formik.setFieldValue('faqs', updatedFaqs);
  };

  const addFaq = () => {
    setFaqs([...faqs, { question: '', answer: '' }]);
  };

  const removeFaq = (index) => {
    const updatedFaqs = faqs.filter((_, i) => i !== index);
    setFaqs(updatedFaqs);
    formik.setFieldValue('faqs', updatedFaqs);
  };
  const resetFormState = () => {
    formik.resetForm();
    setFiles([]);
    setPreviews([]);
    setSelectedSizes([]);
    setImageNames(['', '', '', '']);
    setIsSubmitting(false);
  };

  return (
    <Wrapper>
      <h3>Add Product</h3>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-4 imagebox1">
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<CloudUploadIcon />}
                >
                  Browse Image
                  <input
                    type="file"
                    hidden
                    onChange={(e) => handleFileChange(e, 0)}
                  />
                </Button>
                {previews[0] && <img src={previews[0]} alt="preview" />}
                {previews[0] && (
                  <TextField
                    fullWidth
                    id={`imageName-0`}
                    label="Rename Image"
                    variant="outlined"
                    value={imageNames[0]}
                    onChange={(e) => handleImageNameChange(e, 0)}
                  />
                )}
              </div>
              <div className="col-4 imagebox1">
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<CloudUploadIcon />}
                >
                  Browse Image
                  <input
                    type="file"
                    hidden
                    onChange={(e) => handleFileChange(e, 1)}
                  />
                </Button>
                {previews[1] && <img src={previews[1]} alt="preview" />}
                {previews[1] && (
                  <TextField
                    fullWidth
                    id={`imageName-1`}
                    label="Rename Image"
                    variant="outlined"
                    value={imageNames[1]}
                    onChange={(e) => handleImageNameChange(e, 1)}
                  />
                )}
              </div>
              <div className="col-4 imagebox1 imagebox2">
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<CloudUploadIcon />}
                >
                  Browse Image
                  <input
                    type="file"
                    hidden
                    onChange={(e) => handleFileChange(e, 2)}
                  />
                </Button>
                {previews[2] && <img src={previews[2]} alt="preview" />}
                {previews[2] && (
                  <TextField
                    fullWidth
                    id={`imageName-2`}
                    label="Rename Image"
                    variant="outlined"
                    value={imageNames[2]}
                    onChange={(e) => handleImageNameChange(e, 2)}
                  />
                )}
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<CloudUploadIcon />}
                >
                  Browse Image
                  <input
                    type="file"
                    hidden
                    onChange={(e) => handleFileChange(e, 3)}
                  />
                </Button>
                {previews[3] && <img src={previews[3]} alt="preview" />}
                {previews[3] && (
                  <TextField
                    fullWidth
                    id={`imageName-3`}
                    label="Rename Image"
                    variant="outlined"
                    value={imageNames[3]}
                    onChange={(e) => handleImageNameChange(e, 3)}
                  />
                )}
              </div>
              <div className="col-6 margin_T">
                <TextField
                  fullWidth
                  id="color"
                  label="Color (comma-separated)"
                  variant="outlined"
                  value={formik.values.color}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="col-6 margin_T">
                <div>
                  <label>Size</label>
                  <div>
                    {['S', 'M', 'L', 'XL', 'XXL'].map((size) => (
                      <label key={size}>
                        <Checkbox
                          checked={selectedSizes.includes(size)}
                          onChange={() => handleSizeChange(size)}
                          size="large"
                        />
                        {size}
                      </label>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <CKEditor
                  editor={ClassicEditor}
                  data={formik.values.productDescription}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    formik.setFieldValue('productDescription', data);
                  }}
                />
                {formik.errors.productDescription && formik.touched.productDescription && (
                  <div className="error">{formik.errors.productDescription}</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row second_input_box">
              <div className="col-12">
                <TextField
                  fullWidth
                  id="name"
                  label="Product Name"
                  variant="outlined"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.name && formik.touched.name && (
                  <div className="error">{formik.errors.name}</div>
                )}
              </div>
              <div className="col-6">
                {loadingCategories ? (
                  <CircularProgress />
                ) : (
                  <TextField
                    fullWidth
                    id="category"
                    name="category"
                    label="Category"
                    variant="outlined"
                    select
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    error={formik.touched.category && Boolean(formik.errors.category)}
                    helperText={formik.touched.category && formik.errors.category}
                  >
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.category_name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </div>
              <div className="col-6">
                <TextField
                  fullWidth
                  id="events"
                  label="events"
                  variant="outlined"
                  value={formik.values.events}
                  onChange={formik.handleChange}
                />
                {formik.errors.setEvent && formik.touched.setEvent && (
                  <div className="error">{formik.errors.setEvent}</div>
                )}
              </div>
              <div className="col-6">
                <TextField
                  fullWidth
                  id="price"
                  label="Price"
                  variant="outlined"
                  type="number"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                />
                {formik.errors.price && formik.touched.price && (
                  <div className="error">{formik.errors.price}</div>
                )}
              </div>
              <div className="col-6">
                <TextField
                  fullWidth
                  id="sku"
                  label="SKU"
                  variant="outlined"
                  value={formik.values.sku}
                  onChange={formik.handleChange}
                />
                {formik.errors.sku && formik.touched.sku && (
                  <div className="error">{formik.errors.sku}</div>
                )}
              </div>


              <div className='col-12'>
                <h4>FAQs</h4>
                {faqs.map((faq, index) => (
                  <div key={index} className="faq">
                    <TextField
                      fullWidth
                      name={`faqs[${index}].question`}
                      label="Question"
                      variant="outlined"
                      value={faq.question}
                      onChange={(e) => handleFaqChange(index, 'question', e.target.value)}
                    />
                    <TextField
                      fullWidth
                      name={`faqs[${index}].answer`}
                      label="Answer"
                      variant="outlined"
                      value={faq.answer}
                      onChange={(e) => handleFaqChange(index, 'answer', e.target.value)}
                    />
                    <IconButton onClick={() => removeFaq(index)}>
                      <RemoveIcon />
                    </IconButton>
                  </div>
                ))}
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={addFaq}
                  className="faq_button"
                >
                  Add FAQ
                </Button>
              </div>
              <div className="col-12">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  className="upload_button"
                >
                  {isSubmitting ? 'Submitting...' : 'Publish Product'}
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={resetFormState}
                  disabled={isSubmitting}
                  className="upload_button cancel_button"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default ProductForm;
