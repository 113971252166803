import React, { useState } from 'react';
import { useNavigate,NavLink } from 'react-router-dom'; // useNavigate hook for navigation
import styled from "styled-components";
import  {Button}  from './styles/button';


function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // useNavigate hook for navigation

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can add your logic for authentication
    // For simplicity, let's just navigate to the dashboard on successful login
    if (email === "shareandaware.com@gmail.com" && password === "123456") {
      // Assuming successful login, navigate to the dashboard
      navigate("/admin/dashboard");
    } else {
      // Handle invalid credentials
      alert("Invalid email or password");
    }
  };

  return (
    <Wrapper>
    <div className='containerr'>
      <h3 className='common-heading'>Rabbit & Turtle Admin</h3>
      <p>Etiam quis quam urna. Aliquam odio erat, accumsan eu nulla in</p>

      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input type="email" value={email} onChange={handleEmailChange} />
        </div>
        <div>
          <label>Password:</label>
          <input type="password" value={password} onChange={handlePasswordChange} />
        </div>
        <NavLink to="/ForgotPassword" className="navbar-link">
            <h4 className='common-heading'>

            forgot Password?

          </h4>
          </NavLink>
        <Button type="submit">Login</Button>

      </form>
    </div>
    </Wrapper>
  );
}
const Wrapper = styled.section`
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
h3{
  text-align: center;
  padding-bottom: 1rem;
  font-size: 3.6rem;
}
label{
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin: 10px 0px;
}
input{
  width: 100%;
  border-radius: 10px;
}
h4{

}
button{
  width: 100%;
  border-radius: 5px;
  border: 1px solid #13aa52;

  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 10px 25px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  &:hover {
    box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
    transform: translateY(-2px);
  }
}

}
@media (min-width: 768px) {
  button{
    padding: 10px 30px;
  }
}
h4{
  text-align: center;
  margin: 20px 0px;
  font-size: 20px;

}
a{
  .common-heading{
    color: #035ECF;
  }
}
`;
export default AdminLogin;
