import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const PageNavigation = ({ title }) => {
  return (
    <Wrapper>
       <h1 className="common-heading">{title}</h1>
      <div className="inside">

      <NavLink to="/" className="common-heading">Home</NavLink>&nbsp;&nbsp;----&nbsp;&nbsp;{title}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`

  background-color: #F5F5F5;
  text-align:center;
  padding: 50px 0px;
  .inside{
    display: flex;
    justify-content: center;
    align-items: center;
  }



  a {
    font-size: 1.2rem;
  }
  h1{
    font-size: 4rem;
  }
`;

export default PageNavigation;