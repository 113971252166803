// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminRoutes from './adminroutes';
import WebsiteRoutes from './websiteroutes';

function App() {
  return (
    <Router>

      <Routes>
        {/* Define a route for the admin panel */}
        <Route path="/admin/*" element={<AdminRoutes />} />

        {/* Define a route for the main website */}
        <Route path="/*" element={<WebsiteRoutes />} />
      </Routes>
    </Router>
  );
}

export default App;
