import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HomepageSlider from './homepageslider';
import AdminSliderEditor from './adminslidereditor';

const ImageSliderEditor = () => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch images from the server
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://api.rajhospitalitygroup.com/sliderimage');
        const imageSrcs = response.data.map((image) => ({
          id: image._id,
          src: `https://api.rajhospitalitygroup.com${image.src}`,
        }));
        setImages(imageSrcs);
      } catch (error) {
        setError('Error fetching images');
        console.error('Error fetching images', error);
      }
    };
    fetchImages();
  }, []);

  const updateImages = (newImages) => {
    setImages(newImages);
  };

  return (
    <div>
      <h1>Homepage Slider</h1>
      <HomepageSlider images={images} />
      <h1>Admin Slider Editor</h1>
      <AdminSliderEditor images={images} updateImages={updateImages} />
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default ImageSliderEditor;
