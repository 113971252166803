import React, { useEffect, useState, useRef } from 'react';
import styled from "styled-components";
import BlazeSlider from 'blaze-slider';
import 'blaze-slider/dist/blaze.css';
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress'; // Example import

const CategoriesSlider = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const sliderRef = useRef(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (categories.length) {
      // Destroy the previous slider instance if it exists
      if (sliderRef.current) {
        sliderRef.current.destroy();
      }

      // Ensure the slider is initialized after the component has mounted and data is available
      const sliderElement = document.querySelector('.blaze-slider1');
      if (sliderElement) {
        sliderRef.current = new BlazeSlider(sliderElement, {
          all: {
            enableAutoplay: true,
            draggable: true,
            stopAutoplayOnInteraction: true,
            autoplayInterval: 3000,
            transitionDuration: 1000,
            slidesToShow: 5,
            slideGap: '30px',
            loop: true,
          },
          '(max-width: 900px)': {
            slidesToShow: 3,
          },
          '(max-width: 500px)': {
            slidesToShow: 1,
          },
        });
      }
    }

    // Cleanup on component unmount
    return () => {
      if (sliderRef.current) {
        sliderRef.current.destroy();
      }
    };
  }, [categories]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.rajhospitalitygroup.com/product-categories');
      setCategories(response.data);
      setError('');
    } catch (err) {
      console.error('Error fetching categories:', err);
      setError('Error fetching categories. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <div>{error}</div>
      ) : (
        <div className='container'>
          <h3 className='common-heading'>T-shirt Printing for Everyone</h3>
          <p>What’s more, we do it right!</p>
          <div className="blaze-slider1">
            <div className="blaze-container">
              <div className="blaze-track-container">
                <div className="blaze-track">
                  {categories.map((category, index) => (
                    <div key={category._id}>
                      <img src={category.images[0]} alt={`slide${index + 1}`} />
                      <h4>{category.category_name}</h4>
                    </div>
                  ))}
                </div>
              </div>
              <div className="my-nav-container">
                <button className="blaze-prev"><FaChevronLeft /></button>
                <button className="blaze-next"><FaChevronRight /></button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  .container {
    text-align: center;
    padding-top: 70px;
  }
  img {
    max-width: 200px;
    max-height: 200px;
    width: 200px;
    height: 200px; /* Adjust height as needed */
    object-fit: cover;
    border-radius: 50%; /* Circular images */
    transition: border 0.3s ease, background 0.3s ease;
  }
  .blaze-track {
    text-align: center;
    h4 {
      padding: 20px 0;
      font-weight: 600;
    }
  }
  .blaze-slider1 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .blaze-prev,
  .blaze-next {
    background-color: transparent;
    color: #000;
    font-size: 40px;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    padding: 12px;
    transform: translateY(-50%);
  }
  .blaze-prev {
    left: 10px;
  }
  .blaze-next {
    right: 10px;
  }
  .blaze-prev:hover,
  .blaze-next:hover {
    color: #538B3E;
    transition: 0.3s;
  }
`;

export default CategoriesSlider;
