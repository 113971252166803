// ContactForm.js
import React, { useState } from 'react';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        discussionTopic: '',
        budget: '',
        agreement: false
    });

    const [formStatus, setFormStatus] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://api.rajhospitalitygroup.com/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setFormStatus('Form submitted successfully.');
                setFormData({
                    name: '',
                    email: '',
                    message: '',
                    discussionTopic: '',
                    budget: '',
                    agreement: false
                });
            } else {
                setFormStatus('Form submission failed.');
            }
        } catch (error) {
            setFormStatus('Error submitting form.');
            console.error('Error submitting form:', error);
        }
    };

    return (
        <>
            <div className="contactform">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-6">
                            <input
                                type="text"
                                placeholder="What’s Your Name?"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                aria-label="Your Name"
                            />
                            <input
                                type="text"
                                placeholder="Select Discussion Topic"
                                name="discussionTopic"
                                value={formData.discussionTopic}
                                onChange={handleChange}
                                required
                                aria-label="Discussion Topic"
                            />
                        </div>
                        <div className="col-6">
                            <input
                                type="email"
                                placeholder="Email Address"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                aria-label="Email Address"
                            />
                            <input
                                type="text"
                                placeholder="What’s your budget?"
                                name="budget"
                                value={formData.budget}
                                onChange={handleChange}
                                required
                                aria-label="Budget"
                            />
                        </div>
                        <div className="col-12">
                            <textarea
                                name="message"
                                placeholder="A brief discussion about this project, describe us"
                                value={formData.message}
                                onChange={handleChange}
                                cols="30"
                                rows="5"
                                required
                                aria-label="Message"
                            ></textarea>
                        </div>
                        <div className="col-12 mobile_section_contact">
                            <input
                                type="checkbox"
                                id="agreement"
                                name="agreement"
                                checked={formData.agreement}
                                onChange={handleChange}
                                style={{ width: '30px' }}
                                required
                                aria-label="Agreement"
                            />
                            <label htmlFor="agreement" style={{ color: '#000000', fontSize: '12px' }}>
                                I agree to all Terms & Conditions and Fees, I accept the Privacy & Policy
                            </label>
                        </div>
                        <div className="col-12">
                            <button type="submit" id="submit_message" className="btn btn-primary">SEND MESSAGE</button>
                        </div>
                    </div>
                    {formStatus && <p className="form-status" style={{color: '#2c8b48', fontSize: '24px;' , marginTop: '20px',fontWeight: '600' }}>{formStatus}</p>}
                </form>
            </div>
        </>
    );
};

export default ContactForm;
