// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import styled from 'styled-components';

// const MainContentEditor = () => {
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [folder, setFolder] = useState('Hero_Slider');
//   const [status, setStatus] = useState('');
//   const [uploadedImage, setUploadedImage] = useState('');
//   const [replaceImage, setReplaceImage] = useState(''); // Image to replace
//   const [imagesInFolder, setImagesInFolder] = useState([]);

//   useEffect(() => {
//     const fetchImages = async () => {
//       try {
//         const response = await axios.get(`https://api.rajhospitalitygroup.com/images/${folder}`);
//         setImagesInFolder(response.data.images);
//       } catch (error) {
//         setStatus('Failed to fetch images.');
//       }
//     };

//     fetchImages();
//   }, [folder]);

//   const handleFileChange = (event) => {
//     setSelectedImage(event.target.files[0]);
//   };

//   const handleFolderChange = (event) => {
//     setFolder(event.target.value);
//   };

//   const handleReplaceImageChange = (event) => {
//     setReplaceImage(event.target.value);
//   };

//   const handleUpload = async () => {
//     if (!selectedImage) {
//       setStatus('Please select an image.');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('image', selectedImage);
//     formData.append('replaceImage', replaceImage); // Append image to replace

//     try {
//       const response = await axios.post(`https://api.rajhospitalitygroup.com/images/${folder}`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });
//       setStatus(response.data.message);
//       setUploadedImage(response.data.fileUrl);
//     } catch (error) {
//       setStatus('Upload failed.');
//     }
//   };

//   return (
//     <Wrapper>
//       <h1>Main Content Editor</h1>
//       <div>
//         <h2>Image Uploader</h2>
//         <div>
//           <label>
//             Select Folder:
//             <SelectFolder value={folder} onChange={handleFolderChange}>
//               <option value="Hero_Slider">Hero Slider</option>
//               <option value="Call_To_Action">Call To Action</option>
//               <option value="Three_categories">Three Categories</option>
//             </SelectFolder>
//           </label>
//         </div>
//         <div>
//           <label>
//             Select Image to Replace:
//             <SelectImage value={replaceImage} onChange={handleReplaceImageChange}>
//               <option value="">Select an image</option>
//               {imagesInFolder.map(image => (
//                 <option key={image.fileName} value={image.fileName}>
//                   {image.fileName}
//                 </option>
//               ))}
//             </SelectImage>
//           </label>
//         </div>
//         <div>
//           <FileInput type="file" onChange={handleFileChange} />
//         </div>
//         <UploadButton onClick={handleUpload}>Upload Image</UploadButton>
//         <StatusMessage>{status}</StatusMessage>
//         {uploadedImage && <img src={uploadedImage} alt="Uploaded" />}
//       </div>
//     </Wrapper>
//   );
// };

// const Wrapper = styled.section`
//   text-align: center;
//   padding: 20px;
// `;

// const SelectFolder = styled.select`
//   margin-top: 10px;
//   padding: 5px;
//   font-size: 16px;
// `;

// const SelectImage = styled.select`
//   margin-top: 10px;
//   padding: 5px;
//   font-size: 16px;
// `;

// const FileInput = styled.input`
//   margin-top: 10px;
// `;

// const UploadButton = styled.button`
//   margin-top: 10px;
//   padding: 10px 20px;
//   background-color: #538B3E;
//   color: white;
//   border: none;
//   cursor: pointer;

//   &:hover {
//     background-color: #3e6e2f;
//   }
// `;

// const StatusMessage = styled.p`
//   margin-top: 10px;
//   font-size: 16px;
//   color: #333;
// `;

// export default MainContentEditor;


import React from 'react';
import ContentEditor from './contenteditor';

const MainContentEditor = () => {
  return (
    <div>MainContentEditor

        <ContentEditor/>
    </div>
  )
}

export default MainContentEditor