import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

const ProductCard = ({ product, onEdit, onDelete, onView }) => {
  // Extract the first image URL from the images array or set a default image path
  const firstImage = product.images && product.images.length > 0 ? product.images[0] : '/static/images/default.jpg';

  return (
    <Wrapper>
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          component="img"
          alt={product.name}
          image={`http://localhost:3000/images/product/${firstImage}`} // Use the first image URL

        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            <b>{product.name}</b>
          </Typography>
          <Typography variant="body2" color="text.secondary" className="detail">
            Category: {product.category}<br />
            <div className="color-boxes">
              Colors:
              {product.colors.map((color, index) => (
                <ColorBox key={index} color={color} />
              ))}
            </div>
            Featured: {product.featured ? 'Yes' : 'No'}<br />
            Price: ₹ {product.price}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" className="btn_1" onClick={() => onView(product.id)}>View</Button>
          <Button size="small" className="btn_1" onClick={() => onEdit(product.id)}>Edit</Button>
          <Button size="small" className="btn_2" onClick={() => onDelete(product.id)}>Delete</Button>
        </CardActions>
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .MuiCard-root {
    padding: 12px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #00000020;
    img {
      width: 100%;
      height: 200px;
      object-fit: contain;
      background: #ccc;
      border-radius: 5px;
    }
    .detail {
      font-weight: 600;
      font-size: 14px;
      .color-boxes {
        display: flex;
        align-items: center;
        margin-top: 8px;
        .color-box {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          border-radius: 5px;
          border: 1px solid #000;
        }
      }
    }
    .MuiCardActions-root {
      display: flex;
      justify-content: space-between;
      padding: 12px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .MuiButton-root {
      font-size: 14px;
      border-radius: 20px;
      padding: 5px 20px;
      width: 33%;
    }
    .btn_1 {
      color: #035ecf;
      border: 2px solid #035ecf;
    }
    .btn_2 {
      color: #ff5470;
      border: 2px solid #ff5470;
    }
  }
`;

const ColorBox = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid #000000;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  margin-left: 10px;
`;

export default ProductCard;
