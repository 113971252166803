import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BarChart } from '@mui/x-charts/BarChart';
import styled from "styled-components";
import LinearProgressWithLabel from './countrydata';
import Button from '@mui/material/Button';

const MainPage = () => {
  const [orderData, setOrderData] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [confirmedOrders, setConfirmedOrders] = useState(0);

  useEffect(() => {
    axios.get('https://api.rajhospitalitygroup.com/checkouts')
      .then(response => {
        const data = response.data;
        setOrderData(data);

        // Calculate total sales, total orders, and confirmed orders
        let sales = 0;
        let orders = data.length;
        let confirmed = data.filter(order => order.Orders_Status === 'Orders Confirmed').length;

        data.forEach(order => {
          sales += order.Grand_Total;
        });

        setTotalSales(sales);
        setTotalOrders(orders);
        setConfirmedOrders(confirmed);
      })
      .catch(error => {
        console.error('Error fetching the data', error);
      });
  }, []);

  return (
    <Wrapper>
      <div className='container-fluid'>
        <div className='row topsection '>
          <div className='col-6'>
            <h3 className='common-heading'>Dashboard</h3>
          </div>
          <div className='col-3'><h4 className='common-heading'>Data Refreshed</h4></div>
          <div className='col-3'><h4 className='common-heading'>September 28, 2023 12:35 PM</h4></div>
        </div>
        <div className='row'>
          <div className='col-7 '>
            <div className='row boxsha hompagemargin'>
              <div className='col-2 logomainpage'>
                <img src='../../images/logo.png' alt='mainpagelogo.png' />
              </div>
              <div className='col-9 section2 '>
                <h1 className='common-heading'>Rabbit and Turtle - Retail</h1>
                <p>Aliquam erat volutpat. Duis molestie ultrices tempus. Mauris sem orci, euismod sit amet.</p>
                <div className='row'>
                  <h4 className='common-heading' style={{ paddingBottom: '20px' }}> Average Rate 2023</h4>
                  <div className='col-12'>
                    <div className='row'>
                      <div className='col-4'>
                        <div className='row'>
                          <div className='col-4'>
                            <img src='../../images/icon.png' />
                          </div>
                          <div className='col-8'>
                            <h4 className='common-heading'>₹{totalSales.toLocaleString()}</h4>
                            <p>Total Sales</p>
                          </div>
                        </div>
                      </div>
                      <div className='col-4'>
                        <div className='row'>
                          <div className='col-4'>
                            <img src='../../images/base.png' />
                          </div>
                          <div className='col-8'>
                            <h4 className='common-heading'>{totalOrders}</h4>
                            <p>Total Orders</p>
                          </div>
                        </div>
                      </div>
                      <div className='col-4'>
                        <div className='row'>
                          <div className='col-4'>
                            <img src='../../images/icon2.png' />
                          </div>
                          <div className='col-8'>
                            <h4 className='common-heading'>{confirmedOrders}</h4>
                            <p>Confirmed&nbsp;Orders</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className='row boxsha hompagemargin'>
              <h4 className='common-heading' style={{ paddingTop: '50px', }}>Sales Statistic 2022</h4>
              <BarChart
                xAxis={[{ scaleType: 'band', data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Aug', 'Sep'], categoryGapRatio: 0.6, label: 'Month', }]}
                series={[{ data: [4, 3, 6, 5, 4, 2, 9, 5], label: 'Revenue', }, { data: [1, 6, 5, 3, 8, 2, 7, 8], label: 'Expense' }]}
                colors={['#00193B', '#E2E1E1']}
                width={800}
                height={300}
                slotProps={{
                  bar: {
                    clipPath: `inset(0px round 50px) `,
                  },
                }}
              />
            </div>
          </div>
          <div className='col-4 boxsha' style={{ margin: '20px 0px' }}>
            <h4 className='common-heading'>
              Countries/State wise reporting
            </h4>
            <h5>Total</h5>
            <h4 className='common-heading'>₹152K</h4>
            <LinearProgressWithLabel />
            <Button fullWidth variant="contained" color="success" style={{ padding: '15px', marginTop: '20px', borderRadius: '9px', textTransform: 'capitalize' }}>
              More Details
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
.MuiBarElement-root{
}
.logomainpage{
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: auto;
}
.logomainpage img{
  width: 100%;
  height: 100px;
  object-fit: contain;
}
.section2{
  padding: 0px 20px;
  h1{
    font-size: 2.2rem;
  }
  p{
    font-size: 14px;
    padding-bottom: 20px;
  }
}
.MuiLinearProgress-bar {
  background-color: #538B3E;
}
.flagimg{
  width: 100%;
  padding: 20px;
}
.boxsha{
  box-shadow: 0px 0px 10px #00000040;
  padding: 20px;
}
.hompagemargin{
  margin: 20px;
}
`;
export default MainPage;
