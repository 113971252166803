import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import styled from "styled-components";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Editor = ({ field, form }) => {
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    form.setFieldValue(field.name, data);
  };

  const handleEditorBlur = (event, editor) => {
    form.setFieldTouched(field.name, true);
  };

  return (
    <Wrapper>
      <div className='App'>
        <CKEditor
          editor={ClassicEditor}
          data={field.value}
          onReady={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
          onChange={handleEditorChange}
          onBlur={handleEditorBlur}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  /* Example styling */
  .App {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
  }

  /* Adjust styles as per your design */
  .ck.ck-editor__editable {
    min-height: 200px; /* Set the minimum height of the editor */
    font-size: 16px;   /* Adjust font size if needed */
  }
`;

export default Editor;
