import React, { useState } from 'react';
import styled from "styled-components";
import PageNavigation from "./pagenavigation";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleForgotPassword = async () => {
    try {
      const response = await fetch("https://api.rajhospitalitygroup.com/forgot-password", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json'
        },
        body: JSON.stringify({ email })
      });

      const result = await response.json();
      if (response.ok) {
        setMessage("Password reset email sent successfully.");
      } else {
        setMessage(result.message || "An error occurred.");
      }
    } catch (error) {
      console.error('Error occurred while requesting password reset:', error);
      setMessage("An error occurred. Please try again.");
    }
  }

  return (
    <Wrapper>
      <PageNavigation title="Forgot Password" />
      <div className='container'>
        <div className='row ForgotPasswordPage'>
          <div className='col-md-6 ForgotPasswordSection'>
            <h3 className='heading-common'>Forgot Your Password?</h3>
            <p>Enter your email address to reset your password</p>
            <label>Email</label>
            <input type='email' placeholder='email' onChange={(e) => setEmail(e.target.value)} className='form-control' />
            <br />
            <button onClick={handleForgotPassword} className='btn btn-primary'>Submit</button>
            {message && <p>{message}</p>}
          </div>
          <div className='col-md-6'>
            <img src='images/Make-Your-Own-Shirt-Printify.gif' alt='forgot password' />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  padding-top: 6rem;
  input {
    text-transform: inherit;
  }
  h3 {
    font-weight: 800;
    text-align: center;
    font-size: 3rem;
  }
  p {
    text-align: center;
  }
  span {
    color: #538B3E;
  }
  input {
    max-width: 100%;
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    &:focus {
      outline: none;
      border-color: dodgerblue;
    }
  }
  label {
    display: block;
    font-weight: 600;
    font-size: 1.6rem;
    padding-bottom: 10px;
  }
  button {
    width: 100%;
    padding: 10px;
    background-color: #538B3E;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 30px;
    &:hover {
      background-color: #34A853;
    }
    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  img {
    width: 100%;
  }
  .ForgotPasswordSection {
    padding: 0px 30px;
  }
`;

export default ForgotPassword;
