import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from 'axios';

function LinearProgressWithLabel(props) {
  return (
    <div className='row'>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <div className='col-2'>
          <img className='flagimg' src='images/america.png' alt='flag' />
        </div>
        <div className='col-8'>
          <h5 className='common-heading' style={{ paddingBottom: '15px' }}>{props.category}</h5>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" value={props.value} style={{ height: '6px', borderRadius: '12px' }} />
          </Box>
        </div>
        <div className='col-2'>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
          </Box>
        </div>
      </Box>
    </div>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
};

export default function LinearWithValueLabel() {
  const [categories, setCategories] = React.useState([]);
  const [totalProducts, setTotalProducts] = React.useState(0);

  React.useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.rajhospitalitygroup.com/products');
        const products = response.data;

        // Process the data to get the categories and the number of products in each
        const categoryCounts = products.reduce((acc, product) => {
          const category = product.category;
          if (!acc[category]) {
            acc[category] = 0;
          }
          acc[category]++;
          return acc;
        }, {});

        // Convert the category counts to an array for rendering
        const categoryData = Object.keys(categoryCounts).map(category => ({
          category,
          count: categoryCounts[category],
          value: (categoryCounts[category] / products.length) * 100,
        }));

        setCategories(categoryData);
        setTotalProducts(products.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Total Products: {totalProducts}
      </Typography>
      {categories.map((categoryData, index) => (
        <LinearProgressWithLabel
          key={index}
          category={categoryData.category}
          value={categoryData.value}
        />
      ))}
    </Box>
  );
}
