import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app";
import reportWebVitals from "./reportwebvitals";
import { AppProvider } from "./components/website/context/productcontex";
import { FilterContextProvider } from "./components/website/context/filter_context";
import { CartProvider } from "./components/website/context/cartcontex";
import { GoogleOAuthProvider } from '@react-oauth/google'; // Move this import to the top

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId="869251494205-7tcqs098m52iq4u6l0pupsgkndh2367i.apps.googleusercontent.com">
    <AppProvider>
      <FilterContextProvider>
        <CartProvider>
          <App />
        </CartProvider>
      </FilterContextProvider>
    </AppProvider>
  </GoogleOAuthProvider>
);


reportWebVitals();
