import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminSliderEditor = () => {
  const [images, setImages] = useState([]);
  const [newImageFile, setNewImageFile] = useState(null);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [updateImageFile, setUpdateImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch images from the server
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://api.rajhospitalitygroup.com/sliderimage');
        const imageSrcs = response.data.map((image) => ({ id: image._id, src: `https://api.rajhospitalitygroup.com${image.src}` }));
        setImages(imageSrcs);
      } catch (error) {
        setError('Error fetching images');
        console.error('Error fetching images', error);
      }
    };
    fetchImages();
  }, []);

  const handleAddImage = async () => {
    if (newImageFile) {
      const formData = new FormData();
      formData.append('image', newImageFile);
      setIsLoading(true);
      setError('');

      try {
        const response = await axios.post('https://api.rajhospitalitygroup.com/sliderimage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        const newImage = {
          id: response.data._id,
          src: `https://api.rajhospitalitygroup.com${response.data.src}`,
        };
        setImages([...images, newImage]);
        setNewImageFile(null);
      } catch (error) {
        setError('Error uploading the image');
        console.error('Error uploading the image', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDeleteImage = async (id) => {
    if (window.confirm('Are you sure you want to delete this image?')) {
      const image = images.find((img) => img.id === id);
      if (image) {
        setIsLoading(true);
        setError('');

        try {
          await axios.delete(`https://api.rajhospitalitygroup.com/sliderimage/${id}`);
          setImages(images.filter((img) => img.id !== id));
        } catch (error) {
          setError('Error deleting the image');
          console.error('Error deleting the image', error);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const handleUpdateImage = async (id) => {
    if (window.confirm('Are you sure you want to update this image?')) {
      if (updateImageFile) {
        const formData = new FormData();
        formData.append('image', updateImageFile);
        setIsLoading(true);
        setError('');

        try {
          const response = await axios.put(`https://api.rajhospitalitygroup.com/sliderimage/${id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          setImages(images.map((img) =>
            img.id === id ? { ...img, src: `https://api.rajhospitalitygroup.com${response.data.src}` } : img
          ));
          setUpdateImageFile(null);
          setSelectedImageId(null);
        } catch (error) {
          setError('Error updating the image');
          console.error('Error updating the image', error);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const handleFileChange = (setter) => (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/') && file.size <= 5 * 1024 * 1024) { // 5MB limit
      setter(file);
    } else {
      setError('Invalid file type or size. Please select an image file up to 5MB.');
    }
  };

  return (
    <div className="admin-slider-editor">
      <h2>Slider Image Editor</h2>
      <div>
        <input
          type="file"
          onChange={handleFileChange(setNewImageFile)}
          style={{ width: '300px', marginRight: '10px' }}
        />
        <button onClick={handleAddImage} disabled={isLoading}>Add Image</button>
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div style={{ marginTop: '20px' }}>
        {images.map((image) => (
          <div key={image.id} style={{ marginBottom: '10px', position: 'relative' }}>
            <img src={image.src} alt={`Slider image ${image.id}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
            <button onClick={() => handleDeleteImage(image.id)} style={{ marginLeft: '10px' }} disabled={isLoading}>Delete</button>
            <button onClick={() => setSelectedImageId(image.id)} style={{ marginLeft: '10px' }}>Update</button>
            {selectedImageId === image.id && (
              <div style={{ marginTop: '10px' }}>
                <input
                  type="file"
                  onChange={handleFileChange(setUpdateImageFile)}
                  style={{ width: '300px', marginRight: '10px' }}
                />
                <button onClick={() => handleUpdateImage(image.id)} disabled={isLoading}>Save</button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminSliderEditor;
