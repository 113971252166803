import React, { useState } from 'react';
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import axios from 'axios';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

const AddProductForm = () => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Wrapper>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-heading'>
              <h3>Add a Product</h3>
            </div>
            <Formik
              initialValues={{
                id: '',
                name: '',
                event: '',
                price: '',
                colors: [''],
                sizes: [''],
                images: [''],
                description: '',
                category: '',
                featured: false
              }}
              validate={values => {
                const errors = {};
                // Add validation rules if needed
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                console.log(values);
                axios.post('https://api.rajhospitalitygroup.com/products', values)
                  .then(response => {
                    console.log("Server response:", response.data);
                    alert("Product added successfully!");
                    setSubmitting(false);
                  })
                  .catch(error => {
                    console.error('Error adding product:', error);
                    alert('Error adding product. Please try again.');
                    setSubmitting(false);
                  });
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <InputGroup>
                    <label htmlFor="id">ID</label>
                    <StyledField type="text" name="id" />
                  </InputGroup>
                  <InputGroup>
                    <label htmlFor="name">Name</label>
                    <StyledField type="text" name="name" />
                  </InputGroup>
                  <InputGroup>
                    <label htmlFor="event">Event</label>
                    <StyledField type="text" name="event" />
                  </InputGroup>
                  <InputGroup>
                    <label htmlFor="price">Price</label>
                    <StyledField type="number" name="price" />
                  </InputGroup>
                  <FieldArray name="colors">
                    {({ push, remove, form }) => (
                      <div>
                        <label>Colors</label>
                        {form.values.colors.map((color, index) => (
                          <div key={index}>
                            <StyledField type="text" name={`colors.${index}`} />
                            {index > 0 && (
                              <button type="button" onClick={() => remove(index)}>
                                Remove
                              </button>
                            )}
                          </div>
                        ))}
                        <button type="button" onClick={() => push('')}>
                          Add Color
                        </button>
                      </div>
                    )}
                  </FieldArray>
                  <FieldArray name="images">
                    {({ push, remove, form }) => (
                      <div>
                        <label>Images</label>
                        {form.values.images.map((image, index) => (
                          <div key={index}>
                            <StyledField type="text" name={`images.${index}`} />
                            {index > 0 && (
                              <button type="button" onClick={() => remove(index)}>
                                Remove
                              </button>
                            )}
                          </div>
                        ))}
                        <button type="button" onClick={() => push('')}>
                          Add Image
                        </button>
                      </div>
                    )}
                  </FieldArray>
                  <InputGroup>
                    <label htmlFor="description">Description</label>
                    <StyledField as="textarea" name="description" />
                  </InputGroup>
                  <InputGroup>
                    <label htmlFor="category">Category</label>
                    <StyledField type="text" name="category" />
                  </InputGroup>
                  <InputGroup>
                    <label htmlFor="featured">Featured</label>
                    <StyledField type="checkbox" name="featured" />
                  </InputGroup>
                  <StyledButton type="submit" disabled={isSubmitting}>
                    Add Product
                  </StyledButton>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: 200px;

  .form-heading {
    text-align: center;
    margin-bottom: 20px;
  }

  label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }

  input[type="text"],
  input[type="number"],
  textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }

  textarea {
    resize: vertical;
  }

  button {
    margin-top: 5px;
    background-color: #538B3E;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px 15px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  button:hover {
    background-color: #34A853;
  }
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const StyledField = styled(Field)`
  /* Your additional styling if any */
`;

const StyledErrorMessage = styled(ErrorMessage)`
  color: red;
  margin-bottom: 10px;
`;

const StyledButton = styled.button`
  /* Your additional styling if any */
`;

export default AddProductForm;
