import React from 'react';
import './styles/contentpages.css';


const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h1 className='marg-top'>Privacy Policy</h1>
          <p>
            At Rabbit and Turtle, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our online platform. By accessing or using our platform, you agree to the terms outlined in this Privacy Policy.
          </p>
          <h2>Information We Collect</h2>
          <ul>
            <li>Personal Information: When you create an account or place an order on our platform, we may collect personal information such as your name, email address, shipping address, billing address, phone number, and payment information.</li>
            <li>Order Information: We may collect information related to your orders, including the products you purchase, transaction details, and order history.</li>
            <li>User Content: If you choose to create custom designs or upload images to personalize your garment, we may collect and store this content to fulfill your orders.</li>
            <li>Communication: We may collect information from your communications with us, including emails, chat messages, and feedback you provide.</li>
            <li>Automatically Collected Information: We may automatically collect certain information about your device and usage of our platform, including your IP address, browser type, operating system, and browsing behavior.</li>
          </ul>
          <h2>How We Use Your Information</h2>
          <ul>
            <li>Order Fulfillment: We use your personal information to process and fulfill your orders, communicate with you regarding your purchases, and provide customer support.</li>
            <li>Personalization: We use your user content to create and customize the products you order according to your preferences.</li>
            <li>Communication: We may use your contact information to send you updates about your orders, promotional offers, newsletters, and other marketing communications unless you opt out.</li>
            <li>Improvement of Services: We analyze the data we collect to improve the functionality, usability, and performance of our platform and to develop new features and services.</li>
            <li>Legal Compliance: We may use your information to comply with applicable laws, regulations, legal processes, or governmental requests.</li>
          </ul>
          <h2>How We Protect Your Information</h2>
          <ul>
            <li>Data Security: We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</li>
            <li>Payment Security: We use secure payment processing systems to handle your payment information securely.</li>
            <li>Data Retention: We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</li>
          </ul>
          <h2>Disclosure of Information</h2>
          <ul>
            <li>Third-Party Service Providers: We may share your information with third-party service providers who assist us in operating our platform, processing payments, fulfilling orders, or providing other services.</li>
            <li>Legal Compliance: We may disclose your information if required to do so by law or in response to valid legal requests, such as subpoenas or court orders.</li>
          </ul>
          <h2>Security Measures</h2>
          <ul>
            <li>Data Encryption: We use encryption techniques such as Secure Socket Layer (SSL) technology to encrypt data transmitted between your browser and our servers. This helps ensure that your personal and payment information remains secure during transmission.</li>
            <li>Firewalls and Intrusion Detection Systems: We employ firewalls and intrusion detection systems to prevent unauthorized access to our systems and protect against malicious activities.</li>
            <li>Access Controls: Access to your personal information is restricted to authorized personnel who require access to fulfill their duties. We implement strict access controls and authentication mechanisms to safeguard your data.</li>
            <li>Regular Security Audits: We conduct regular security audits and assessments to identify and address any vulnerabilities in our systems and infrastructure.</li>
            <li>Employee Training: Our employees undergo regular training on security best practices and data handling procedures to ensure compliance with our security policies.</li>
          </ul>
          <h2>Cookies</h2>
          <p>
            Cookies are small text files that are stored on your device when you visit a website. They help us recognize your browser and remember certain information about your preferences and activities on our platform.
          </p>
          <h3>Types of Cookies</h3>
          <ul>
            <li>Essential Cookies: These cookies are necessary for the operation of our platform and enable core functionalities such as account authentication and order processing.</li>
            <li>Analytical Cookies: We use analytical cookies to gather information about how users interact with our platform, such as which pages are visited most frequently and if users encounter any errors. This helps us analyze and improve the performance and usability of our platform.</li>
            <li>Marketing Cookies: We may use marketing cookies to track your browsing behavior and display personalized advertisements or promotional offers based on your interests.</li>
          </ul>
          <h3>Your Cookie Choices</h3>
          <ul>
            <li>Consent: By continuing to use our platform, you consent to the use of cookies as described in this Privacy Policy. You can withdraw your consent at any time by adjusting your browser settings to refuse cookies or by opting out of specific cookie categories.</li>
            <li>Opt-Out: You can opt out of certain cookies by adjusting your browser settings or by using the opt-out mechanisms provided by third-party cookie providers.</li>
            <li>Third-Party Cookies: We may allow third-party service providers, such as advertising networks and analytics providers, to use cookies on our platform. These third parties may collect information about your online activities over time and across different websites for purposes such as targeted advertising.</li>
            <li>Cookie Management: You can manage your cookie preferences and settings through your browser settings. However, please note that disabling certain cookies may affect the functionality of our platform and your user experience.</li>
          </ul>
          <h2>Data Security and Cookie Policy Updates</h2>
          <p>
            We may update our security measures and cookie practices from time to time to reflect changes in technology, industry standards, or legal requirements. We will notify you of any material changes to our security or cookie practices by posting the updated policy on our platform or by other means of communication.
          </p>
          <h2>Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access the personal information we hold about you</li>
            <li>Request correction of any incorrect or incomplete information</li>
            <li>Request deletion of your personal information</li>
            <li>Object to the processing of your personal information</li>
            <li>Withdraw your consent at any time</li>
          </ul>
          <p>To exercise these rights, please contact us.</p>
          <h2>Changes to This Privacy Policy</h2>
          <p>
            We reserve the right to update or change our Privacy Policy at any time, and any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically for any updates or changes.
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns about our Privacy Policy or our handling of your information, please contact us at rabbitandturtle22@gmail.com.
          </p>
          <p>By using our website or services, you agree to the terms of this Privacy Policy.</p>
          <p>Last updated: [Insert Date]</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
