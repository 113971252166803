import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import Nav from "./nav";

const theme = {
  colors: {
    bg: '#EEFFE8',
    // Add more colors here as needed
  },
};

const MainHeader = styled.header`
  padding: 0 4.8rem;
  height: 8rem;
  background-color: ${({ theme }) => theme.colors.bg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .logo {
    height: 5rem;
  }
  .logoimg{
    background: url('rabbit_and_turtle/images/Rectangle9942.png')no-repeat center center ;
    max-width: 150px;
    width: 100%;
    background-size: cover;
    position: absolute;
    top: 0;
  }
  .paddingtopall{
    margin-top: 70px;
  }

padding: 0;
 height: 8rem;


  @media (max-width: ${({ theme }) => theme.media.mobile}) {
 .logoimg{
 position: absolute;
    top: 0;
     right: 10%;
     width: 80px;
}
     padding: 0;
 height: 4rem;
}

    }

`;


const Header = () => {
  return (
    <ThemeProvider theme={theme}>
      <section className='themebg'>
      <div className='container'>
        <MainHeader className='row'>
          <div className='col-md-2'>
          <NavLink to="/">
          <img className='logoimg navbar-link'
            src= "../images/logo.png"

            alt=""
          />
        </NavLink>
          </div>
          <div className='col-10'>
            <Nav/>
          </div>

        </MainHeader>

      </div>
      </section>
    </ThemeProvider>
  );
};

export default Header;
