import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import PageNavigation from "./components/pagenavigation";
import { HiArrowRight } from "react-icons/hi";
import { NavLink, useNavigate } from 'react-router-dom';

const Checkout = () => {
  const [cartDetails, setCartDetails] = useState([]);
  const [orderTotal, setOrderTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  useEffect(() => {
    const storedCartDetails = localStorage.getItem('cart-details');
    if (storedCartDetails) {
      const parsedCartDetails = JSON.parse(storedCartDetails);
      const carts = parsedCartDetails.carts || [];
      setCartDetails(carts);
      const calculatedTotal = calculateOrderTotal(carts);
      setOrderTotal(calculatedTotal);
      calculateGrandTotal(calculatedTotal);
    } else {
      console.error('No cart details found in local storage');
    }
  }, []);

  const calculateOrderTotal = (carts) => {
    if (Array.isArray(carts)) {
      const total = carts.reduce((acc, cart) => {
        const { S = 0, M = 0, L = 0, XL = 0, XXL = 0 } = cart.size;
        return acc + (cart.price * (S + M + L + XL + XXL));
      }, 0);
      return total;
    } else {
      console.error('Expected carts to be an array, but got:', carts);
      return 0;
    }
  };

  const calculateGrandTotal = (subtotal) => {
    const discountPercentage = 20; // 20% discount
    const discount = (subtotal * discountPercentage) / 100;
    const deliveryFee = 130.00; // Static delivery fee
    const tax = 27.00; // Static tax
    const total = subtotal - discount + deliveryFee + tax;
    setGrandTotal(total);
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    postalCode: '',
    city: '',
    country: '',
    paymentMethod: '',
    shipping: '',
    product_details: [],
    Orders_Status: 'Orders Confirmed'
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleCheckout = (e) => {

    const userInfo = JSON.parse(localStorage.getItem('user-info'));
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      product_details: cartDetails,
      subtotal: orderTotal,
      Discount: (orderTotal * 20) / 100,  // Change 'discount' to 'Discount'
      Delivery_fee: 130.00,
      Tax: 27.00,
      Grand_Total: grandTotal,
      user_id: userInfo ? userInfo._id : null
    };

    console.log('Form Data:', updatedFormData);

    fetch('https://api.rajhospitalitygroup.com/checkouts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedFormData),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => {
            console.error('Server error:', err);
            throw new Error('Network response was not ok');
          });
        }
        return response.json();
      })
      .then(data => {
        console.log('Data sent successfully:', data);
        navigate("/Thankyou");
      })
      .catch(error => {
        console.error('Error while sending data:', error);
      });



    // Reset form fields if needed
    setFormData({
      name: '',
      email: '',
      phone: '',
      address: '',
      postalCode: '',
      city: '',
      country: '',
      paymentMethod: '',
      shipping: '',
      product_details: [],
      Orders_Status: 'Orders Confirmed',
    });
  };

  return (
    <Wrapper>
      <PageNavigation title="Checkout" />
      <form onSubmit={handleCheckout}>
        <div className='container'>
          <div className='row' style={{ justifyContent: 'space-between', }}>
            <div className='col-lg-7 col-md-12 col-sm-12'>

              <div className='row Personal'>
                <h3 className='common-heading'>Personal Information</h3>
                <div className='col-12'>
                  <input type='text' name='name' value={formData.name} onChange={handleChange} placeholder='Your Name' />
                </div>
                <div className='col-6'>
                  <input type='email' name='email' value={formData.email} onChange={handleChange} placeholder='Email' />
                </div>
                <div className='col-6'>
                  <input type='tel' name='phone' value={formData.phone} onChange={handleChange} placeholder='Phone Number' />
                </div>
              </div>

              <div className='row Personal'>
                <h3 className='common-heading'>Shipping Address</h3>
                <div className='col-8'>
                  <input type='text' name='address' value={formData.address} onChange={handleChange} placeholder='Address' />
                </div>
                <div className='col-4'>
                  <input type='text' name='postalCode' value={formData.postalCode} onChange={handleChange} placeholder='Postal Code' />
                </div>
                <div className='col-6'>
                  <input type='text' name='city' value={formData.city} onChange={handleChange} placeholder='City' />
                </div>
                <div className='col-6'>
                  <input type='text' name='country' value={formData.country} onChange={handleChange} placeholder='Country' />
                </div>
              </div>

              <div className='row Payment Personal'>
                <h3 className='common-heading' style={{ marginBottom: "0", }}>Shipping Methods</h3>
                <div className='col-12'>
                  <table>
                    <tr>
                      <td className='new_td'>
                        <input type='radio' name='shipping' value='standard' checked={formData.shipping === 'standard'} onChange={handleChange} />
                        <h4 className='borderheading'>₹&nbsp;0.00</h4>
                      </td>

                      <td><h4 className='borderheading'>Standard</h4></td>
                      <td><h4>5-10 days after placing order.</h4></td>
                      {/* Timeframe may vary depending on order items. */}
                    </tr>
                    <tr style={{ borderTop: "2px", borderStyle: "solid", borderColor: "#65A04F", }}>
                      <td className='new_td'> <input type='radio' name='shipping' value='express_shipping' checked={formData.shipping === 'express_shipping'} onChange={handleChange} />
                        <h4 className='borderheading'>₹&nbsp;0.00</h4></td>

                      <td><h4 className='borderheading'>Express Shipping</h4></td>
                      <td><h4>2-5 days depending on order items.</h4></td>
                    </tr>
                  </table>


                </div>
              </div>

              <div className='row Payment Personal'>
                <h3 className='common-heading' style={{ marginBottom: "0", }}>Payment Method</h3>
                <div className='col-12'>
                  <table>
                    <tr className='new_td1'>
                      <td className='new_td'>
                        <input type='radio' name='paymentMethod' value='cash_on_delivery' checked={formData.paymentMethod === 'cash_on_delivery'} onChange={handleChange} />
                        <h4>Cash On Delivery</h4>
                      </td>


                      <td className='new_td'>
                        <input type='radio' name='paymentMethod' value='pay_now' checked={formData.paymentMethod === 'pay_now'} onChange={handleChange} />
                        <h4>Pay Now</h4>
                      </td>

                    </tr>
                  </table>


                </div>
              </div>
              <button type="submit" onClick={handleCheckout} hidden>Submit</button>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12  column2box">
              <h4 className="common-heading" style={{ marginBottom: "20px", }}>
                Order Summary
              </h4>
              <div className="row column2">

                <div className="col-12">
                  <h4 className='common-heading' style={{ fontSize: "16px", }}>Product Details</h4>
                </div>
                {cartDetails.length > 0 ? (
                  cartDetails.map((cart, index) => (
                    <div className='row margin_zero cart-item' key={cart._id} >
                      <div className='col-1'>

                        <ul><li>{index+1}</li></ul>
                      </div>
                      <div className="col-8">

                        <ul>
                          <li>
                            {cart.productname}
                          </li>
                          <li className='colorouter'>
                            Color:<span style={{ background: "#000000" }}></span>
                          </li>
                          <li style={{ display: 'flex', width: '200px', justifyContent: 'start', alignItems: 'center', }}>
                            Size: {Object.keys(cart.size).map(size => (
                              <li key={size} style={{ fontSize: '12px', }}>{size}: {cart.size[size]}</li>
                            ))};
                          </li>
                          <li style={{display: 'flex',}}>Printing&nbsp;Side:&nbsp;&nbsp;{cart.images.map((image, i) => (
                                                    <div className='productimage_inner' key={i} >

                                                        {[' Front', 'Back', 'Left', 'Right'][i]}
                                                    </div>
                                                ))}</li>
                          <li> Total Price: ₹{cart.price * (cart.size.S + cart.size.M + cart.size.L + cart.size.XL + cart.size.XXL)}</li>
                        </ul>

                      </div>
                      <div className="col-3">
                        <img src={cart.images.length > 0 ? `http://localhost/PHOTO_EDITING_APPLICATION_IN_PHP_WITH_SOURCE_CODE/${cart.images[0]}` : "path/to/placeholder.png"} alt="product" />
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No items in cart.</p>
                )}


                <div className="col-6">
                  <h5>Subtotal</h5>
                </div>
                <div className="col-6">
                  <h5 className="common-heading righttext">₹ {orderTotal.toFixed(2)}</h5>
                </div>
                <div className="col-6">
                  <h5>Discount(-20%)</h5>
                </div>
                <div className="col-6">
                  <h5 className="common-heading righttext">₹ {((orderTotal * 20) / 100).toFixed(2)}</h5>
                </div>
                <div className="col-6">
                  <h5>Delivery Fee</h5>
                </div>
                <div className="col-6">
                  <h5 className="common-heading righttext">₹ 130.00</h5>
                </div>
                <div className="col-6">
                  <h5>Tax</h5>
                </div>
                <div className="col-6">
                  <h5 className="common-heading righttext">₹ 27.00</h5>
                </div>
                <div className="col-6">
                  <h5 className="common-heading">Grand Total</h5>
                </div>
                <div className="col-6">
                  <h5 className="common-heading righttext">₹{grandTotal.toFixed(2)}</h5>
                </div>
                <div className='row textouter margin_zero'>
                  <div className="col-6">
                    <input type="text" placeholder="Add promo code"></input>
                  </div>
                  <div className="col-6 righttext">
                    <button className="applybtn ">Apply</button>
                  </div>
                </div>
                <div className="col-12">
                  <NavLink to="/Checkout" className="navbar-link checkoutbtn" onClick={handleCheckout}>
                    Go to Checkout<HiArrowRight />
                  </NavLink>

                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.section`

padding: 9rem 0;
.Personal{
    box-shadow: 0px 0px 3px #00000050 inset;
    padding: 20px 20px 0px 20px;
    margin-bottom: 50px;

}
img{
    width: 100%;
    padding: 5%;
    height: auto;
}
h3{
    border-bottom: 2px  solid #65A04F;
    padding: 0;
    padding-bottom: 10px;
    margin-bottom: 30px;
    font-size: 2.6rem;
}
input{
    background: #F0F0F0;
    border: none;
    max-width: 100%;
    font-size: 14px;
    width: 100%;
    box-shadow: none;
    margin-bottom: 30px;
    ::placeholder {

        font-size: 14px;
        font-weight: 600;
        opacity: 1; /* Firefox */
            text-transform: capitalize;
      }

      ::-ms-input-placeholder { /* Edge 12-18 */

        font-size: 16px;
        font-weight: 600;
            text-transform: capitalize;
      }
      :focus-visible {
        outline: #65A04F auto 1px;
      }

}
.Payment{
    input{
        width: max-content;
        margin-bottom: 0px;
        margin-right: 20px;
    }
    table{
        margin-bottom: 30px;
        display:block;
        padding-bottom: 20px;


    }
    .new_td1{
        display: flex;
        justify-content: start;

    }
    .new_td{

        display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    h4{
    padding-right: 16px;
    margin-bottom: 0px;
    }


    }
    td{
        padding:  0 20px;

        height: 80px;

        h4{

            height: 60px;
            display: flex;
            justify-content: start;
            align-items: center;
            font-weight: 600;
        }
        .borderheading{
            border-right: 2px solid #65A04F;
        }
        input{
            width: 20px;
            height: 20px;
            margin-right: 0px;
        }
    }
}

label{
    display:flex;
    justify-content: center;
    align-items:center;
}
.applybtn{
    width: 100px;
    border-radius: 5px;
    background: #538B3E;
    padding: 12px 15px;
    color: #ffffff;
    border: none;
    font-size: 12px;
  }
  .checkoutbtn{
    display: block;
    text-align: center;
    width: 100%;
    border-radius: 8px;
    background: #538B3E;
    padding: 12px 15px;
    color: #ffffff;
    border: none;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        margin-left: 5px;
    }
  }
.column2box{
    box-shadow: 0px 0px 5px #00000040;
    border-radius: 6px;
    padding: 30px;
    h5{
        padding-top: 16px;
    }
    input{
        margin: 0;
    }
    .textouter{
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;
    }
}
.column2 ul{
    padding: 0px;
    font-weight: 600;
    line-height: 1.6;
    font-size: 14px;
    color: #000000;
}
.righttext{
    text-align: right;

    padding: 0;
}
.margin_zero{margin:0;

span{
    display: block;
    width: 10px;
    height: 10px;
    margin: 0px;
    border-radius: 100%;
    margin-left: 10px;
    margin-top: 4px;
}
.colorouter{
    display: flex;
    justify-content:start;
    align-items: center;
}
}
`;

export default Checkout;