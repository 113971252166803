import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <style> {`
        .newUSP{
            padding: 30px 0px;
        }
        .social_icon svg{
            background: #ffffff;
            color: #82868C;
            border-radius : 100%;
            width: 30px;
            height: 30px;
            padding: 8px;
            margin-left: 16px;
        }
        .blacksection{
            color: #ffffff;
            margin-top: 70px;
            background-image: url('../images/backgroundimage.png');
        }
        .outerusp{
            background: #EEFFE8;
            padding: 20px;
        }
        .flexclass {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .border_R{
            border-right: 1px solid #ffffff60;
        }
        .footer_section_1 .flexclass img{
            max-width: 50px;
            width: 100%;
            height: auto;
            padding: 5px;
        }
        .footer_section_inner{
            display: flex;
            flex-direction: column;
        }
        .footer_section_inner p{
            margin-bottom: 0;
        }
        .footer_section_22 h5{
            font-size: 20px;
        }
        .footer_section_1 h5{
            font-size: 18px;
            font-weight: 500;
            color: #000000;
            letter-spacing: 0.04em;
        }
        .footer_section_1 p{
            font-size: 12px;
        }
        .footer_section_2{
            border: 2px solid #ffffff60;
            border-width: 2px 0px 2px 0px;
            padding: 60px 100px 50px 100px;
        }
        .footer_section_21 p{
            padding-top: 20px;
            font-size: 12px;
            line-height: 20px;
            color: #ffffff;
        }
        .footer_section_22 a:hover{
            color: #649E4E;
        }
        .footer_section_22 a{
            display: block;
            color: #ffffff;
            text-decoration: none;
            padding-right: 30px;
            line-height: 3rem;
            font-size: 14px;
            width: 100%;
        }
        .footer_section_3 {
            padding: 0px 30px;
        }
        .footer_section_3 img{
            max-width: 400px;
            width: 100%;
        }
        .footer_section_3 form input{
            font-size: 16px;
            padding: 5px;
            border-radius: 20px;
        }
        .footer_section_3 form #email{
            width: 100%;
            padding: 10px 10px 10px 40px;
            font-size: 14px;
        }
        .footer_section_3 form .email_outer{
            position: relative;
        }
        .email_outer .fa-envelope{
            position: absolute;
            top: 15px;
            left: 20px;
            color: #00000080;
        }
        .email_outer .fa-chevron-right{
            position: absolute;
            top: 4px;
            right: 4px;
            color: #ffffff;
            border-radius: 50px;
            background: #649E4E;
            width: 36px;
            height: 36px;
        }
        .footer_section_31{
            font-size: 12px;
            display:flex;
            padding: 10px 0;
        }
        .footer_section_31 .fa-check{
            background: #649E4E;
            width: 16px;
            height: 16px;
            font-size: 12px;
            border-radius: 30px;
            color: #000000;
            font-weight: 900;
            margin-right: 6px;
        }
        .footer_section_4 {
            font-size: 12px;
            padding: 10px 0px 20px 0px;
            padding: 20px 100px;
        }
        .footer_section_4 p{
            margin-bottom: 0px;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
        }
        .footer_section_43 a{
            color: #ffffff;
            padding: 0px 10px;
        }
        @media screen and (max-width: 720px){
            .flexclass{
                flex-direction: column;
                height: auto;
            }
            .footer_section_1 .flexclass img{
                max-width: 100px;
            }
            .footer_section_1 {
                text-align: center;
                h5{
                    font-size: 18px;
                }
                p{
                    font-size: 16px;
                }
            }
            .bannerc{
                text-align: center;
                padding: 30px 0px;
                h3{
                    text-shadow: 0px 0px 2px #ffffff;
                }
                p{
                    color: #000000;
                    font-weight: 600;
                    text-shadow: 0px 0px 5px #ffffff;
                }
            }
            .footer_section_2{
                padding: 50px 0px;
                text-align: center;
            }
                .footer_section_22 a{
                padding-right: 0;}


        }
      `}</style>
      <section className="blacksection" >
        <div className='outerusp'>
          <div className='container'>
            <div className="row footer_section_1">
              <div className="col-lg-3 col-mb-6 col-sm-12 col-6 flexclass border_R">
                <img src="../images/fast_1.png" alt=""/>
                <div className="footer_section_inner">
                  <h5>Free Shipping</h5>
                  <p>Free shipping over ₹100</p>
                </div>
              </div>
              <div className="col-lg-3 col-mb-6 col-sm-12 col-6 flexclass border_R">
                <img src="../images/Group.png" alt=""/>
                <div className="footer_section_inner">
                  <h5>Support 24/7</h5>
                  <p>Contact us 24 hours a day</p>
                </div>
              </div>
              <div className="col-lg-3 col-mb-6 col-sm-12 col-6 flexclass border_R">
                <img src="../images/wallet_1.png" alt=""/>
                <div className="footer_section_inner">
                  <h5>100% Money Back</h5>
                  <p>You have 30 days to Return</p>
                </div>
              </div>
              <div className="col-lg-3 col-mb-6 col-sm-12 col-6 flexclass">
                <img src="../images/costumer-service_1.png" alt=""/>
                <div className="footer_section_inner">
                  <h5>Payment Secure</h5>
                  <p>We ensure secure payment</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container footer_section">
          <div className="row footer_section_2 footer_section_21">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <img src="../images/logo2.png" alt=""/>
              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum quas, saepe ratione impedit, illo dolorum minima repellendus neque excepturi debitis provident non ea earum alias molestiae. Deserunt possimus culpa enim.</p>
            </div>
            <div className="col-lg col-md-4 col-sm-12 footer_section_22">
              <h5>Our Office</h5>
              <NavLink to="/newsletter">Newsletter & Blog</NavLink>
              <NavLink to="/career">Career & Contact</NavLink>
              <NavLink to="/monthly-offer">Monthly Offer</NavLink>
              <NavLink to="/PrivacyPolicy">Privacy Policy</NavLink>
              <NavLink to="/TermsOfService"> Terms Of Service</NavLink>
              <NavLink to="/TermsAndConditions">Terms And Conditions</NavLink>
            </div>
            <div className="col-lg col-md-4 col-sm-12 footer_section_22">
              <h5>Quick Link</h5>
              <NavLink to="/">Home</NavLink>
              <NavLink to="/about">About Us</NavLink>
              <NavLink to="/shop">Shop Now</NavLink>
              <NavLink to="/create">Create</NavLink>
              <NavLink to="/blog">Blog</NavLink>
              <NavLink to="/contact">Contact Us</NavLink>
            </div>
            <div className="col-md-4 footer_section_3">
              <form action="#">
                <div className="email_outer">
                  <i className="fa-light fa-envelope"></i>
                  <input id="email" type="email" name="email" placeholder="Enter you Email"/>
                  <i className="fa-light fa-chevron-right flexclassName"></i>
                </div>
              </form>
              <div className="footer_section_31">
                <i className="fa-thin fa-check"></i>
                <p>I agree to <a href="#">terms & condition</a> and <a href="#">Privacy Policy</a></p>
              </div>
              <div className="footer_section_32 flexclass">
                <h5>Follow us on</h5>
                <div className="social_icon">
                  <NavLink to="#"><FaFacebookF /></NavLink>
                  <NavLink to="#"><FaLinkedinIn /></NavLink>
                  <NavLink to="#"><FaWhatsapp /></NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="row footer_section_2 footer_section_4">
            <p>&copy; 2021 All Rights Reserved</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
