import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import axios from "axios";
import PageNavigation from "./components/pagenavigation";
import { Button } from "./styles/button";
import MyImage from "./components/myimage";
import { FaFacebookF, FaWhatsapp, FaInstagram, FaStar } from "react-icons/fa";
import PopUpLogin from "./popuplogin";

const SingleProduct = () => {
  const [seen, setSeen] = useState(false);
  const [product, setProduct] = useState(null);
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("description");
  const userInfo = JSON.parse(localStorage.getItem('user-info'));
  const userId = userInfo ? userInfo.id : null;

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://api.rajhospitalitygroup.com/products/${id}`);
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProduct();
  }, [id]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const togglePop = () => {
    setSeen(!seen);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "faq":
        return (
          <div className="accordionouter">
            <div className="accordion" id="accordionExample">
              {product.faqs.map((faq, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className="accordion-button accordionmy collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse${index}`}
                    >
                      {faq.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body panel">
                      {faq.answer}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      default:
        return <p className="description">{product.description}</p>;
    }
  };

  if (!product) {
    return <div className="page_loading">Loading.....</div>;
  }

  return (
    <Wrapper>
      <div className="container">
        <PageNavigation title={product.name} />
        <div className="row">
          <div className="product_images col-md-6">
            <MyImage images={product.images} />
          </div>
          <div className="product-data col-md-6">
            <h2 className="common-heading">{product.name}</h2>
            <p className="description">{product.description}</p>
            <div className="row">
              <div className="col-6">
                <div className="product-data-info">
                  <p>SKU : <span>{product.id}</span></p>
                  <p>Category : <span>{product.category}</span></p>

                  <p>Stock : <span>In Stock</span></p>
                  <p>Material : <span>Cotton fabric</span></p>
                  <br />
                  <p>Share : <span className="social_icon"><FaFacebookF /><FaWhatsapp /><FaInstagram /></span></p>
                  <p>Delivery : <span>1 to 3 BUSINESS DAYS</span></p>
                </div>
              </div>
            </div>

            <div className="row Log_in_button_outer">
              <Button
                as="a"
                href='#'
                className="Customize Log_in_button" onClick={togglePop}
              >
                Customize Now
              </Button>
              {seen ? <PopUpLogin toggle={togglePop} /> : null}

              {userInfo ?
                <Button
                  as="a"
                  href={`/editor/index.php?id=${product.id}&id_=${userInfo._id}&name=${product.name}&price=${product.price}&imagename=front`}
                  className="Customize Log_in_button1"
                >
                  Customize Now
                </Button>
                : null
              }
            </div>
          </div>
        </div>
        <div className="row">
          <div className="tab-menu">
            <button
              className={activeTab === "description" ? "active" : ""}
              onClick={() => handleTabChange("description")}
            >
              Description
            </button>
            <button
              className={activeTab === "faq" ? "active" : ""}
              onClick={() => handleTabChange("faq")}
            >
              FAQ
            </button>
          </div>
          <div className="tab-content">{renderTabContent()}</div>
        </div>
        <div className="row">
          <div className="col-6">
            <h3>Customers Reviews</h3>
            <h1 className="common-heading" style={{ fontWeight: '600' }}>4.7</h1>
            <div className="star-rating">
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <FaStar
                    key={index}
                    className="star"
                    color={index <= product.rating ? "#ffc107" : "#e4e5e9"}
                  />
                );
              })}
            </div>
          </div>
          <div className="col-6">
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding-top: 9rem;

  .product_images {
    display: flex;
    align-items: center;
  }
  .social_icon {
    svg {
      margin-left: 10px;
    }
  }
  .product-data {
    padding: 30px;
    .description {
      padding: 20px 0px 50px;
    }
    .product-data-warranty {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ccc;
      margin-bottom: 1rem;
      .product-warranty-data {
        text-align: center;
        .warranty-icon {
          background-color: rgba(220, 220, 220, 0.5);
          border-radius: 50%;
          width: 4rem;
          height: 4rem;
          padding: 0.6rem;
        }
        p {
          font-size: 1.4rem;
          padding-top: 0.4rem;
        }
      }
    }
    .product-data-price {
      font-weight: bold;
    }
    .product-data-real-price {
      color: ${({ theme }) => theme.colors.btn};
    }
    .product-data-info {
      display: flex;
      flex-direction: column;
      font-size: 1.8rem;
      p {
        font-weight: 600;
        color: #000000;
      }
      span {
        font-weight: normal;
        color: #00000080;
      }
    }
    hr {
      max-width: 100%;
      width: 90%;
      /* height: 0.2rem; */
      border: 0.1rem solid #000;
      color: red;
    }
  }
  .product-images {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page_loading {
    font-size: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Log_in_button_outer {
    position: relative;
  }
  .Log_in_button1 {
    position: absolute;
    top: 0;
    left: 0;
  }
  .Customize {
    display: block;
    background: #65a04f;
    color: #ffffff;
    padding: 20px 10px;
    width: 100%;
    border-radius: 10px;
    font-weight: bold;
    margin-top: 50px;
    text-transform: capitalize;
    font-size: 2rem;
    text-align: center;
  }
  .tab-content {
    padding: 30px;
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    padding: 0 2.4rem;
  }
  .tab-menu {
    margin-top: border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      margin: 0px 20px;
      padding: 10px 00px;
      border: none;
      background: #00000000;
    }
    .active {
      border-bottom: 2px solid #000000;
    }
  }
  .star-rating {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    .star {
      font-size: 2rem;
      color: #d3d3d3;
      cursor: pointer;
      &:hover {
        color: #ffc107;
      }
    }
  }`
;


export default SingleProduct;
