import React from 'react';
import styled from 'styled-components';
import { Button } from './styles/button';
import { NavLink } from 'react-router-dom';
export const Error = () => {
  return (
   <Wrapper>
    <div className='container '>
      <div>
        <h2>404</h2>
        <h3>UH OH! You're lost.</h3>
        <p>this page doesn't  Exist</p>
        <NavLink to="/">
        <Button>GO BACK TO HOME PAGE</Button>
        </NavLink>
      </div>
    </div>
   </Wrapper>
  )
};
const Wrapper = styled.section`

.container{
  padding: 9rem 0;
  text-align: center;
  color: #000000;
}
h2{
  font-size: 10rem;
}
h3{
  font-size: 3.6rem;
}
p{
  margin: 2rem 0;
}
`;
export default Error ;
