import React, { useState } from 'react';
import styled from 'styled-components';
import { Card, Button, TextField } from '@mui/material';

const MyInfo = ({ user }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [contactDetails, setContactDetails] = useState({
        userName: user.user_name || '',
        email: user.email || '',
        phoneNumber: user.phoneNumber || '',
        password: '********',
    });
    const [addresses, setAddresses] = useState(user.addresses || []);
    const [loading, setLoading] = useState(false);

    const handleEditToggle = () => setIsEditing(!isEditing);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContactDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
    };

    const handleAddressChange = (index, e) => {
        const { name, value } = e.target;
        setAddresses((prevAddresses) =>
            prevAddresses.map((address, i) => i === index ? { ...address, [name]: value } : address)
        );
    };

    const handleAddAddress = () => {
        setAddresses((prevAddresses) => [
            ...prevAddresses,
            { street: '', city: '', postalCode: '', country: '' }
        ]);
    };

    const handleRemoveAddress = (index) => {
        setAddresses((prevAddresses) => prevAddresses.filter((_, i) => i !== index));
    };

    const handleUpdateContact = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://api.rajhospitalitygroup.com/users/${user._id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(contactDetails),
            });
            if (response.ok) {
                alert('Contact details updated successfully');
            } else {
                alert('Error updating contact details');
            }
        } catch (error) {
            console.error('Error updating contact details:', error);
            alert('Error updating contact details');
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateAddresses = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://api.rajhospitalitygroup.com/users/${user._id}/addresses`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ addresses }),
            });
            if (response.ok) {
                alert('Addresses updated successfully');
            } else {
                alert('Error updating addresses');
            }
        } catch (error) {
            console.error('Error updating addresses:', error);
            alert('Error updating addresses');
        } finally {
            setLoading(false);
        }
    };

    return (
        <StyledCard>
            <Section>
                <h2 className='common-heading'>Contact Details</h2>
                <div className='row'>
                    <div className='col-md-6'>
                        <TextField
                            label="Your Name"
                            name="userName"
                            value={contactDetails.userName}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            disabled={!isEditing || loading}
                        />
                    </div>
                    <div className='col-md-6'>
                        <TextField
                            label="Email Address"
                            name="email"
                            type="email"
                            value={contactDetails.email}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            disabled={!isEditing || loading}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <TextField
                            label="Phone Number"
                            name="phoneNumber"
                            value={contactDetails.phoneNumber}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            disabled={!isEditing || loading}
                        />
                    </div>
                    <div className='col-md-6'>
                        <TextField
                            label="Password"
                            name="password"
                            type="password"
                            value={contactDetails.password}
                            fullWidth
                            margin="normal"
                            disabled
                        />
                    </div>
                </div>
                {isEditing ? (
                    <Button variant="contained" color="success" onClick={handleUpdateContact} disabled={loading}>
                        {loading ? 'Saving...' : 'Save Changes'}
                    </Button>
                ) : (
                    <Button variant="contained" color="success" onClick={handleEditToggle}>
                        Change
                    </Button>
                )}
            </Section>
            <Section>
                <h2 className='common-heading'>Addresses</h2>
                {addresses.map((address, index) => (
                    <AddressSection key={index} className='row'>
                        <div className='col-md-6'>
                            <TextField
                                label="Street Address"
                                name="street"
                                value={address.street || ''}
                                onChange={(e) => handleAddressChange(index, e)}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div className='col-md-6'>
                            <TextField
                                label="City"
                                name="city"
                                value={address.city || ''}
                                onChange={(e) => handleAddressChange(index, e)}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div className='col-md-6'>
                            <TextField
                                label="Postal Code"
                                name="postalCode"
                                value={address.postalCode || ''}
                                onChange={(e) => handleAddressChange(index, e)}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div className='col-md-6'>
                            <TextField
                                label="Country"
                                name="country"
                                value={address.country || ''}
                                onChange={(e) => handleAddressChange(index, e)}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div className='col-md-12'>
                            <Button
                                variant="contained"
                                className='remove_address'
                                onClick={() => handleRemoveAddress(index)}
                                disabled={loading}
                            >
                                Remove Address
                            </Button>
                        </div>
                    </AddressSection>
                ))}
                <Button
                    style={{ marginRight: '20px' }}
                    variant="contained"
                    color="success"
                    onClick={handleAddAddress}
                    disabled={loading}
                >
                    Add Address
                </Button>
                <Button variant="contained" color="success" onClick={handleUpdateAddresses} disabled={loading}>
                    {loading ? 'Updating...' : 'Update Addresses'}
                </Button>
            </Section>
        </StyledCard>
    );
};

const StyledCard = styled(Card)`
    && {
        padding: 30px;
        width: 100%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        border-radius: 10px;
    }
    input {
        font-size: 16px;
    }
    .MuiInputLabel-root {
        font-size: 16px;
        background: #ffffff;
    }
    .remove_address {
        background: transparent;
        padding: 10px;
        font-size: 14px;
        color: #ff0000;
        border: 1px solid #ff0000;
        &:hover {
            color: #ffffff;
            background: #ff0000;
            border: none;
        }
    }
    button {
        padding: 10px;
        font-size: 14px;
        font-weight: 600;
    }
`;

const Section = styled.div`
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    h2 {
        margin-bottom: 20px;
        color: #333;
        font-size: 1.8rem;
    }
`;

const AddressSection = styled.div`
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    button {
        margin-top: 10px;
    }
`;

export default MyInfo;
