import React from 'react';
import styled from "styled-components";
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import TextField from '@mui/material/TextField';
const SettingPage = () => {
  return (
   <Wrapper>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12'>
                    <h2 className='common-heading'>Setting</h2>
                </div>

            </div>
            <div className='row'>
                <div className='col-3'>
                    <div className='row'>
                        <div className='col-12 section1 boxShad'>
                            <img src='images/icon.png' alt='profile_image'/>
                            <h3 className='common-heading'>User Name</h3>
                            <h5>Position</h5>
                            <h6>last Visit 02/03/2024</h6>
                            <button>Log Out</button>
                        </div>

                        <div className='col-12 boxShad  section2'>

                            <div className='row'>
                                <EmailIcon/><h4>Email@123.com</h4>
                            </div>
                            <div className='row'>
                                <PlaceIcon/><h4>Address: asdsd asdds asd</h4>
                            </div>
                            <div className='row'>
                                <LocalPhoneIcon/><h4>+91 8456221399</h4>
                            </div>
                            <div className='row'>
                                <PersonIcon/><h4>Profile</h4>
                            </div>


                        </div>
                    </div>
                </div>
                <div className='col-9  section3'>
                  <div className='row boxShad boxShad2'>
                    <div className='col-12'>
                            <h3 className='common-heading'>My Profile Details</h3>
                            <div className='row'>
                                <div className='col-6'>
                                <TextField fullWidth label="fullWidth" id="fullWidth" />
                                </div>
                                <div className='col-6'>
                                <TextField fullWidth label="fullWidth" id="fullWidth" />
                                </div>
                                <div className='col-6'>
                                <TextField fullWidth label="fullWidth" id="fullWidth" />
                                </div>
                                <div className='col-6'>
                                <TextField fullWidth label="fullWidth" id="fullWidth" />
                                </div>
                                <div className='col-6'>
                                <TextField fullWidth label="fullWidth" id="fullWidth" />
                                </div>
                                <div className='col-6'>
                                <TextField fullWidth label="fullWidth" id="fullWidth" />
                                </div>
                                <div className='col-6'>
                                <TextField fullWidth label="fullWidth" id="fullWidth" />
                                </div>
                                <div className='col-6'>
                                <TextField fullWidth label="fullWidth" id="fullWidth" />
                                </div>
                                <div className='col-6'>
                                <TextField fullWidth label="fullWidth" id="fullWidth" />
                                </div>
                                <div className='col-6'>
                                <TextField fullWidth label="fullWidth" id="fullWidth" />
                                </div>
                                <button>Update Information</button>
                            </div>


                    </div>
                  </div>
                </div>
            </div>
        </div>
   </Wrapper>
  )
}
const Wrapper = styled.section`
img{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background: #ccc;
    object-fit: contain;
}
.boxShad{
    box-shadow:0px 0px 10px #00000040;
    border-radius: 12px;
    padding: 30px;
    display: block;
    margin: 10px ;
}
.section1{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h5{

        display: block;
        background: #FF5470;
        border-radius: 5px;
        padding: 10px 16px;

        color: #ffffff;
        margin-bottom: 10px;
        font-size: 20px;
    }

    h6{
        font-size: 20px;
        padding: 10px 0px;
    }
}
button{
    width: 70%;
    background: #538B3E;
    border: none;
    border-radius: 50px;
    margin-top: 8px;
    padding: 12px;
}
.section2{
    .row{
        display: flex;
        justify-content: start;
        align-items: center;
    }
    svg{
     width: 50px;
     height: 50px;
    }
    h4{
        width: 80%;
        margin-bottom: 0px;
        color: #000000;

    }

}
.boxShad2{
    margin: 10px 20px;
}
.section3{

    h3{
        padding-bottom: 32px;
    }
    .MuiTextField-root {
        margin-bottom: 30px;
        input{
            font-size: 2rem;
            padding-bottom: 10px;
        }
        label{
            font-size: 2rem;
            background: #ffffff;
        }


    }
    .boxShad2{
        height: 100%;
    }
    button{
        width: 400px;
        border-radius: 12px;
        font-size: 20px;

    }
}
`;
export default SettingPage;