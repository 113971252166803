import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Editor from './editor';

const Wrapper = styled.section`
    padding: 0px 50px;
    .section1 {
        padding-top: 20px;
        .MuiTextField-root {
            margin-bottom: 30px;
            input {
                font-size: 2rem;
                padding-bottom: 10px;
            }
            label {
                font-size: 2rem;
                background: #ffffff;
            }
        }
    }
    .section2 {
        img {
            display: block;
            width: 100%;
            background: #ccc;
            height: 300px;
            border-radius: 12px;
            object-fit: contain;
        }
    }
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #ffffff;
        &:hover {
            background-color: #ffffff;
        }
    }
    .uploadbtn {
        width: 100%;
        height: 200px;
        color: #000000;
        font-size: 2rem;
        font-weight: 700;
        border: 1px dashed #000000;
        border-radius: 12px;
        margin-top: 14px;
        svg {
            background: #ccc;
            padding: 18px;
            border-radius: 100%;
            width: 80px;
            height: 80px;
        }
    }
    .deletebtn {
        color: #000000;
        margin: 20px 0px;
        border: 1px solid #000000;
    }
    .submit-button {
        background: #000000;
    }
`;

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const slugify = (text) => {
    return text
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/^-+|-+$/g, '');
};

const AddBlogForm = () => {
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState('');
    const [imageName, setImageName] = useState('');
    const [categories, setCategories] = useState([]);
    const fileInputRef = useRef(null);

    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required'),
        slug: Yup.string().required('Slug is required'),
        category: Yup.string().required('Category is required'),
        seoTitle: Yup.string().max(65, 'SEO Title must be at most 65 characters').required('SEO Title is required'),
        seoDescription: Yup.string().max(250, 'SEO Description must be at most 250 characters').required('SEO Description is required'),
        content: Yup.string().required('Content is required'),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            slug: '',
            category: '',
            seoTitle: '',
            seoDescription: '',
            content: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (!file) {
                alert('Please upload an image.');
                return;
            }

            const confirmSubmission = window.confirm('Are you sure you want to submit this blog?');
            if (!confirmSubmission) return;

            try {
                const fileName = await uploadImage(file, imageName);

                if (!fileName) {
                    alert('Image upload failed. Please try again.');
                    return;
                }

                const blogData = {
                    ...values,
                    image: fileName,
                };

                const response = await fetch('https://api.rajhospitalitygroup.com/blog', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(blogData),
                });

                if (response.ok) {
                    alert('Blog added successfully.');
                    resetFormState();
                } else {
                    const errorText = await response.text();
                    throw new Error(errorText);
                }
            } catch (error) {
                console.error('Error adding blog:', error);
                alert(`Error adding blog: ${error.message}`);
            }
        },
    });

    useEffect(() => {
        if (formik.values.title) {
            const newSlug = slugify(formik.values.title);
            formik.setFieldValue('slug', newSlug);
        }
    }, [formik.values.title, formik.setFieldValue]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('https://api.rajhospitalitygroup.com/blog-categories');
                if (!response.ok) {
                    throw new Error('Failed to fetch categories');
                }
                const data = await response.json();
                setCategories(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
                alert('Failed to load categories. Please refresh the page.');
            }
        };

        fetchCategories();
    }, []);

    const uploadImage = async (file, imageName) => {
        const formData = new FormData();
        formData.append('blogImage', file, imageName || file.name);

        try {
            const response = await fetch('https://api.rajhospitalitygroup.com/uploadblog', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Uploaded file name:', result.fileName);
                return result.fileName;
            } else {
                const errorText = await response.text();
                throw new Error(errorText);
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            alert('Failed to upload image. Please try again.');
            return null;
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            console.log('Selected file:', selectedFile);
            setFile(selectedFile);
            setFileUrl(URL.createObjectURL(selectedFile));
            setImageName(selectedFile.name);
        }
    };

    const handleImageNameChange = (event) => {
        setImageName(event.target.value);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const selectedFile = event.dataTransfer.files[0];
        if (selectedFile) {
            console.log('Selected file:', selectedFile);
            setFile(selectedFile);
            setFileUrl(URL.createObjectURL(selectedFile));
            setImageName(selectedFile.name);
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const resetFormState = () => {
        formik.resetForm();
        setFile(null);
        setFileUrl('');
        setImageName('');
    };

    return (
        <Wrapper>
            <form onSubmit={formik.handleSubmit}>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'><h3>Blog</h3></div>
                        <div className='row section1'>
                            <div className='col-4'><h4 className='common-heading'>Basic Details</h4></div>
                            <div className='col-8'>
                                <TextField
                                    fullWidth
                                    label="Post title"
                                    id="title"
                                    name="title"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    error={formik.touched.title && Boolean(formik.errors.title)}
                                    helperText={formik.touched.title && formik.errors.title}
                                />
                                <TextField
                                    fullWidth
                                    label="Slug"
                                    id="slug"
                                    name="slug"
                                    value={formik.values.slug}
                                    onChange={formik.handleChange}
                                    error={formik.touched.slug && Boolean(formik.errors.slug)}
                                    helperText={formik.touched.slug && formik.errors.slug}
                                />
                                <TextField
                                    fullWidth
                                    select
                                    label="Select Category"
                                    id="category"
                                    name="category"
                                    value={formik.values.category}
                                    onChange={formik.handleChange}
                                    error={formik.touched.category && Boolean(formik.errors.category)}
                                    helperText={formik.touched.category && formik.errors.category}
                                >
                                    {categories.map((category) => (
                                        <MenuItem key={category._id} value={category._id}>
                                            {category.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className='row section2'>
                            <div className='col-4'><h4 className='common-heading'>Post Cover</h4></div>
                            <div className='col-8'>
                                {fileUrl && <img src={fileUrl} alt="Post Cover" />}
                                {file && (
                                    <TextField
                                        fullWidth
                                        label="Rename Image"
                                        id="imageName"
                                        name="imageName"
                                        value={imageName}
                                        onChange={handleImageNameChange}
                                    />
                                )}
                                <Button className='deletebtn' variant="outlined" startIcon={<DeleteIcon />} onClick={() => { setFile(null); setFileUrl(''); }}>
                                    Delete
                                </Button>
                                <Button
                                    className='uploadbtn'
                                    component="label"
                                    role={undefined}
                                    variant="contained"
                                    tabIndex={-1}
                                    startIcon={<CloudUploadIcon />}
                                    onClick={handleButtonClick}
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                >
                                    <div>
                                        <h4 className='common-heading'>Click to upload or drag and drop</h4>.
                                        <h5 className='common-heading'>(SVG, JPG, PNG, or GIF maximum 900x400)</h5>
                                    </div>
                                    <VisuallyHiddenInput
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                        aria-label="Upload blog image"
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='row section1'>
                        <div className='col-4'><h4 className='common-heading'>Content</h4></div>
                        <div className='col-8'>
                            <Editor
                                field={{ value: formik.values.content, name: 'content' }}
                                form={formik}
                            />
                            {formik.touched.content && formik.errors.content && <div className="error">{formik.errors.content}</div>}
                        </div>
                    </div>
                    <div className='row section1'>
                        <div className='col-4'><h4 className='common-heading'>Meta</h4></div>
                        <div className='col-8'>
                            <TextField
                                fullWidth
                                label="SEO Title"
                                id="seoTitle"
                                name="seoTitle"
                                value={formik.values.seoTitle}
                                onChange={formik.handleChange}
                                error={formik.touched.seoTitle && Boolean(formik.errors.seoTitle)}
                                helperText={formik.touched.seoTitle && formik.errors.seoTitle}
                            />
                            <TextField
                                fullWidth
                                label="SEO Description"
                                id="seoDescription"
                                name="seoDescription"
                                value={formik.values.seoDescription}
                                onChange={formik.handleChange}
                                error={formik.touched.seoDescription && Boolean(formik.errors.seoDescription)}
                                helperText={formik.touched.seoDescription && formik.errors.seoDescription}
                            />
                        </div>
                    </div>
                </div>

                <div className='col-12'>
                    <Button type="submit" variant="contained" color="primary" className="submit-button">
                        Submit Blog
                    </Button>
                    <Button type="button" variant="contained" color="secondary" onClick={resetFormState}>
                        Cancel
                    </Button>
                </div>
            </form>
        </Wrapper>
    );
};

export default AddBlogForm;