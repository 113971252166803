import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, List, ListItem, ListItemText, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import styled from "styled-components";

const CategoryManagement = () => {
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [error, setError] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://api.rajhospitalitygroup.com/blog-categories');
      setCategories(response.data);
      setError('');
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Error fetching categories. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingId) {
        await axios.put(`https://api.rajhospitalitygroup.com/blog-categories/${editingId}`, { name });
        setEditingId(null);
      } else {
        await axios.post('https://api.rajhospitalitygroup.com/blog-categories', { name });
      }
      setName('');
      fetchCategories();
    } catch (error) {
      console.error('Error submitting category:', error);
      setError('Error submitting category. Please try again.');
    }
  };

  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.rajhospitalitygroup.com/blog-categories/${id}`);
      fetchCategories();
      setDeleteConfirmation(null);
    } catch (error) {
      console.error('Error deleting category:', error);
      setError('Error deleting category. Please try again.');
    }
  };

  const handleEdit = (category) => {
    setName(category.name);
    setEditingId(category._id);
  };

  return (
    <Wrapper>
      <div>
        <h2 className='common-heading'>Manage Categories</h2>
        <form style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }} onSubmit={handleSubmit}>
          <TextField
            label="Category Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            style={{ marginRight: 10 }}
          />
          <Button type="submit" variant="contained" color="primary">
            {editingId ? 'Update' : 'Add'}
          </Button>
        </form>
        {error && <p>{error}</p>}
        <StyledList>
          {categories.map((category, index) => (
            <StyledListItem key={category._id} odd={index % 2 !== 0} secondaryAction={
              <>
                <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(category)}>
                  <EditIcon color="primary" />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteConfirmation(category._id)}>
                  <DeleteIcon color="primary" />
                </IconButton>
              </>
            }>
              <ListItemText primary={category.name} />
            </StyledListItem>
          ))}
        </StyledList>
        <Dialog open={deleteConfirmation !== null} onClose={() => setDeleteConfirmation(null)}>
          <DialogTitle>Delete Category</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete this category?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmation(null)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleDelete(deleteConfirmation)} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`

  max-width: 600px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  input,span{
    font-size: 16px;
  }
`;

const StyledList = styled(List)`
  & .MuiListItem-root:nth-child(even) {
    background-color: #f0f0f0;
  }
`;

const StyledListItem = styled(ListItem)`
  ${({ odd }) => odd && 'background-color: #fafafa;'}
`;

export default CategoryManagement;
