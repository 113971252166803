import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PageNavigation from "./components/pagenavigation";
import styled from "styled-components";
import { FaPenNib } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import { MdOutlineDateRange } from "react-icons/md";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://api.rajhospitalitygroup.com/blog');
        setBlogs(response.data);
        setLoading(false);
      } catch (error) {
        setError('Error fetching blogs');
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Wrapper>
      <PageNavigation title="Blog" />
      <div className='container'>
        <div className='row'>
          {blogs.map(blog => (
            <div className='col-lg-8' key={blog._id}>
              <img src={blog.image || 'images/blog/default.png'} alt={blog.title} className='blog-image'/>
              <div className='row'>
                <div className='col-md-6 blog-btn-main'>
                  <FaPenNib className='blog-btn-icon'/>
                  <NavLink to={`blog-single/${blog._id}`} className="blog-btn">{blog.authorName}</NavLink>
                  <MdOutlineDateRange className='blog-btn-icon'/>
                  <NavLink to={`blog-single/${blog._id}`} className="blog-btn">{new Date(blog.date).toLocaleDateString()}</NavLink>
                </div>
                <div className='col-12'>
                  <h3 className='blog-title'>{blog.title}</h3>
                  <p className='blog-content'>{blog.seoDescription}</p>
                  <NavLink to={`blog-single/${blog._id}`} className="read-more">Read More +</NavLink>
                </div>
              </div>
            </div>
          ))}
          <div className='col-lg-4'>
            <input
              type="text"
              name="search"
              placeholder="Search products..."
              className='search-input'
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding-top: 9rem;

  .blog-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .blog-btn {
    display: block;
    margin: 5px 10px;
    padding: 5px 15px;
    background: #65A04F;
    border-radius: 5px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    transition: background 0.3s ease;

    &:hover {
      background: #4a8a41;
    }
  }

  .blog-btn-main {
    display: flex;
    justify-content: center;
    align-items: center;

    .blog-btn-icon {
      font-size: 20px;
      margin-right: 5px;
    }
  }

  .blog-title {
    font-weight: 600;
    margin-top: 10px;
  }

  .blog-content {
    margin: 10px 0;
  }

  .read-more {
    font-weight: 600;
    color: #65A04F;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #4a8a41;
    }
  }

  .search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
`;

export default Blog;
