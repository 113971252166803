import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { HiArrowRight, HiChevronDown, HiChevronUp } from "react-icons/hi";
import { NavLink, useNavigate } from 'react-router-dom';

const CartComponentGet = () => {
    const [carts, setCarts] = useState([]);
    const [orderTotal, setOrderTotal] = useState(0);
    const [openSizeSelection, setOpenSizeSelection] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const userInfo = localStorage.getItem('user-info');
        if (userInfo) {
            const { _id } = JSON.parse(userInfo);
            console.log('User ID:', _id);  // Display the user ID on the console
            fetchData(_id);
        } else {
            console.error('User info not found in local storage');
        }
    }, []);
    useEffect(() => {
        calculateOrderTotal();
    }, [carts]);
    const imagePrice = {
        front: 100,
        back: 100,
        left: 50,
        right: 50
    };
    const calculateProductPrice = (product) => {
        let basePrice = product.price || 100;
        const images = product.images || [];
        const additionalPrice = images.reduce((acc, image, index) => {
            switch (index) {
                case 0:
                    return acc + imagePrice.front;
                case 1:
                    return acc + imagePrice.back;
                case 2:
                    return acc + imagePrice.left;
                case 3:
                    return acc + imagePrice.right;
                default:
                    return acc;
            }
        }, 0);

        return basePrice + additionalPrice;
    };
    const fetchData = (userId) => {
        console.log('Fetching data for User ID:', userId);
        fetch(`https://api.rajhospitalitygroup.com/add-to-cart/?userId=${userId}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Data received from API:', data);
                if (Array.isArray(data) && data.length > 0) {
                    const updatedCarts = data.flatMap(cartData => {
                        const productEntries = Object.entries(cartData.product || {});
                        return productEntries.map(([id, product]) => ({
                            _id: id,
                            productname: product.name,
                            images: product.images,
                            size: product.size || { // Ensure size data is handled
                                S: 0,
                                M: 0,
                                L: 0,
                                XL: 0,
                                XXL: 0
                            },
                            price: calculateProductPrice(product)
                        }));
                    });
                    setCarts(updatedCarts);
                } else {
                    console.error('No data or incorrect data format');
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    };


    const isSizeSelectedForAllProducts = () => {
        return carts.every(cart =>
            Object.values(cart.size).some(quantity => quantity > 0)
        );
    };

    const handleDeleteProduct = async (productId) => {
        const userInfo = localStorage.getItem('user-info');
        if (userInfo) {
            const { _id: userId } = JSON.parse(userInfo);
            const confirmation = window.confirm('Are you sure you want to delete this item from the cart?');
            if (confirmation) {
                // Optimistic update
                setCarts(prevCarts => prevCarts.filter(cart => cart._id !== productId));

                try {
                    const response = await fetch(`https://api.rajhospitalitygroup.com/add-to-cart/${userId}/product/${productId}`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    // Check if the response is ok
                    if (!response.ok) {
                        const errorDetails = await response.text();
                        throw new Error(`HTTP error! status: ${response.status}, details: ${errorDetails}`);
                    }

                    const result = await response.json();

                    // Check if the result indicates success
                    if (result.success) {
                        console.log('Product successfully deleted');
                    } else {
                        throw new Error('Product not deleted');
                    }
                } catch (error) {
                    console.error('Error deleting product:', error);
                    alert('An error occurred while deleting the product. The page will refresh.');
                    // Rollback the optimistic update
                    fetchData(userId); // Ensure fetchData is defined elsewhere
                }
            }
        } else {
            alert('User information not found. Please log in and try again.');
        }
    };





    const calculateOrderTotal = () => {
        const total = carts.reduce((acc, cart) => acc + (cart.price * (cart.size.S + cart.size.M + cart.size.L + cart.size.XL + cart.size.XXL)), 0);
        setOrderTotal(total);
    };

    const handleQuantityChange = (index, size, change) => {
        const updatedCarts = [...carts];
        const updatedCart = { ...updatedCarts[index] };
        const currentQuantity = updatedCart.size[size] || 0;
        updatedCart.size[size] = Math.max(0, currentQuantity + change);
        updatedCarts[index] = updatedCart;
        setCarts(updatedCarts);
    };

    const toggleSizeSelection = (index) => {
        setOpenSizeSelection(openSizeSelection === index ? null : index);
    };

    const handleCheckout = () => {
        if (isSizeSelectedForAllProducts()) {
            const cartDetails = { carts, total: orderTotal };
            localStorage.setItem('cart-details', JSON.stringify(cartDetails));
            navigate('/checkout');
        } else {
            alert('Please select at least one size for each product before proceeding to checkout.');
        }
    };

    return (
        <Wrapper>
            <div className='row'>
                <div className="cart-item col-md-7">
                    {carts.length > 0 ? (
                        <div className='cart-item_inner'>
                            <div className='header'>
                                <div className='column' style={{ textAlign: 'left', }}><h4>Product</h4></div>
                                <div className='column'><h4>Quantity</h4></div>
                                <div className='column'><h4>Price per piece</h4></div>
                                <div className='column'><h4 style={{textAlign: 'right',}}>Total</h4></div>
                            </div>
                            {carts.map((cart, index) => (
                                <div key={cart._id} className='cart-item_row'>
                                    <div className='productdetail'>
                                        <div className='image-container'>
                                            <div>
                                                <img src={cart.images.length > 0 ? `http://localhost/PHOTO_EDITING_APPLICATION_IN_PHP_WITH_SOURCE_CODE/${cart.images[0]}` : "path/to/placeholder.png"} alt="product" />

                                            </div>
                                            <div>
                                            <h3 className='common-heading'>{cart.productname}</h3>
                                            <div className='selected-sizes'>

                                               <h3>Size:</h3> {Object.entries(cart.size).map(([size, quantity]) =>
                                                    quantity > 0 && <div key={size}><h3>{size}: {quantity}</h3></div>
                                                )}
                                            </div>
                                            <div className='d-flex'>
                                                <h3>Printing Side : </h3>
                                            {cart.images.map((image, i) => (
                                                    <div className='productimage_inner' key={i} style={{display: 'inline-block',}}>
                                                    <h3>{image.split('/').pop().split('.').slice(0, -1).join('.')}</h3>
                                                </div>
                                                ))}
                                            </div>

                                            </div>

                                        </div>
                                        <div className='product-info'>

                                            <div className='productimage'>
                                                {cart.images.map((image, i) => (
                                                    <div className='productimage_inner' key={i}>
                                                        <img src={`http://localhost/PHOTO_EDITING_APPLICATION_IN_PHP_WITH_SOURCE_CODE/${image}`} alt={`image${i}`} /><br/>
                                                        <div className='productimage_inner' key={i} style={{display: 'inline-block',}}>
                                                    <h4>{image.split('/').pop().split('.').slice(0, -1).join('.')}</h4>
                                                </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='size-selection'>
                                        <div id='quantity_section' onClick={() => toggleSizeSelection(index)}>
                                            <h4>Select Size & Quantity</h4>
                                            <ArrowIcon>
                                                {openSizeSelection === index ? <HiChevronUp /> : <HiChevronDown />}
                                            </ArrowIcon>
                                        </div>
                                        {openSizeSelection === index && (
                                            <SizeSelection>
                                                <QuantityControl>
                                                    S: <button onClick={() => handleQuantityChange(index, 'S', -1)}>-</button>
                                                    <span> {cart.size.S || 0}</span>
                                                    <button onClick={() => handleQuantityChange(index, 'S', 1)}>+</button>
                                                </QuantityControl>
                                                <QuantityControl>
                                                    M: <button onClick={() => handleQuantityChange(index, 'M', -1)}>-</button>
                                                    <span> {cart.size.M || 0}</span>
                                                    <button onClick={() => handleQuantityChange(index, 'M', 1)}>+</button>
                                                </QuantityControl>
                                                <QuantityControl>
                                                    L: <button onClick={() => handleQuantityChange(index, 'L', -1)}>-</button>
                                                    <span> {cart.size.L || 0}</span>
                                                    <button onClick={() => handleQuantityChange(index, 'L', 1)}>+</button>
                                                </QuantityControl>
                                                <QuantityControl>
                                                    XL: <button onClick={() => handleQuantityChange(index, 'XL', -1)}>-</button>
                                                    <span> {cart.size.XL || 0}</span>
                                                    <button onClick={() => handleQuantityChange(index, 'XL', 1)}>+</button>
                                                </QuantityControl>
                                                <QuantityControl>
                                                    XXL:<button onClick={() => handleQuantityChange(index, 'XXL', -1)}>-</button>
                                                    <span> {cart.size.XXL || 0}</span>
                                                    <button onClick={() => handleQuantityChange(index, 'XXL', 1)}>+</button>
                                                </QuantityControl>
                                            </SizeSelection>
                                        )}
                                    </div>
                                    <div className='price'>
                                        <h5>₹ {cart.price}</h5>
                                    </div>
                                    <div className='total'>
                                        <h5>₹ {cart.price * (cart.size.S + cart.size.M + cart.size.L + cart.size.XL + cart.size.XXL)}</h5>
                                        <button onClick={() => handleDeleteProduct(cart._id)}>Remove item<img src='images/Icon_material-delete-sweep.png'  alt='Icon_material-delete-sweep.png'></img></button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>No items in cart.</p>
                    )}
                    <div className='col-12'>
                        <NavLink to="/Shop" className="Customize">
                            Continue to shopping
                        </NavLink>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className='order-summary'>
                        <div className='order-summary_inner'>
                            <h4 className='common-heading'>Order Summary</h4>
                            <div className='order-details common-heading' style={{fontSize: '16px',}} >
                                <div className='order-detail'>
                                    <span>Subtotal:</span>
                                    <span>₹ {orderTotal}</span>
                                </div>
                                <div className='order-total'>
                                    <span>Total:</span>
                                    <span>₹ {orderTotal}</span>
                                </div>
                                <button onClick={handleCheckout}>Proceed to Checkout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.section`
    .row {
        display: flex;
        justify-content: space-between;
    }
    .cart-item {
        background-color: #f8f8f8;

        border-radius: 8px;
    }
    .cart-item_inner {
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 20px;
    }
        img{
        width: 100px;}
    .header, .cart-item_row {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
    }
        .cart-item_row{
        border-bottom: 1px solid #ddd;
        }
    .header {
        border-bottom: 1px solid #ddd;
        margin-bottom: 10px;
    }
    .column {
        flex: 1;
        text-align: center;
        h4{
        font-weight: 600;
        }
    }

    .image-container {
    display:flex;
    align-items: start;
        justify-content: start;
        gap: 12px;

        img{

        width: 30px;

        }
    }
    .product-info {
        flex: 1;

    }
    .image-container {
    h3{
    font-size: 16px;
        margin: 0 0 10px 0;}

    }
    .productimage {
        display: flex;
    }
    .productimage_inner {
        margin-right: 10px;
        img {
        width: 80px;
        height: 80px;
        border-radius: 10px;
        object-fit: cover;
        object-position: center;
    }
    }

    .size-selection {
        width: 120px;

    }
    #quantity_section {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background:#F2F2F2;

        h4{
        font-size: 16px;
        color: #000000;
        font-weight: 600;
        padding: 5px;
        }
    }
    .price {
        flex: 1;
        text-align: center;
    }
    .total{

    text-align: right;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    }
    .total button {
    text-align: right;
        margin-top: 10px;
        padding: 5px 0;
        background-color: #00000000;
        color: #000000;
        font-size: 16px;
        font-weight: 600;
        border: none;
        cursor: pointer;
        width: 180px;


        img{
        width: 40px;
        height: auto;}
    }

    .order-summary {
        background-color: #f8f8f8;
        padding: 20px;
        border-radius: 8px;
        border: 1px solid #ddd;

    }

    .order-details {
        margin: 20px 0;

    }
    .order-detail, .order-total {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
    }
    .order-total {
        font-weight: bold;
    }
    .order-summary button {
        padding: 10px 20px;
        background-color: #538B3E;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        width: 100%;
        margin-top: 20px;
    }
    .order-summary button:hover {
        background-color: #ff3f3f;
    }
        .selected-sizes{
        display: flex;
        justify-content: start;
        align-items: center;
        }
        .Customize{
        display: block;
        width: 100%;
        background: #538B3E;
        text-align: center;
        border-radius: 12px;
        color: #ffffff;
        padding: 15px;
        font-size: 18px;


        }
        h5{
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        }
        h3{
        font-weight: 600;
        }
`;

const SizeSelection = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background: #F2F2F2;
     border-radius: 0px 0px 10px 10px;
`;

const QuantityControl = styled.div`
    display: flex;
    align-items: center;
    margin: 5px;
     color: #000000;
     font-weight: 600;

    button {
        padding: 0 10px;

        color: #B0B0B0;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    button:hover {
        background-color: #B0B0B090;
         color: #000000;
    }
    span {
        margin: 0 5px;

    }
`;

const ArrowIcon = styled.div`
    margin-left: 10px;
`;

export default CartComponentGet;
