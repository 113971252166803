import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, NavLink } from 'react-router-dom';
import { GrClose } from 'react-icons/gr';

const PopUpLogin = ({ toggle }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate(); // Use useNavigate hook to access navigation functionality
    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (popupRef.current && !popupRef.current.contains(e.target)) {
                toggle();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [toggle]);

    const login = async () => {
        console.warn(email, password);
        let item = { email, password };
        try {
            let result = await fetch('https://api.rajhospitalitygroup.com/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(item),
            });
            result = await result.json();
            localStorage.setItem('user-info', JSON.stringify(result));
            toggle(); // Close the popup after successful login

        } catch (error) {
            console.error('Error occurred while logging in:', error);
        }
    };

    return (
        <Popup>
            <PopupInner ref={popupRef}>
                <h2>Log In</h2>
                <label>Email</label>
                <input
                    type="text"
                    placeholder="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                />
                <label>Password</label>
                <input
                    type="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                />
                <button onClick={login} className="btn btn-primary">
                    Log In
                </button>
                <button onClick={toggle} className="close-button">
                    <GrClose />
                </button>
                <Heading>
                    Don't have an account?
                    <span>
                        <NavLink to="/SignUp">Sign up here</NavLink>
                    </span>
                </Heading>
            </PopupInner>
        </Popup>
    );
};

const Popup = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const PopupInner = styled.div`
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    max-width: 100%;
    position: relative;
    .close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        background: #538b3e;

        border: none;
        font-size: 1.5rem;
        font-weight: 800;
        cursor: pointer;
        width: 40px;
        height: 40px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }
    h2 {
        text-align: center;
        margin-bottom: 20px;
        color: #538b3e;
        font-size: 43px;
        font-weight: 600;
    }
    label {
        display: block;
        margin-bottom: 5px;
        font-size: 1.4rem;
        font-weight: 600;
    }
    input {
        width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        text-transform: lowercase;
    }
    button {
        width: 100%;
        padding: 10px;
        background-color: #538b3e;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
        margin-bottom: 10px;
        &:hover {
            background-color: #34a853;
        }
    }
`;

const Heading = styled.h3`
    text-align: center;
    font-size: 1.5rem;
    span {
        margin-left: 5px;
    }
`;

export default PopUpLogin;
