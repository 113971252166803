
import PageNavigation from "./components/pagenavigation";
import styled from "styled-components";
import ContactForm from "./components/contactform";
const Contact = () => {




  return (

    <Wrapper>
      <PageNavigation title="Contact Us" />
      <div className="container">
        <div className="row contactinfoouter3section">
          <div className="col-md-4">
            <div className="row contactinfoouter">
            <div className="col-3">
              <img src="images/contact/001-email.png"/>
            </div>
            <div className="col-9 text">
              <h4>Mail address</h4>
              <p>mehediihasa926@gmail.com +998757478492</p>
            </div>
            </div>
          </div>
          <div className="col-md-4 contactinfplace">
            <div className="row contactinfoouter">
            <div className="col-3">
              <img src="images/contact/icon01.png"/>
            </div>
            <div className="col-9 text">
              <h4>Mail address</h4>
              <p>mehediihasa926@gmail.com +998757478492</p>
            </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row contactinfoouter">
            <div className="col-3">
              <img src="images/contact/icon02.jpg"/>
            </div>
            <div className="col-9 text">
              <h4>Mail address</h4>
              <p>mehediihasa926@gmail.com +998757478492</p>
            </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <ContactForm/>
          </div>
          <div className="col-md-6">

          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448193.9509941796!2d76.76358321479294!3d28.64428736194578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1712406309150!5m2!1sen!2sin" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

          </div>
        </div>
        <div className="row">
          <div className="col-12 contactinfo common-heading">
            <h2>T-shirt printing made easy.</h2>
            <p>Let us show you how your product come to life.</p>

          </div>

        </div>
        <div className="row contactinfo2">
          <div className="col-md-6">
            <div className="bullets_point">
              <div className="number bgcol">1</div>
              <h3>Choose from 412 custom products in our
                catalog</h3>
            </div>
            <div className="bullets_point">
              <div className="number bgcol2">2</div>
              <h3>Customize your design with graphics, text
                or your own uploaded images.</h3>
            </div>
            <div className="bullets_point">
              <div className="number bgcol2">3</div>
              <h3>Order prints by selecting your preferred T-
                shirt size, style, and quantity.</h3>
            </div>
            <div className="bullets_point">
              <div className="number bgcol2">4</div>
              <h3>Get your order sent to your door with free
                standard shipping.</h3>
            </div>
          </div>
          <div className="col-md-6"><img src="images/contact/step-banner-41.png.png" /></div>

        </div>
      </div>
    </Wrapper>



  );
};
const Wrapper = styled.section`
 padding-top: 9rem;
 .contactinfoouter3section{
  margin-top: 30px;
 }
 .contactinfplace{
  background: #65A04F;

    .text {
      color: #fff;

      p{
        color: #fff;
      }
    }
 }
 .contactinfoouter{
  justify-content: center;
  align-items: center;

  img{
    width: 100%;
    height: auto;
    padding: 20px;
  }
  .text{
    padding: 20px;
    h4{
      font-weight: 600;
      font-size: 20px;
    }
    p{
      font-size: 14px;
    }
  }
 }
 .contactform{
  border-left: 0px;
  border-right: 3px solid #538B3EBF;
  form{
    margin: 45px;
    padding: 15px;
  }
 }
 iframe{
  max-width: 500px;
  max-height: 500px;
  width: 100%;
  height: 100%;
margin: 30px;
margin-top:0px;
  background: #ffffff;
  padding: 40px;
 }
 .common-heading{
margin-bottom:0px;
 }

 img{
  width: 100%;
  height: auto;
 }
 .contactinfo{
  text-align:center;
  padding: 6rem 0;

  h2{
    font-weight: 700;
  }

 }
 .bullets_point{
  display: flex;
  justify-content:center;
  align-items: center;
  padding-bottom: 20px;
  .bgcol{
    background: #65A04F;
    color: #fff;
  }
  .bgcol2{
    color: #65A04F
  }
  .number{

    width: 40px;
    height: 40px;
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 100%;
    display: flex;
  justify-content:center;
  align-items: center;
  }
  h3{
    margin-bottom: 0;
    padding: 0px 80px 0px 20px;

    font-size: 20px;
    font-weight: 600;
  }
}
.contactinfo2{


  align-items: center;
}

@media (max-width: ${({ theme }) => theme.media.mobile}) {

  iframe{
  padding: 0;
  margin: 0;
  }
  }
}

`;


export default Contact;