import PageNavigation from "./components/pagenavigation";
import styled from "styled-components";
import { IoCheckmarkSharp } from "react-icons/io5";
import Testimimonialslider from "./components/testimimonialslider";
import { BsChatSquareTextFill } from "react-icons/bs";
import FAQSection  from "./components/faqsection";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const About = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
  autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <Wrapper>
      <PageNavigation title="About Us" />
      <div className="container">
        <div className="row section1">
          <div className="col-md-6">
            <img
              src="images/service-detail-1.jpg.png"
              alt="service-detail-1.jpg"
            />
          </div>
          <div className="col-md-6">

            <h5 className="common-heading">Design your own</h5>
            <h3 className="common-heading">
              You’ve got the ideas, we’ve got the tools
            </h3>
            <p>
              T-shirt Printing for Everyone. Get a headstart with free design
              templates you can customize in a few clicks.
            </p>
            <ul>
              <li className="common-heading">
                <IoCheckmarkSharp className="icontick" />
                Top quality prints using the latest technology
              </li>
              <li className="common-heading">
                <IoCheckmarkSharp className="icontick" />
                Mix and match colors, sizes, and designs
              </li>
              <li className="common-heading">
                <IoCheckmarkSharp className="icontick" />
                Fast and free standard shipping
              </li>
              <li className="common-heading">
                <IoCheckmarkSharp className="icontick" />
                Customer happiness guarantee
              </li>
            </ul>
          </div>
        </div>

        <div className="row footer_section_1 section2">
          <div className="col-lg-4 col-mb-6 col-sm-12 flexclass border_R">
            <img src="images/SVG.png" alt="" />
            <div>
              <h5 className="common-heading">Best Quality</h5>
              <p>
                Lorem ipsum det, consec tetur adipiscing elit duis nec fringi
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-mb-6 col-sm-12 flexclass border_R">
            <img src="images/SVGbox.png" alt="" />
            <div>
              <h5 className="common-heading">Mix and match</h5>
              <p>
                Lorem ipsum det, consec tetur adipiscing elit duis nec fringi
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-mb-6 col-sm-12 flexclass border_R">
            <img src="images/SVG.png" alt="" />
            <div>
              <h5 className="common-heading">Shipping worldwide</h5>
              <p>
                Lorem ipsum det, consec tetur adipiscing elit duis nec fringi
              </p>
            </div>
          </div>
        </div>
        </div>
        <section className="section3outer">
        <div className="container">
        <div className="row section1 section3">
          <div className="col-md-6">
            <img
              src="images/div.elementor-element.png"
              alt="service-detail-1.jpg"
            />
          </div>
          <div className="col-md-6">
          <div className="vision-slider">
          <Slider {...settings}>
          <div className="slider-content">
            <h5 className="common-heading">Design your own</h5>
            <h3 className="common-heading">Vision</h3>
            <p>
              T-shirt Printing for Everyone. Get a headstart with free design
              templates you can customize in a few clicks.
            </p>
            <ul>
              <li className="common-heading">
                <IoCheckmarkSharp className="icontick" />
                Top quality prints using the latest technology
              </li>
              <li className="common-heading">
                <IoCheckmarkSharp className="icontick" />
                Mix and match colors, sizes, and designs
              </li>
              <li className="common-heading">
                <IoCheckmarkSharp className="icontick" />
                Fast and free standard shipping
              </li>
              <li className="common-heading">
                <IoCheckmarkSharp className="icontick" />
                Customer happiness guarantee
              </li>
            </ul>
            </div>
            <div className="slider-content">
            <h5 className="common-heading">Design your own</h5>
            <h3 className="common-heading">Mission</h3>
            <p>
              T-shirt Printing for Everyone. Get a headstart with free design
              templates you can customize in a few clicks.
            </p>
            <ul>
              <li className="common-heading">
                <IoCheckmarkSharp className="icontick" />
                Top quality prints using the latest technology
              </li>
              <li className="common-heading">
                <IoCheckmarkSharp className="icontick" />
                Mix and match colors, sizes, and designs
              </li>
              <li className="common-heading">
                <IoCheckmarkSharp className="icontick" />
                Fast and free standard shipping
              </li>
              <li className="common-heading">
                <IoCheckmarkSharp className="icontick" />
                Customer happiness guarantee
              </li>
            </ul>
            </div>
            </Slider>
      </div>
          </div>
        </div>
        </div>
        </section>
        <div className="container">
        <div className="row section4">
          <h3 className="common-heading mainheading">T-shirt printing made easy.</h3>
          <div className="col-md-4">
            <h3 className="common-heading textnumber">1</h3>
            <h4 className="common-heading">Add your shirt design</h4>
            <p>Lorem ipsum det, consec tetur adipiscing elit duis nec
              fringi det, consec tetur adipiscing eli</p>
          </div>
          <div className="col-md-4">
            <h3 className="common-heading textnumber">2</h3>
            <h4 className="common-heading">Add your shirt design</h4>
            <p>Lorem ipsum det, consec tetur adipiscing elit duis nec
              fringi det, consec tetur adipiscing eli</p>
          </div>
          <div className="col-md-4">
            <h3 className="common-heading textnumber">3</h3>
            <h4 className="common-heading">Add your shirt design</h4>
            <p>Lorem ipsum det, consec tetur adipiscing elit duis nec
              fringi det, consec tetur adipiscing eli</p>
          </div>
        </div>
      </div>
      <section style={{background:"#DEFFD2",}}>
      {/* <section className="section3outer section5">
      <div className="container">
        <div className="row">
          <h3 className="common-heading">Meet our team!</h3>
          <div className="col-md-3">
            <img src="images/team-about-1.jpg.png"/>
            <h4 className="common-heading">Arlene McCoy</h4>
            <p>President of Sales</p>
          </div>
          <div className="col-md-3">
            <img src="images/team-about-2.jpg.png"/>
            <h4 className="common-heading">Arlene McCoy</h4>
            <p>President of Sales</p>
          </div>
          <div className="col-md-3">
            <img src="images/team-about-3.jpg.png"/>
            <h4 className="common-heading">Arlene McCoy</h4>
            <p>President of Sales</p>
          </div>
          <div className="col-md-3">
            <img src="images/team-about-4.jpg.png"/>
            <h4 className="common-heading">Arlene McCoy</h4>
            <p>President of Sales</p>
          </div>
        </div>
      </div>
      </section> */}

      <div className="container" >
      <div className="row section1">
          <div className="col-md-6">
            <h5 className="common-heading">Design your own</h5>
            <h3 className="common-heading">
              You’ve got the ideas, we’ve got the tools
            </h3>
            <p style={{padding: "20px 0px",}}>
              T-shirt Printing for Everyone. Get a headstart with free design
              templates you can customize in a few clicks.
            </p>
            <BsChatSquareTextFill style={{color: "#538b3e", fontSize: "20px",}}/> <span className="common-heading"> &nbsp;&nbsp;&nbsp;Any questions</span>
            <h4 className="common-heading" style={{paddingTop: "20px",}}>hello@teespace.com</h4>
          </div>
          <div className="col-md-6">
          <FAQSection/>
          </div>
        </div>
      </div>
      </section>
      <Testimimonialslider/>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  padding-top: 9rem;
  .section1 {
    padding: 50px 0px;
    .col-md-6 {
      padding: 0px 50px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    h5 {
      color: #b479d9;
      background: #b479d925;
      border-radius: 50px;
      width: fit-content;
      padding: 12px 15px;
    }
    ul {
      margin: 0;
      padding: 0;
      padding-top: 20px;
      list-style: none;
      line-height: 5rem;
      font-size: 14px;
      .icontick {
        background: #e2f5f2;
        color: #538b3e;
        margin-right: 12px;
        font-size: 20px;
      }
    }
      span{
        text-transform: uppercase;

      }
  }
  .section2 {
    h5 {
      font-weight: 600;
    }
    img {
      margin-right: 20px;
    }
    p {
      width: 200px;
    }
  }
  .section3outer{
    background:#DEFFD2;
    margin: 50px 0px;
    padding: 50px 0px;
  }
  .section3 {

    flex-direction: row-reverse;
  }
  .section4 {
      padding: 50px 0px;
    text-align: center;
      .col-md-4{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0px 30px;
      }
      .mainheading{
        padding-bottom: 30px;
      }
    .textnumber{

      background: #538B3E;
      color: #ffffff;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      padding: 20px;
      margin-bottom: 20px;
      display: flex;
      font-size:22px;
    justify-content: center;
    align-items: center;
    }
  }
  .section5{
    h3{
      margin: 50px;
    }
    text-align:center;
    img{
      width: 100%;
      height: auto;
      padding: 0px 15px;
      margin-bottom: 20px;
    }
  }
  .accordionouter{
    padding: 0;
  }
  .accordionmy{
    margin-top: 0px;
  }
    .accordion-item{
    background: #00000000;
    }
    .accordion-header{
 background: #00000000;
    }
`;

export default About;
