import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Add this line if your root element has the id 'root'

const OrderDetails = () => {
    const [orders, setOrders] = useState([]);
    const [productDetails, setProductDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const userInfo = JSON.parse(localStorage.getItem('user-info'));
                if (!userInfo || !userInfo._id) {
                    throw new Error('User not logged in or user info not found.');
                }

                const response = await fetch(`https://api.rajhospitalitygroup.com/checkouts/`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                const userOrders = data.filter(order => order.user_id === userInfo._id);
                if (userOrders.length === 0) {
                    throw new Error('No orders found for this user');
                }

                setOrders(userOrders);

                const productIds = [];
                userOrders.forEach(order => {
                    order.product_details.forEach(product => {
                        if (!productIds.includes(product.productname)) {
                            productIds.push(product.productname);
                        }
                    });
                });

                const productDetailsResponse = await Promise.all(
                    productIds.map(async (productId) => {
                        const res = await fetch(`https://api.rajhospitalitygroup.com/products/${productId}`);
                        if (!res.ok) {
                            throw new Error('Failed to fetch product details');
                        }
                        const productData = await res.json();
                        return { productId, productName: productData.name, productImage: productData.images[0] };
                    })
                );

                const details = productDetailsResponse.reduce((acc, { productId, productName, productImage }) => {
                    acc[productId] = { productName, productImage };
                    return acc;
                }, {});

                setProductDetails(details);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching order details:', err);
                setError(err);
                setLoading(false);
            }
        };

        fetchOrderDetails();
    }, []);

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString();
        const formattedTime = date.toLocaleTimeString();
        return { formattedDate, formattedTime };
    };

    const handleViewDetail = (order) => {
        setSelectedOrder(order);
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
        setSelectedOrder(null);
    };

    const handleDeleteOrder = async (orderId) => {
        const confirmation = window.confirm("Are you sure you want to delete this order?");
        if (confirmation) {
            try {
                // Perform the delete request
                const response = await fetch(`https://api.rajhospitalitygroup.com/checkouts/${orderId}`, {
                    method: 'DELETE'
                });

                if (!response.ok) {
                    throw new Error('Failed to delete order');
                }

                // Update the state to remove the deleted order
                setOrders(orders.filter(order => order._id !== orderId));
            } catch (error) {
                console.error('Error deleting order:', error);
                setError(error);
            }
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <Wrapper>
            {orders.length > 0 ? (
                orders.map(order => {
                    const { formattedDate, formattedTime } = formatDateTime(order.createdAt);
                    return (
                        <div key={order._id} className="order-details row">
                            <div className="col-md-6">
                                <h4 className="common-heading">Order ID: {order._id}</h4>
                                <p><strong>Order Date:</strong> {formattedDate}</p>
                                <p><strong>Order Time:</strong> {formattedTime}</p>
                            </div>
                            <div className="col-md-6 text_align_R">
                                <h4 className="common-heading">Orders_Status: {order.Orders_Status}</h4>
                                <p><strong>Shipping:</strong> {order.shipping}</p>
                                <p><strong>Payment Method:</strong> {order.paymentMethod}</p>
                            </div>

                            <h3>Product Details</h3>
                            {order.product_details.map((item, index) => (
                                <div key={index} className="product-item row">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='row'>
                                                <div className='col-3'>
                                                    <img src={`images/product/${productDetails[item.productname]?.productImage}`} alt={productDetails[item.productname]?.productName} />
                                                </div>
                                                <div className='col-9'>
                                                    <h4>{productDetails[item.productname]?.productName || item.productname}</h4>
                                                    <p><strong>Size:</strong> {Object.keys(item.size).map(size => (
                                                        <span key={size}>{size}: {item.size[size]} </span>
                                                    ))}</p>
                                                    <p><strong>Price:</strong> ₹{item.price}</p>
                                                    <p><strong>Quantity:</strong> {Object.values(item.size).reduce((a, b) => a + b, 0)}</p>
                                                    <p><strong>Total:</strong> ₹{Object.values(item.size).reduce((a, b) => a + b, 0) * item.price}</p>
                                                </div>
                                                <div className='col-12'>
                                                    {item.images.map((img, idx) => (
                                                        <img key={idx} src={`http://localhost/PHOTO_EDITING_APPLICATION_IN_PHP_WITH_SOURCE_CODE/${img}`} alt={productDetails[item.productname]?.productName || item.productname} />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <h3>Order Summary</h3>
                            <p><strong>Subtotal:</strong> ₹{order.subtotal}</p>
                            <p><strong>Discount:</strong> ₹{order.Discount}</p>
                            <p><strong>Delivery Fee:</strong> ₹{order.Delivery_fee}</p>
                            <p><strong>Tax:</strong> ₹{order.Tax}</p>
                            <p><strong>Grand Total:</strong> ₹{order.Grand_Total}</p>

                            <div className='viewbutton'>
                                <button className='button-37' onClick={() => handleViewDetail(order)}>View Detail</button>
                                <button className='button-37 delete-button' onClick={() => handleDeleteOrder(order._id)}>Delete Order</button>
                            </div>
                        </div>
                    );
                })
            ) : (
                <p>No order details found.</p>
            )}

            {selectedOrder && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={handleCloseModal}
                    style={customStyles}
                    contentLabel="Order Detail Modal"
                >
                    <ModalContent>
                        <h2>Order ID: {selectedOrder._id}</h2>
                        <p><strong>Order Status:</strong> {selectedOrder.Orders_Status}</p>
                        <p><strong>Company Contact:</strong> 123-456-7890</p>
                        <a href={`https://api.rajhospitalitygroup.com/invoice/${selectedOrder._id}`} download className="download-link">Download Invoice</a>
                        <button onClick={handleCloseModal} className="close-button">Close</button>
                    </ModalContent>
                </Modal>
            )}
        </Wrapper>
    );
};

// Modal styles
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '12px',
        padding: '2rem',
        maxWidth: '600px',
        width: '100%',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        overflow: 'hidden',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
    }
};

const ModalContent = styled.div`
  h2 {
    margin-bottom: 1rem;
    font-size: 24px;
    color: #333;
  }
  p {
    font-size: 16px;
    color: #555;
    margin-bottom: 1rem;
  }
  .download-link {
    display: inline-block;
    margin-top: 1rem;
    padding: 10px 20px;
    color: #fff;
    background-color: #538B3E;
    border-radius: 4px;
    text-decoration: none;
  }
  .close-button {
    margin-top: 1rem;
    padding: 10px 20px;
    background-color: #999;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
`;

const Wrapper = styled.section`
    .order-details {
        background-color: #fff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        margin-bottom: 2rem;
        padding: 2rem;
        border-radius: 12px;
    }
    .common-heading {
        color: #538B3E;
        margin-bottom: 1rem;
    }
    .product-item img {
        width: 100%;
        max-width: 100px;
        border-radius: 6px;
    }
    .viewbutton {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
    }
    .button-37 {
        background-color: #538B3E;
        color: #fff;
        padding: 10px 20px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        font-size: 16px;
    }
    .delete-button {
        background-color: #FF4136;
    }
`;

export default OrderDetails;
