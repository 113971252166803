import React, { useEffect, useState } from 'react';

const Abundantcart = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Retrieve and parse user-info from local storage
    const userInfo = localStorage.getItem('user-info');
    console.log("User info from local storage:", userInfo);

    if (!userInfo) {
      console.error("No user info found in local storage.");
      setError("No user info found in local storage.");
      setLoading(false);
      return;
    }

    const parsedUserInfo = JSON.parse(userInfo);
    const userId = parsedUserInfo._id;
    console.log("Extracted user ID:", userId);

    // Fetch and filter data based on userId
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.rajhospitalitygroup.com/add-to-cart');
        console.log("API response status:", response.status);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        console.log("Fetched data:", result);

        if (!Array.isArray(result)) {
          throw new Error('Expected an array of data');
        }

        // Filter data based on user._id
        const filteredData = result.filter(item => item.user._id === userId);
        console.log("Filtered data:", filteredData);

        setData(filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDelete = (id) => {
    console.log("Deleting item with ID:", id);

    fetch(`https://api.rajhospitalitygroup.com/add-to-cart/${id}`, {
      method: 'DELETE',
    })
    .then(response => {
      console.log("Delete response status:", response.status);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setData(prevData => prevData.filter(item => item._id !== id));
    })
    .catch(error => {
      console.error("Error deleting data:", error);
      setError(error.message);
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1>Abundantcart</h1>
      <table>
        <thead>
          <tr>
            <th>Folder ID</th>
            <th>Files</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <tr>
              <td colSpan="3">No data available</td>
            </tr>
          ) : (
            data.map((item) => (
              <React.Fragment key={item._id}>
                {Object.keys(item.folders).map(folderId => (
                  <tr key={folderId}>
                    <td>{folderId}</td>
                    <td>
                      {Object.keys(item.folders[folderId]).length === 0 ? (
                        <div>No files</div>
                      ) : (
                        <ul>
                          {Object.keys(item.folders[folderId]).map(fileId => (
                            <li key={fileId}>File ID: {fileId}</li>
                          ))}
                        </ul>
                      )}
                    </td>
                    <td>
                      <button onClick={() => handleDelete(item._id)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Abundantcart;
