import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import Sidebar from './components/sidebar'; // Import the Sidebar component
import OrderDetails from './components/orderdetails';
import MyInfo from './components/myinfo';

const UserProfile = () => {
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState('myInfo'); // State to track the selected menu item
    const [error, setError] = useState(null); // State for error handling
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        // Retrieve user ID from localStorage
        const userInfo = JSON.parse(localStorage.getItem('user-info'));
        if (userInfo && userInfo._id) {
            setUserId(userInfo._id);
        }

        // Fetch user data based on userId
        const fetchUserData = async () => {
            if (userId) {
                try {
                    const response = await fetch(`https://api.rajhospitalitygroup.com/users/${userId}`);

                    // Check if the response is JSON or plain text
                    const contentType = response.headers.get('content-type');

                    if (response.ok) {
                        if (contentType && contentType.includes('application/json')) {
                            const data = await response.json();
                            setUser(data);
                        } else {
                            const text = await response.text();
                            console.error('Error fetching user data:', text);
                            setError('Error fetching user data');
                        }
                    } else {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    setError('Error fetching user data');
                }
            }
        };

        fetchUserData();
    }, [userId]);

    const logOut = () => {
        localStorage.clear();
        navigate('/SignUp');
    };

    if (error) {
        return <Loading>{error}</Loading>;
    }

    if (!user) {
        return <Loading>Loading...</Loading>;
    }

    const renderContent = () => {
        switch (selectedMenu) {
            case 'myInfo':
                return <ContentCard><MyInfo user={user} /></ContentCard>;
            case 'myOrders':
                return <ContentCard><OrderDetails /></ContentCard>;
            case 'signOut':
                return (
                    <ContentCard>
                        <SignOutWrapper>
                            <h2>Are you sure you want to sign out?</h2>
                            <button onClick={logOut} className="btn btn-primary">Yes, Sign Out</button>
                        </SignOutWrapper>
                    </ContentCard>
                );
            default:
                return null;
        }
    };

    return (
        <ProfileWrapper className='container'>
            <div className='row'>
                <Sidebar onSelect={setSelectedMenu} />
                <ContentWrapper className='col-9'>
                    {renderContent()}
                </ContentWrapper>
            </div>
        </ProfileWrapper>
    );
};

const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5rem;
    color: #555;
`;

const ProfileWrapper = styled.section`
    height: 100vh;
    overflow: auto;
    margin-top: 100px;
`;

const ContentWrapper = styled.div`
    flex-grow: 1;
    overflow: auto;
`;

const ContentCard = styled.div`
    background: #fff;
    border-radius: 8px;
    width: 100%;
`;

const SignOutWrapper = styled.div`
    text-align: center;
    padding: 20px;
    h2 {
        margin-bottom: 20px;
    }
`;

export default UserProfile;
