import React, { useState } from 'react';
import styled from "styled-components";
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const RegistrationForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className='container'>
        <div className='row user_signupform'>
          <div className='col-md-6'>
            <div className='user_signupform_heading'>
              <h3 className='common-heading '>Free access to all <span>Rabbit and Turtle</span> tools</h3>
              <p>Get back to your designs in a few clicks</p>
            </div>
            <Formik
              initialValues={{
                user_name: '',
                email: '',
                password: ''
              }}
              validate={values => {
                const errors = {};
                if (!values.user_name) {
                  errors.user_name = "Required";
                }

                if (!values.email) {
                  errors.email = "Required";
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                  errors.email = "Invalid email address";
                }

                if (!values.password) {
                  errors.password = "Required";
                } else if (values.password.length > 12) {
                  errors.password = "Password max limit 12 characters";
                } else if (!/(?=.*[A-Z])/.test(values.password)) {
                  errors.password = "Password must contain at least one uppercase letter";
                } else if (!/(?=.*[a-z])/.test(values.password)) {
                  errors.password = "Password must contain at least one lowercase letter";
                } else if (!/(?=.*\d)/.test(values.password)) {
                  errors.password = "Password must contain at least one numeric digit";
                } else if (!/(?=.*[!@#$%^&*(),.?":{}|<>])/.test(values.password)) {
                  errors.password = "Password must contain at least one special character";
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                axios.post('https://api.rajhospitalitygroup.com/users', values)
                .then(response => {
                  console.log("Server response:", response.data);
                  alert("Form submitted successfully!");
                  navigate("/profile");
                  setSubmitting(false);
                })
                .catch(error => {
                  console.error('Error saving form data:', error);
                  if (error.response && error.response.data && error.response.data.message) {
                    alert(error.response.data.message); // This will show 'Email ID already taken' if that's the error message
                  } else {
                    alert('An error occurred.');
                  }
                  setSubmitting(false);
                });
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <InputGroup>
                    <label htmlFor="user_name">User Name</label>
                    <StyledField type="text" name="user_name" placeholder="Full Name" />
                    <StyledErrorMessage name="user_name" component="div" />
                  </InputGroup>

                  <InputGroup>
                    <label htmlFor="email">Your Email</label>
                    <StyledField type="email" name="email" placeholder="Email" />
                    <StyledErrorMessage name="email" component="div" />
                  </InputGroup>

                  <InputGroup>
                    <label htmlFor="password">Password</label>
                    <StyledField type={showPassword ? "text" : "password"} name="password" placeholder="Password" />
                    <StyledIcon onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </StyledIcon>
                    <StyledErrorMessage name="password" component="div" />
                  </InputGroup>

                  <StyledButton type="submit" disabled={isSubmitting}>
                    Sign Up
                  </StyledButton>
                </Form>
              )}
            </Formik>
            <div className='user_signupform_heading' style={{ paddingTop: '50px' }}>
              <p>By creating an account, you agree to our <span className='common-heading'>Terms & Conditions</span></p>
            </div>
          </div>
          <div className='col-md-6'>
            <img src='images/image_100.png' alt='signupimg' />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  label {
    display: block;
    font-weight: 600;
    font-size: 1.6rem;
    padding-bottom: 10px;
  }
  .user_signupform {
    align-items: center;
    span {
      color: #538B3E;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .user_signupform_heading {
      text-align: center;
      h3 {
        font-size: 2.8rem;
      }
    }
  }
`;

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

const StyledField = styled(Field)`
  max-width: 100%;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  text-transform: inherit;
  &:focus {
    outline: none;
    border-color: dodgerblue;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  color: red;
  font-size: 16px;
  margin-top: 5px;
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #538B3E;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #34A853;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const StyledIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 70%;
  font-size: 20px;
  transform: translateY(-50%);
  cursor: pointer;
`;

export default RegistrationForm;
