import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/website/home.js";
import About from "./components/website/about.js";
import Product from "./components/website/products.js";
import Editor from "./components/website/editor.js";
import Blog from "./components/website/blog.js";
import Profile from './components/website/profile.js';
import PrivacyPolicy from './components/website/privacypolicy.js';
import TermsOfService  from './components/website/termsofservice.js';
import TermsAndConditions from './components/website/termsandconditions.js';
import Contact from "./components/website/contact.js";
import SingleProduct from "./components/website/singleproduct.js";
import Addproduct from "./components/website/addproduct.js";
import Cart from "./components/website/cart.js";
import Abundantcart from "./components/website/components/abundantcart.js";
import SignUp from "./components/website/signup.js";
import ForgotPassword from "./components/website/components/forgotpassword.js";
import ResetPassword from "./components/website/components/resetpassword.js";
import Addtocart from "./components/website/addtocart.js";
import Checkout from "./components/website/checkout.js";
import Thankyou from "./components/website/thankyou.js";
import Error from "./components/website/error.js";
import UserProfile from "./components/website/userprofile.js";
import WhatsAppIcon from './components/website/components/whatsappicon.js';

import { GlobalStyle } from "./globalstyle.js";
import { ThemeProvider } from 'styled-components';
import Header from './components/website/components/header.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './components/website/styles/contactform.css';






import Footer from './components/website/components/footer.js';



const WebsiteRoutes = () => {

  const theme = {
    colors: {
      header: "",
      text: "rgba(29 ,29, 29, .8)",
      white: "fff",
      black: "#212529",
      helper: "#8490ff",

      bg: "#F6F8FA",
      footer_bg: "#0a1435",
      btn: "rgb(98 84 243)",
      border: "rgba(98, 84, 243, 0.5)",
      hr: "#ffffff",
      gradient:
        "linear-gradient(0deg, rgb(132 144 255) 0%, rbg(98 189 252) 100%)",
      shadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
      shadowSupport: "rgba(0, 0, 0, 0.16) 0px 1px 4px",

    },
    media: {
      mobile: "1023px",
      tab: "998px",
    },
  };
  return (
    <>
      <ThemeProvider theme={theme}>

          <GlobalStyle />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/Shop" element={<Product />} />
            <Route path="/Editor" element={<Editor />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Addproduct" element={<Addproduct />} />
            <Route path="/Singleproduct/:id" element={<SingleProduct />} />
            <Route path='/Profile' element={<Profile/>}/>
            <Route path='/PrivacyPolicy' element={<PrivacyPolicy/>}/>
            <Route path='/TermsOfService' element={<TermsOfService/>}/>
            <Route path='/TermsAndConditions' element={<TermsAndConditions/>}/>

            <Route path='/UserProfile' element={<UserProfile/>}/>
            <Route path='/Abundantcart' element={<Abundantcart/>}/>

            <Route path='/SignUp' element={<SignUp/>}/>
            <Route path='/ForgotPassword' element={<ForgotPassword/>}/>
            <Route path='/reset-password/:token' element={<ResetPassword />}/>

            <Route path='/Addtocart' element={<Addtocart/>}/>
            <Route path='/Checkout' element={<Checkout/>}/>
            <Route path='/Thankyou' element={<Thankyou/>}/>
            <Route path="/Cart" element={<Cart />} />




            <Route path="*" element={<Error />} />
          </Routes>
          <Footer />

          <WhatsAppIcon />

      </ThemeProvider>
      <div>
        {/* <h1>Data from Backend Server</h1> */}

      </div>

    </>

  );
};

export default WebsiteRoutes;