import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AdminLogin from "./components/admin/adminlogin.js";
import Dashboard from "./components/admin/dashboard.js";

import AddProductToDatabase from "./components/admin/admindashboard.js"

import { GlobalStyle } from "./globalstyle.js";
import { ThemeProvider } from 'styled-components';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './components/website/styles/contactform.css';
import EditProduct from "./components/admin/components/editproduct.js";









const AdminRoutes = () => {

  const theme = {
    colors: {
      header: "",
      text: "rgba(29 ,29, 29, .8)",
      white: "fff",
      black: "#212529",
      helper: "#8490ff",

      bg: "#F6F8FA",
      footer_bg: "#0a1435",
      btn: "rgb(98 84 243)",
      border: "rgba(98, 84, 243, 0.5)",
      hr: "#ffffff",
      gradient:
        "linear-gradient(0deg, rgb(132 144 255) 0%, rbg(98 189 252) 100%)",
      shadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
      shadowSupport: "rgba(0, 0, 0, 0.16) 0px 1px 4px",

    },
    media: {
      mobile: "1023px",
      tab: "998px",
    },
  };
  return (
    <>
      <ThemeProvider theme={theme}>

          <GlobalStyle />

          <Routes>
            <Route path="/" element={<AdminLogin />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/AddProductToDatabase" element={<AddProductToDatabase />} />
            <Route path="/EditProduct" element={<EditProduct />} />



          </Routes>




      </ThemeProvider>
      <div>
        {/* <h1>Data from Backend Server</h1> */}

      </div>

    </>

  );
};

export default AdminRoutes;