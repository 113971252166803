import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from 'styled-components';

const ProductCategories = () => {
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState('');
  const [frontPrice, setFrontPrice] = useState('');
  const [backPrice, setBackPrice] = useState('');
  const [leftPrice, setLeftPrice] = useState('');
  const [rightPrice, setRightPrice] = useState('');
  const [images, setImages] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.rajhospitalitygroup.com/product-categories');
      setCategories(response.data);
      setError('');
    } catch (err) {
      console.error('Error fetching categories:', err);
      setError('Error fetching categories. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      category_name: name,
      front_price: frontPrice,
      back_price: backPrice,
      left_price: leftPrice,
      right_price: rightPrice,
      images,
    };

    if (editingId) {
      payload._id = editingId; // Include the ID when updating
    }

    try {
      if (editingId) {
        await axios.put(`https://api.rajhospitalitygroup.com/product-categories/${editingId}`, payload);
        setEditingId(null);
      } else {
        await axios.post('https://api.rajhospitalitygroup.com/product-categories', payload);
      }
      resetForm();
      fetchCategories();
    } catch (err) {
      console.error('Error submitting category:', err);
      if (err.response) {
        console.error('Error response data:', err.response.data);
        setError(err.response.data.error || 'Error submitting category. Please try again.');
      } else if (err.request) {
        console.error('Error request data:', err.request);
        setError('No response received from server.');
      } else {
        console.error('Error message:', err.message);
        setError('An error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };


  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(id);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`https://api.rajhospitalitygroup.com/product-categories/${id}`);
      fetchCategories();
      setDeleteConfirmation(null);
    } catch (err) {
      console.error('Error deleting category:', err);
      setError('Error deleting category. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (category) => {
    setName(category.category_name);
    setFrontPrice(category.front_price || '');
    setBackPrice(category.back_price || '');
    setLeftPrice(category.left_price || '');
    setRightPrice(category.right_price || '');
    setImages(category.images || []);
    setEditingId(category._id);
  };

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const newImages = [...images];
        newImages[index] = reader.result;
        setImages(newImages);
      };
      reader.readAsDataURL(file);
    }
  };

  const resetForm = () => {
    setName('');
    setFrontPrice('');
    setBackPrice('');
    setLeftPrice('');
    setRightPrice('');
    setImages([]);
  };

  return (
    <Wrapper>
      <div>
        <h2 className='common-heading'>Manage Product Categories</h2>
        <form style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }} onSubmit={handleSubmit}>
          <TextField
            label="Category Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            style={{ marginBottom: 20 }}
          />
          <TextField
            label="Front Price"
            value={frontPrice}
            onChange={(e) => setFrontPrice(e.target.value)}
            type="number"
            style={{ marginBottom: 20 }}
          />
          <TextField
            label="Back Price"
            value={backPrice}
            onChange={(e) => setBackPrice(e.target.value)}
            type="number"
            style={{ marginBottom: 20 }}
          />
          <TextField
            label="Left Price"
            value={leftPrice}
            onChange={(e) => setLeftPrice(e.target.value)}
            type="number"
            style={{ marginBottom: 20 }}
          />
          <TextField
            label="Right Price"
            value={rightPrice}
            onChange={(e) => setRightPrice(e.target.value)}
            type="number"
            style={{ marginBottom: 20 }}
          />
          <ImageContainer>
            {Array.from({ length: 5 }).map((_, index) => (
              <ImageUpload key={index}>
                {images[index] && <img src={images[index]} alt={`Category Image ${index + 1}`} />}
                <input
                  accept="image/*"
                  type="file"
                  style={{ display: 'none' }}
                  id={`image-upload-${index}`}
                  onChange={(e) => handleImageChange(e, index)}
                />
                <label htmlFor={`image-upload-${index}`}>
                  <IconButton component="span">
                    <CloudUploadIcon color="primary" />
                  </IconButton>
                </label>
              </ImageUpload>
            ))}
          </ImageContainer>
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : editingId ? 'Update' : 'Add'}
          </Button>
        </form>
        {error && <ErrorText>{error}</ErrorText>}
        {loading ? (
          <CircularProgress />
        ) : (
          <StyledList>
            {categories.map((category, index) => (
              <StyledListItem
                key={category._id}
                odd={index % 2 !== 0}
                secondaryAction={
                  <>
                    <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(category)}>
                      <EditIcon color="primary" />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteConfirmation(category._id)}>
                      <DeleteIcon color="primary" />
                    </IconButton>
                  </>
                }
              >
                <ListItemText
                  primary={category.category_name}
                  secondary={`Front Price: ${category.front_price || 'N/A'} | Back Price: ${category.back_price || 'N/A'} | Left Price: ${category.left_price || 'N/A'} | Right Price: ${category.right_price || 'N/A'}`}
                />
              </StyledListItem>
            ))}
          </StyledList>
        )}
        <Dialog open={deleteConfirmation !== null} onClose={() => setDeleteConfirmation(null)}>
          <DialogTitle>Delete Category</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete this category?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmation(null)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleDelete(deleteConfirmation)} color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Confirm'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  max-width: 600px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  input, span {
    font-size: 16px;
  }
`;

const StyledList = styled(List)`
  margin-top: 20px;
`;

const StyledListItem = styled(ListItem)`
  background-color: ${props => (props.odd ? '#f9f9f9' : '#fff')};
  margin-bottom: 10px;
  border-radius: 4px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ImageUpload = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  label {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

export default ProductCategories;
