import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import BlazeSlider from 'blaze-slider';
import 'blaze-slider/dist/blaze.css';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import axios from 'axios';

const HeroSection = () => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://api.rajhospitalitygroup.com/sliderimage');
        const imageSrcs = response.data.map((image) => ({
          id: image._id,
          src: `https://api.rajhospitalitygroup.com${image.src}`,
        }));
        setImages(imageSrcs);
      } catch (error) {
        setError('Error fetching images');
        console.error('Error fetching images', error);
      }
    };
    fetchImages();
  }, []);

  useEffect(() => {
    const el = document.querySelector('.blaze-slider');
    if (el) {
      new BlazeSlider(el, {
        all: {
          enableAutoplay: true,
          draggable: true,
          stopAutoplayOnInteraction: true,
          autoplayInterval: 3000,
          transitionDuration: 1000,
          slidesToShow: 1,
          slideGap: '10px',
          loop: true,
        },
        '(max-width: 900px)': {
          slidesToShow: 1,
        },
        '(max-width: 500px)': {
          slidesToShow: 1,
        },
      });
    }
  }, [images]); // Reinitialize slider when images change

  return (
    <Wrapper>
      <div className='container-fluid'>
        <div className="blaze-slider">
          <div className="blaze-container">
            <div className="blaze-track-container">
              <div className="blaze-track">
                {images.length ? (
                  images.map((image) => (
                    <div key={image.id}><img src={image.src} alt={`slide${image.id}`} /></div>
                  ))
                ) : (
                  <p>No images available</p>
                )}
              </div>
            </div>
            <div className="my-nav-container headerslider">
              <button className="blaze-prev"><FaChevronLeft /></button>
              <button className="blaze-next"><FaChevronRight /></button>
            </div>
          </div>
        </div>
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  .blaze-slider {
    padding-top: 4.2%;
  }
  img {
    width: 100%;
    height: 80vh;
    object-fit: cover;
  }
  @media (max-width: 900px) {
    img {
      height: 600px; /* Adjust the height for smaller screens */
    }
  }
  @media (max-width: 500px) {
    img {
      height: 300px; /* Adjust the height for even smaller screens */
    }
  }
  .blaze-slider {
    position: relative;
    padding-top: 4.2%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .blaze-prev,
  .blaze-next {
    background-color: #00000000;
    color: #fff;
    font-size: 50px;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .blaze-prev {
    left: 10px;
  }
  .blaze-next {
    right: 10px;
  }
  .blaze-prev:hover,
  .blaze-next:hover {

    color: #A6DD10;
  }
    @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .headerslider .blaze-prev,
  .headerslider .blaze-next {
  display: none;
  }

    }
`;

export default HeroSection;
