import React, { useState } from 'react';
import UserList from './components/userlist';
import UserForm from './components/userform';
import UserEdit from './components/useredit';
import { registerUser } from './services/userservice';

const UserManagement = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [editUserId, setEditUserId] = useState(null);

    const handleRegister = async (formData) => {
        await registerUser(formData);
    };

    const handleEdit = (user) => {
        setEditUserId(user._id);
        setIsEditing(true);
    };

    const handleCloseEdit = () => {
        setIsEditing(false);
        setEditUserId(null);
    };

    return (
        <div>
            <h2>User Management</h2>
            {isEditing ? (
                <UserEdit userId={editUserId} onClose={handleCloseEdit} />
            ) : (
                <UserForm onSubmit={handleRegister} />
            )}
            <UserList onEdit={handleEdit} />
        </div>
    );
};

export default UserManagement;
