import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CardHeader,
} from '@mui/material';
import { styled } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const StyledCard = styled('div')({
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  overflow: 'hidden',
  backgroundColor: '#fff',
});

const StyledCardMedia = styled('div')(({ image }) => ({
  height: '200px',
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const StyledCardContent = styled('div')({
  padding: '16px',
});

const StyledCardActions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 16px 16px',
});

const ActionButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
});

const UpdateButton = styled(Button)({
  marginTop: '16px',
});

const BlogManagement = () => {
  const [blogs, setBlogs] = useState([]);
  const [editingBlog, setEditingBlog] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [blogToDelete, setBlogToDelete] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchBlogs();
    fetchCategories();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get('https://api.rajhospitalitygroup.com/blog');
      setBlogs(response.data);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://api.rajhospitalitygroup.com/blog-categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleEdit = (blog) => {
    setEditingBlog(blog);
    setIsEditDialogOpen(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      for (const key in editingBlog) {
        formData.append(key, editingBlog[key]);
      }
      if (imageFile) {
        formData.append('image', imageFile);
      }
      await axios.put(`https://api.rajhospitalitygroup.com/blog/${editingBlog._id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setIsEditDialogOpen(false);
      setImageFile(null); // Clear the image file after successful update
      fetchBlogs();
    } catch (error) {
      console.error('Error updating blog:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.rajhospitalitygroup.com/blog/${id}`);
      setIsDeleteDialogOpen(false);
      fetchBlogs();
    } catch (error) {
      console.error('Error deleting blog:', error);
    }
  };

  const handleDeleteConfirmation = (blog) => {
    setBlogToDelete(blog);
    setIsDeleteDialogOpen(true);
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '24px', padding: '24px' }}>
      {blogs.map((blog) => (
        <StyledCard key={blog._id}>
          <StyledCardMedia
            image={blog.image || 'https://via.placeholder.com/300x200'}
            title={blog.title}
          />
          <CardHeader title={blog.title} subheader={blog.category} />
          <StyledCardContent>
            <p>{blog.seoDescription}</p>
          </StyledCardContent>
          <StyledCardActions>
            <ActionButton color="primary" onClick={() => handleEdit(blog)} style={{ background: "#2c8b48" }}>
              <EditIcon /> Edit
            </ActionButton>
            <ActionButton color="error" onClick={() => handleDeleteConfirmation(blog)} style={{ background: "red" }}>
              <DeleteIcon /> Delete
            </ActionButton>
            <ActionButton color="secondary" style={{ background: "grey" }}>
              <VisibilityIcon /> View
            </ActionButton>
          </StyledCardActions>
        </StyledCard>
      ))}

      {/* Edit Dialog */}
      <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Edit Blog Post</DialogTitle>
        <DialogContent>
          <form onSubmit={handleUpdate}>
            <TextField
              label="Title"
              fullWidth
              margin="normal"
              value={editingBlog?.title || ''}
              onChange={(e) => setEditingBlog({ ...editingBlog, title: e.target.value })}
            />
            <TextField
              label="Category"
              fullWidth
              margin="normal"
              value={editingBlog?.category || ''}
              onChange={(e) => setEditingBlog({ ...editingBlog, category: e.target.value })}
              select
            >
              {categories.map((category) => (
                <MenuItem key={category._id} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="SEO Title"
              fullWidth
              margin="normal"
              value={editingBlog?.seoTitle || ''}
              onChange={(e) => setEditingBlog({ ...editingBlog, seoTitle: e.target.value })}
            />
            <TextField
              label="SEO Description"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={editingBlog?.seoDescription || ''}
              onChange={(e) => setEditingBlog({ ...editingBlog, seoDescription: e.target.value })}
            />
            <TextField
              label="Content"
              fullWidth
              margin="normal"
              multiline
              rows={6}
              value={editingBlog?.content || ''}
              onChange={(e) => setEditingBlog({ ...editingBlog, content: e.target.value })}
            />
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="image-upload"
              type="file"
              onChange={handleImageChange}
            />
            <label htmlFor="image-upload">
              <Button variant="outlined" component="span" startIcon={<CloudUploadIcon />}>
                Upload Image
              </Button>
            </label>
            {imageFile && <p>{imageFile.name}</p>}
            <DialogActions>
              <UpdateButton
                type="submit"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Save changes
              </UpdateButton>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">Delete Blog Post</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this blog post titled "{blogToDelete?.title}"?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDelete(blogToDelete._id)} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BlogManagement;
