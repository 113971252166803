import React, { useEffect, useState } from 'react';
import ProductCard from './productcard';
import EditProduct from './editproduct';
import styled from 'styled-components';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editOpen, setEditOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);

  const fetchProducts = async () => {
    try {
      const response = await fetch('https://api.rajhospitalitygroup.com/products');
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error('Failed to fetch products:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleView = (id) => {
    console.log('View product:', id);
    window.open(`/SingleProduct/${id}`, '_blank');
  };

  const handleEditOpen = (product) => {
    setCurrentProduct(product);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setCurrentProduct(null);
  };

  const handleSave = (updatedProduct) => {
    setProducts(products.map(product =>
      product.id === updatedProduct.id ? updatedProduct : product
    ));
    handleEditClose();
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this product?');
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await fetch(`https://api.rajhospitalitygroup.com/products/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setProducts(products.filter(product => product.id !== id));
        alert('Product deleted successfully.');
      } else {
        const errorText = await response.text();
        console.error('Product deletion failed:', errorText);
        alert(`Product deletion failed: ${errorText}`);
      }
    } catch (error) {
      console.error('Error deleting product:', error);
      alert('Error deleting product.');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <ProductGrid>
      {products.map(product => (
        <ProductCard
          key={product.id}
          product={product}
          onEdit={() => handleEditOpen(product)}
          onDelete={() => handleDelete(product.id)}
          onView={() => handleView(product.id)}
        />
      ))}
      {currentProduct && (
        <EditProduct
          open={editOpen}
          onClose={handleEditClose}
          product={currentProduct}
          onSave={handleSave}
        />
      )}
    </ProductGrid>
  );
};

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
  gap: 16px;
  padding: 16px;
`;

export default ProductList;
