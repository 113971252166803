import React from 'react';

const Usp = () => {
  return (
    <>
      <style>
        {`




        .border_R {
            border-right: 1px solid #ffffff60;
        }
        .footer_section_1 h5 {
            font-size: 18px;
            font-weight: 600;
            color: #000000;
            letter-spacing: 0.04em;
        }
        .topusp h5 {
            color: #000000;
            font-weight: 600;
        }




        `}
      </style>
      <section style={{ background: '#EEFFE8', paddingBottom: '20px', marginTop: '-6px', boxShadow: '0px -4px 23px #00000040', position: 'relative' }}>
        <div className="container topusp">
          <div className="row footer_section_1 newUSP">
            <div className="col-lg-3 col-mb-6 col-sm-12 col-6  flexclass border_R">
              <img src="images/heroslider/icon1.png" alt="" />
              <div>
                <h5>Free Shipping</h5>
                <p>Free shipping over ₹100</p>
              </div>
            </div>
            <div className="col-lg-3 col-mb-6 col-sm-12 col-6  flexclass border_R">
              <img src="images/heroslider/icon3.png" alt="" />
              <div>
                <h5>Payment Secure</h5>
                <p>Got 100% Payment Safe</p>
              </div>
            </div>
            <div className="col-lg-3 col-mb-6 col-sm-12 col-6  flexclass border_R">
              <img src="images/heroslider/Group1.png" alt="" />
              <div>
                <h5>Support 24/7</h5>
                <p>Top quialty 24/7 Support</p>
              </div>
            </div>
            <div className="col-lg-3 col-mb-6 col-sm-12 col-6 flexclass">
              <img src="images/heroslider/icon2.png" alt="" />
              <div>
                <h5>100% Money Back</h5>
                <p>Cutomers Money Backs</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Usp;
