import React, { useState } from 'react';
import './userform.css'; // Import the CSS file

const UserForm = ({ onSubmit, user }) => {
    const [formData, setFormData] = useState({
        user_name: user ? user.user_name : '',
        email: user ? user.email : '',
        password: '',
        phoneNumber: user ? user.phoneNumber : '',
        addresses: user ? user.addresses : [{ street: '', city: '', postalCode: '', country: '' }]
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleAddressChange = (e, index) => {
        const { name, value } = e.target;
        const updatedAddresses = formData.addresses.map((address, i) => {
            if (i === index) {
                return { ...address, [name]: value };
            }
            return address;
        });
        setFormData({ ...formData, addresses: updatedAddresses });
    };

    const addAddress = () => {
        setFormData({
            ...formData,
            addresses: [...formData.addresses, { street: '', city: '', postalCode: '', country: '' }]
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit} className='usermanagement'>
            <div>
                <label>Name</label>
                <input type="text" name="user_name" value={formData.user_name} onChange={handleChange} required />
            </div>
            <div>
                <label>Email</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div>
                <label>Password</label>
                <input type="password" name="password" value={formData.password} onChange={handleChange} required />
            </div>
            <div>
                <label>Phone Number</label>
                <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
            </div>
            <h4>Addresses</h4>
            {formData.addresses.map((address, index) => (
                <div key={index} className="address-group">
                    <div>
                        <label>Street</label>
                        <input
                            type="text"
                            name="street"
                            value={address.street}
                            onChange={(e) => handleAddressChange(e, index)}
                        />
                    </div>
                    <div>
                        <label>City</label>
                        <input
                            type="text"
                            name="city"
                            value={address.city}
                            onChange={(e) => handleAddressChange(e, index)}
                        />
                    </div>
                    <div>
                        <label>Postal Code</label>
                        <input
                            type="text"
                            name="postalCode"
                            value={address.postalCode}
                            onChange={(e) => handleAddressChange(e, index)}
                        />
                    </div>
                    <div>
                        <label>Country</label>
                        <input
                            type="text"
                            name="country"
                            value={address.country}
                            onChange={(e) => handleAddressChange(e, index)}
                        />
                    </div>
                </div>
            ))}
            <button type="button" onClick={addAddress}>
                Add Address
            </button>
            <button type="submit">Submit</button>
        </form>
    );
};

export default UserForm;
