import React, { useState } from 'react';

const HomepageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="homepage-slider" style={{ position: 'relative', width: '100%', height: '400px', overflow: 'hidden' }}>
      {images.map((image, index) => (
        <img
          key={image.id}
          src={image.src}
          alt={`Slide ${index + 1}`}
          style={{
            display: currentIndex === index ? 'block' : 'none',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      ))}
      <button onClick={handlePrev} style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>Prev</button>
      <button onClick={handleNext} style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}>Next</button>
    </div>
  );
};

export default HomepageSlider;
