import React, { useState } from "react";
import styled from "styled-components";
import ReactImageZoom from 'react-image-zoom';

const MyImage = ({ images = [] }) => {
  const [mainImage, setMainImage] = useState(images[0]);

  const handleClick = (image) => {
    setMainImage(image);
  };

  const zoomProps = {
    width: 400,
    height: 500,
    zoomWidth: 400,
    zoomHeight: 400,
    img: `../images/product/${mainImage}`,
    zoomPosition: "side",
    zoomScale: 1,
  };

  return (
    <Wrapper>
      <div className="image-container">
        <ReactImageZoom {...zoomProps} />
        <div className="small_img">
          {images.map((image, index) => (
            <div className="col-3" key={index}>
              <img
                src={`../images/product/${image}`}
                alt={`Thumbnail ${index + 1}`}
                className="box-image--style"
                onClick={() => handleClick(image)}
              />
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .image-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px; /* Adjust the gap between main image and zoom panel */
  }

  .main_screen_img {
    position: relative;
  }

  .small_img {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .small_img .col-3 {
    flex-basis: calc(25% - 1rem);
    margin-right: 1rem;
    cursor: pointer;
    border-radius: 12px;
    overflow: hidden;
  }

  .small_img .col-3:last-child {
    margin-right: 0;
  }

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .small_img .col-3 {
      flex-basis: calc(33.33% - 0.5rem);
    }

    .small_img .col-3:last-child {
      margin-right: 1rem;
    }
  }
`;

export default MyImage;
